import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { IntercomProvider } from 'react-use-intercom';
import { PostHogProvider } from 'posthog-js/react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
	<Auth0Provider
		domain={process.env.REACT_APP_AUTH0_DOMAIN}
		clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
		redirectUri={window.location.href}
		useRefreshTokens
	>
		<StrictMode>
			<PostHogProvider
				apiKey={process.env.REACT_APP_POSTHOG_KEY}
				options={{
					api_host: process.env.REACT_APP_POSTHOG_HOST,
					autocapture: false,
					disable_session_recording: false,
					capture_pageview: true,
					capture_pageleave: true,
					secure_cookie: true
				}}
			>
				<IntercomProvider appId={process.env.REACT_APP_INTERCOM_ID}>
					<App />
				</IntercomProvider>
			</PostHogProvider>
		</StrictMode>
	</Auth0Provider>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
