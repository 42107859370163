/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { createMatchForMatchingRoundRequest } from '../models/createMatchForMatchingRoundRequest';
import type { createMatchForMatchingRoundResponse } from '../models/createMatchForMatchingRoundResponse';
import type { CreateMatchingRoundRequest } from '../models/CreateMatchingRoundRequest';
import type { DeleteMatchingRoundRequest } from '../models/DeleteMatchingRoundRequest';
import type { GenerateGPTIntroForMatchReqeust } from '../models/GenerateGPTIntroForMatchReqeust';
import type { generateMatchesForMatchingRoundRequest } from '../models/generateMatchesForMatchingRoundRequest';
import type { generateMatchesForMatchingRoundResponse } from '../models/generateMatchesForMatchingRoundResponse';
import type { GetMatchesResponse } from '../models/GetMatchesResponse';
import type { GetMatchingRoundsResponse } from '../models/GetMatchingRoundsResponse';
import type { GetUnmatchedMembersForMatchingRoundResponse } from '../models/GetUnmatchedMembersForMatchingRoundResponse';
import type { MatchingRoundDetailsModel } from '../models/MatchingRoundDetailsModel';
import type { MatchingRoundInviteResponse } from '../models/MatchingRoundInviteResponse';
import type { MatchModel } from '../models/MatchModel';
import type { SwapUserIdForMatchReqeust } from '../models/SwapUserIdForMatchReqeust';
import type { UpdateMatchingRoundRequest } from '../models/UpdateMatchingRoundRequest';
import type { UpdateMatchRequest } from '../models/UpdateMatchRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MatchingRoundsService {
	/**
	 * Get matching rounds for a club
	 * GET /clubs/<display id>/matching-rounds
	 * @returns GetMatchingRoundsResponse Successful Response
	 * @throws ApiError
	 */
	public static getMatchingRoundsClubsDisplayIdMatchingRoundsGet({
		displayId,
		mrid,
		hash,
		initiated,
		offOneEvent,
		shouldSendCalendarInvite,
		shouldSendSchedulingLink,
		active,
		includeUserSignUpStats = false
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		mrid?: number;
		hash?: string;
		initiated?: boolean;
		offOneEvent?: boolean;
		shouldSendCalendarInvite?: boolean;
		shouldSendSchedulingLink?: boolean;
		active?: boolean;
		includeUserSignUpStats?: boolean;
	}): CancelablePromise<GetMatchingRoundsResponse> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/clubs/{displayId}/matching-rounds',
			path: {
				displayId: displayId
			},
			query: {
				mrid: mrid,
				hash: hash,
				initiated: initiated,
				offOneEvent: offOneEvent,
				shouldSendCalendarInvite: shouldSendCalendarInvite,
				shouldSendSchedulingLink: shouldSendSchedulingLink,
				active: active,
				includeUserSignUpStats: includeUserSignUpStats
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Create a matching round for a club
	 * POST /clubs/<display id>/matching-rounds
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static createMatchingRoundClubsDisplayIdMatchingRoundsPost({
		displayId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: CreateMatchingRoundRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/clubs/{displayId}/matching-rounds',
			path: {
				displayId: displayId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Get matches for a club's matching round
	 * GET /clubs/<display id>/matching-rounds/<matchingRoundId>/matches
	 * @returns GetMatchesResponse Successful Response
	 * @throws ApiError
	 */
	public static getMatchesClubsDisplayIdMatchingRoundsMatchingRoundIdMatchesGet({
		displayId,
		matchingRoundId
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		matchingRoundId: number;
	}): CancelablePromise<GetMatchesResponse> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/clubs/{displayId}/matching-rounds/{matchingRoundId}/matches',
			path: {
				displayId: displayId,
				matchingRoundId: matchingRoundId
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Create a match for a matching round
	 * Create a match for a matching round
	 * @returns createMatchForMatchingRoundResponse Successful Response
	 * @throws ApiError
	 */
	public static createMatcheForMatchingRoundClubsDisplayIdMatchingRoundsMatchingRoundIdMatchesPost({
		displayId,
		matchingRoundId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		matchingRoundId: number;
		requestBody: createMatchForMatchingRoundRequest;
	}): CancelablePromise<createMatchForMatchingRoundResponse> {
		return __request(OpenAPI, {
			method: 'POST',
			url:
				'/clubs/{displayId}/matching-rounds/{matchingRoundId}/matches/',
			path: {
				displayId: displayId,
				matchingRoundId: matchingRoundId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Update a match preview
	 * PUT /matches/previews/{id}
	 * @returns MatchModel Successful Response
	 * @throws ApiError
	 */
	public static updateMatchPreviewClubsDisplayIdMatchingRoundsMatchingRoundIdMatchesMatchIdPut({
		displayId,
		matchingRoundId,
		matchId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		matchingRoundId: number;
		matchId: number;
		requestBody: UpdateMatchRequest;
	}): CancelablePromise<MatchModel> {
		return __request(OpenAPI, {
			method: 'PUT',
			url:
				'/clubs/{displayId}/matching-rounds/{matchingRoundId}/matches/{matchId}',
			path: {
				displayId: displayId,
				matchingRoundId: matchingRoundId,
				matchId: matchId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Generate a GPT intro for a match
	 * POST /clubs/<display id>/matching-rounds
	 * @returns MatchModel Successful Response
	 * @throws ApiError
	 */
	public static generateGptIntroForMatchClubsDisplayIdMatchingRoundsMatchingRoundIdMatchesMatchIdGenerateGptIntroPost({
		displayId,
		matchingRoundId,
		matchId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		matchingRoundId: number;
		matchId: number;
		requestBody: GenerateGPTIntroForMatchReqeust;
	}): CancelablePromise<MatchModel> {
		return __request(OpenAPI, {
			method: 'POST',
			url:
				'/clubs/{displayId}/matching-rounds/{matchingRoundId}/matches/{matchId}/generate-gpt-intro',
			path: {
				displayId: displayId,
				matchingRoundId: matchingRoundId,
				matchId: matchId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Swap one of the users in the match
	 * POST /clubs/<display id>/matching-rounds
	 * @returns MatchModel Successful Response
	 * @throws ApiError
	 */
	public static swapUserIdInMatchClubsDisplayIdMatchingRoundsMatchingRoundIdMatchesMatchIdSwapUserPost({
		displayId,
		matchingRoundId,
		matchId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		matchingRoundId: number;
		matchId: number;
		requestBody: SwapUserIdForMatchReqeust;
	}): CancelablePromise<MatchModel> {
		return __request(OpenAPI, {
			method: 'POST',
			url:
				'/clubs/{displayId}/matching-rounds/{matchingRoundId}/matches/{matchId}/swap-user',
			path: {
				displayId: displayId,
				matchingRoundId: matchingRoundId,
				matchId: matchId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Get details on a matching round
	 * GET /clubs/<display id>/matching-rounds/<matchingRoundId>
	 * @returns MatchingRoundDetailsModel Successful Response
	 * @throws ApiError
	 */
	public static getMatchingRoundDetailsClubsDisplayIdMatchingRoundsMatchingRoundIdGet({
		displayId,
		matchingRoundId
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		matchingRoundId: number;
	}): CancelablePromise<MatchingRoundDetailsModel> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/clubs/{displayId}/matching-rounds/{matchingRoundId}',
			path: {
				displayId: displayId,
				matchingRoundId: matchingRoundId
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Update a matching round for a club
	 * PUT /clubs/{displayId}/matching-rounds/{matchingRoundId}
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static updateMatchingRoundClubsDisplayIdMatchingRoundsMatchingRoundIdPut({
		displayId,
		matchingRoundId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		matchingRoundId: number;
		requestBody: UpdateMatchingRoundRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/clubs/{displayId}/matching-rounds/{matchingRoundId}',
			path: {
				displayId: displayId,
				matchingRoundId: matchingRoundId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Delete a matching round for a club
	 * DELETE /clubs/{displayId}/matching-rounds/{matchingRoundId}
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static deleteMatchingRoundClubsDisplayIdMatchingRoundsMatchingRoundIdDelete({
		displayId,
		matchingRoundId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		matchingRoundId: number;
		requestBody: DeleteMatchingRoundRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/clubs/{displayId}/matching-rounds/{matchingRoundId}',
			path: {
				displayId: displayId,
				matchingRoundId: matchingRoundId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Respond to an invite for a matching round
	 * PUT /clubs/<display id>/matching-rounds/<matchingRoundId>/invites
	 * @returns number Successful Response
	 * @throws ApiError
	 */
	public static respondToInviteForMatchingRoundClubsDisplayIdMatchingRoundsMatchingRoundIdInvitesPut({
		displayId,
		matchingRoundId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		matchingRoundId: number;
		requestBody: MatchingRoundInviteResponse;
	}): CancelablePromise<number> {
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/clubs/{displayId}/matching-rounds/{matchingRoundId}/invites',
			path: {
				displayId: displayId,
				matchingRoundId: matchingRoundId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Get all members who have opted-in for the matching round but are not part of a match
	 * GET /clubs/<display id>/matching-rounds
	 * @returns GetUnmatchedMembersForMatchingRoundResponse Successful Response
	 * @throws ApiError
	 */
	public static getUnmatchedMembersForMatchingRoundClubsDisplayIdMatchingRoundsMatchingRoundIdUnmatchedMembersGet({
		displayId,
		matchingRoundId
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		matchingRoundId: number;
	}): CancelablePromise<GetUnmatchedMembersForMatchingRoundResponse> {
		return __request(OpenAPI, {
			method: 'GET',
			url:
				'/clubs/{displayId}/matching-rounds/{matchingRoundId}/unmatched-members',
			path: {
				displayId: displayId,
				matchingRoundId: matchingRoundId
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Generate Matches for the matching round
	 * Generate matches for the matching round
	 * @returns generateMatchesForMatchingRoundResponse Successful Response
	 * @throws ApiError
	 */
	public static generateMatchesForMatchingRoundClubsDisplayIdMatchingRoundsMatchingRoundIdGenerateMatchesPost({
		displayId,
		matchingRoundId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		matchingRoundId: number;
		requestBody: generateMatchesForMatchingRoundRequest;
	}): CancelablePromise<generateMatchesForMatchingRoundResponse> {
		return __request(OpenAPI, {
			method: 'POST',
			url:
				'/clubs/{displayId}/matching-rounds/{matchingRoundId}/generate-matches',
			path: {
				displayId: displayId,
				matchingRoundId: matchingRoundId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}
}
