import { action, computed, makeObservable, observable } from 'mobx';
import { ISearchOption } from '../../../../../data-models/response/rest/users/search/UsersSearchGetResponse';
import {
	DynamicQuestionModel,
	IDynamicQuestionModel
} from '../dynamic-question';
import {
	ICreateQuestionParams,
	IRankingMetadata,
	SurveyQuestionTypeEnum
} from '../../types';
import { QuestionMatchingRuleTypeEnum } from '../../../../../api-client';
import { Observable } from 'rxjs';

export interface IRankingSurveyQuestionModel extends IDynamicQuestionModel {
	/**
	 * Answer to the ranking question
	 */
	value: number;
	/**
	 * Label to display on the red portion of the scale
	 */
	redLabel: string;
	/**
	 * Label to display on the yellow portion of the scale
	 */
	yellowLabel: string;
	/**
	 * Label to display on the green portion of the scale
	 */
	greenLabel: string;
	/**
	 * Sets the answer to the ranking question
	 */
	setValue(val: number): void;
}

export class RankingSurveyQuestionModel
	extends DynamicQuestionModel
	implements IRankingSurveyQuestionModel {
	/**
	 * ranking metadata that stores its red, yellow, and green labels
	 */
	@observable
	public rankingScale: IRankingMetadata;

	@observable
	public value: number;

	// type of this question is always multiselect
	public type: SurveyQuestionTypeEnum = SurveyQuestionTypeEnum.Ranking;

	constructor(params: ICreateQuestionParams) {
		super({ ...params, suppressConditionalLogic: true });
		makeObservable(this);
		if (params.questionBankEntry) {
			this.rankingScale = params.questionBankEntry.rankingMetadata;
		} else {
			this.rankingScale = params.questionModel.rankingMetadata;
		}
	}

	public createMatchingRules(
		rule: QuestionMatchingRuleTypeEnum
	): Observable<void> {
		return;
	}

	public saveMatchingRules(): Observable<void> {
		this.modifiedWeight = false;
		return;
	}

	@computed
	public get searchOption(): ISearchOption {
		return undefined;
	}

	@computed
	public get redLabel(): string {
		return this.rankingScale?.redLabel;
	}

	@computed
	public get yellowLabel(): string {
		return this.rankingScale?.yellowLabel;
	}

	@computed
	public get greenLabel(): string {
		return this.rankingScale?.greenLabel;
	}

	@computed
	public get validForSubmission(): boolean {
		return !!this.value || this.optional;
	}

	@action
	public setValue(val: number) {
		this.value = val;
	}
}
