import { computed, makeObservable, observable, action } from 'mobx';
import { Observable } from 'rxjs';
import { AppState } from '../../../../../AppModel';
import { ISearchOption } from '../../../../../data-models/response/rest/users/search/UsersSearchGetResponse';
import { RestApiClient } from '../../../../api/rest/restApiClientModel';
import {
	DynamicQuestionModel,
	IDynamicQuestionModel
} from '../dynamic-question';
import {
	ICreateQuestionParams,
	ILikertMetadata,
	SurveyQuestionTypeEnum
} from '../../types';
import {
	LikertMetadata,
	QuestionsService,
	SurveyTypes
} from '../../../../../api-client';

export interface ILikertSurveyQuestionModel extends IDynamicQuestionModel {
	/**
	 * Label to display on the left side of the likert scale
	 */
	left: string;
	/**
	 * Label to display between the left and middle options on the likert scale
	 */
	leftMid: string;
	/**
	 * Label to display in the middle side of the likert scale
	 */
	middle: string;
	/**
	 * Label to display between the middle and right options on the likert scale
	 */
	rightMid: string;
	/**
	 * Label to display on the right side of the likert scale
	 */
	right: string;
	/**
	 * Value of the likert scale
	 */
	value: number;
	/**
	 * Set the value of this likert question
	 */
	setValue(val: number): void;
	/**
	 * Get the label of the likert question from the value
	 * @param val
	 */
	getLabel(val: number): string;
	/**
	 * Changes the value of this questions likert scale label
	 */
	changeLikertScale(
		displayId: string,
		surveyType: string,
		metadata: LikertMetadata
	): Observable<unknown>;
}

export class LikertSurveyQuestionModel
	extends DynamicQuestionModel
	implements ILikertSurveyQuestionModel {
	/**
	 * likert metadata that stores its left, middle, and right values
	 */
	@observable
	public metadata: ILikertMetadata;
	@observable
	public value: number;

	// type of this question is always likert
	public type: SurveyQuestionTypeEnum = SurveyQuestionTypeEnum.Likert;

	constructor(params: ICreateQuestionParams) {
		super({ ...params, suppressConditionalLogic: true });
		makeObservable(this);
		if (params.questionBankEntry) {
			this.metadata = params.questionBankEntry.likertMetadata;
		} else {
			this.metadata = params.questionModel.likertMetadata;
		}
	}

	/**
	 * Likert questions are always valid for submission
	 */
	public get validForSubmission() {
		return this.value !== undefined || this.optional;
	}

	@computed
	public get searchOption(): ISearchOption {
		return {
			question: this.title,
			answers: [],
			likertData: {
				id: this.id,
				data: this.metadata
			}
		};
	}

	@computed
	public get left() {
		return this.metadata?.left;
	}

	@computed
	public get leftMid() {
		return this.metadata?.leftMid;
	}

	@computed
	public get middle() {
		return this.metadata?.middle;
	}

	@computed
	public get rightMid() {
		return this.metadata?.rightMid;
	}

	@computed
	public get right() {
		return this.metadata?.right;
	}

	public getLabel(value: number): string {
		switch (value) {
			case 1:
				return this.left;
			case 3:
				return this.leftMid;
			case 5:
				return this.middle;
			case 7:
				return this.rightMid;
			default:
				return this.right;
		}
	}

	public changeLikertScale(
		displayId: string,
		surveyType: string,
		metadata: LikertMetadata
	) {
		return RestApiClient.serviceRequest({
			generator: () =>
				QuestionsService.updateQuestionClubsDisplayIdSurveysSurveyTypeQuestionsQidPut(
					{
						displayId: encodeURIComponent(
							AppState.selectedGroup.displayId
						),
						surveyType: SurveyTypes.PROFILE,
						qid: this.id,
						requestBody: {
							likertMetadata: metadata
						}
					}
				),
			userToken: AppState.user?.cookie
		});
	}

	@action
	public setValue(val: number) {
		this.value = val;
	}
}
