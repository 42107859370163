/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateMatchingLogicRequest } from '../models/CreateMatchingLogicRequest';
import type { SurveyTypes } from '../models/SurveyTypes';
import type { UpdateMatchingLogicRequest } from '../models/UpdateMatchingLogicRequest';
import type { UpdateMatchingLogicResponse } from '../models/UpdateMatchingLogicResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MatchingService {
	/**
	 * Apply matching logic to the question specified
	 * POST /clubs/<display id>/surveys/<survey type>/questions/<qid>/matching
	 * @returns UpdateMatchingLogicResponse Successful Response
	 * @throws ApiError
	 */
	public static applyMatchingLogicClubsDisplayIdSurveysSurveyTypeQuestionsQidMatchingPost({
		displayId,
		surveyType,
		qid,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		surveyType: SurveyTypes;
		qid: number;
		requestBody: CreateMatchingLogicRequest;
	}): CancelablePromise<UpdateMatchingLogicResponse> {
		return __request(OpenAPI, {
			method: 'POST',
			url:
				'/clubs/{displayId}/surveys/{surveyType}/questions/{qid}/matching',
			path: {
				displayId: displayId,
				surveyType: surveyType,
				qid: qid
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Update matching logic to the question specified
	 * PUT /clubs/<display id>/surveys/<survey type>/questions/<qid>/matching
	 * @returns UpdateMatchingLogicResponse Successful Response
	 * @throws ApiError
	 */
	public static updateMatchingLogicClubsDisplayIdSurveysSurveyTypeQuestionsQidMatchingPut({
		displayId,
		surveyType,
		qid,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		surveyType: SurveyTypes;
		qid: number;
		requestBody: UpdateMatchingLogicRequest;
	}): CancelablePromise<UpdateMatchingLogicResponse> {
		return __request(OpenAPI, {
			method: 'PUT',
			url:
				'/clubs/{displayId}/surveys/{surveyType}/questions/{qid}/matching',
			path: {
				displayId: displayId,
				surveyType: surveyType,
				qid: qid
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}
}
