import { IGroupMemberDataModel } from '../../data-models/shared/groupMember/GroupMember';
import { RestApiClient } from '../api/rest/restApiClientModel';

import {
	ObservableMap,
	action,
	computed,
	makeObservable,
	observable
} from 'mobx';
import { Observable, Subject } from 'rxjs';
import { AppState } from '../../AppModel';
import {
	ClubsUpdateSingleMemberRequest,
	GetIndividualMemberProfileResponse,
	GetMemberRecommendations,
	MemberProfileModel,
	datamodels__models__groupMember__types__MemberQuickStats,
	MembersService,
	SurveyQuestionResponse,
	UpdateUserRequest,
	UsersService
} from '../../api-client';
import { map } from 'rxjs/operators';
import { ReadOnlyAnswer } from '../survey/question/types';
import { GroupMemberStatusEnum } from './types';

export interface IGroupMemberParams extends IGroupMemberDataModel {
	/**
	 * Display id of the group to which this group member belongs
	 */
	displayId: string;
}

/**
 * Interfaces a profile tag for this member
 */
export interface IProfileTag {
	/**
	 * The name fo the tag
	 */
	label: string;
	/**
	 * Whether it should be visible on the user's profile
	 */
	visible: boolean;
}

/**
 * Interfaces quick stats for this group member
 */
export interface IGroupMemberQuickStats {
	/**
	 * Number of opt-ins this member has had
	 */
	optInCount: number;
	/**
	 * Number of matches this member has had
	 */
	matchCount: number;
	/**
	 * Number of reviews this member has submitted
	 */
	reviews: number;
	/**
	 * Average rating this member has received
	 */
	avgRating: number;
	/**
	 * Average duration of the matches this user has had
	 */
	avgDuration: number;
	/**
	 * Number of matches this member has skipped
	 */
	numSkipped: number;
}

export interface IGroupMember {
	/**
	 * Unique id of the user associated with this member
	 */
	uid: number;
	/**
	 * Full name of the user
	 */
	name: string;
	/**
	 * Initials of the user
	 */
	initials: string;
	/**
	 * Email of the user
	 */
	email: string;
	/**
	 * Phone number of the user
	 */
	phone: string;
	/**
	 * Discord tag of the user
	 */
	discordTag: string;
	/**
	 * date this user joined the group
	 */
	dateJoined: string;
	/**
	 * join survey response for this group member
	 */
	survey: SurveyQuestionResponse[];
	surveyMap?: ObservableMap<number, SurveyQuestionResponse>;
	/**
	 * Profile image for this user
	 */
	profileImageUrl: string;
	/**
	 * Company title for this user
	 */
	title: string;
	/**
	 * Company for this user
	 */
	company: string;
	/**
	 * Bio for this user
	 */
	bio: string;
	/**
	 * Location of this user
	 */
	location: string;
	/**
	 * Pronouns of this user
	 */
	pronouns: string;
	/**
	 * Twitter of this user
	 */
	twitter: string;
	/**
	 * LinkedIn of this user
	 */
	linkedIn: string;
	/**
	 * Personal site of this user
	 */
	site: string;
	/**
	 * Highest education of this user
	 */
	education: string;
	/**
	 * Degree / Concentration of this user
	 */
	degrees: string;
	/**
	 * True if this member is an active member of the group
	 */
	active: boolean;
	/**
	 * The date this member was invited
	 */
	inviteSent?: Date;
	/**
	 * The date this member left the group
	 */
	dateLeft?: Date;
	/**
	 * The date this member accepted their invite
	 */
	inviteAccepted?: Date;
	/**
	 * True if this member was uploaded
	 */
	uploaded?: boolean;
	/**
	 * Tags associated with this group member
	 */
	tags?: IProfileTag[];
	/**
	 * Quick stats for this group member
	 */
	stats?: datamodels__models__groupMember__types__MemberQuickStats;
	/**
	 * True if the member has been removed by a group admin
	 */
	suspended?: boolean;
	/**
	 * True if the member has been accepted into the group
	 */
	accepted?: boolean;
	/**
	 * True if the member has been rejected from the group
	 */
	rejected?: boolean;
	/**
	 * True if the member's email has bounced
	 */
	emailBounced?: boolean;
	/**
	 * True if the member is allowed to be removed by the admin
	 */
	removeable: boolean;
	/**
	 * Unique ids of other members recommended for this member
	 */
	recommendations?: number[];
	/**
	 * Total number of pages of recommendations for this member
	 */
	numRecommendationsPages?: number;
	/**
	 * True if recommendations are loading
	 */
	recommendationsLoading?: boolean;
	/**
	 * True if automatic bookings are enabled by the user
	 */
	enableAutomaticBookings?: boolean;
	/**
	 * True if available hours are enabled by the user
	 */
	enableAvailableHours?: boolean;
	/**
	 * Fires when the member profile has been loaded
	 */
	profileLoaded?: Subject<void>;
	/**
	 * True if the members survey has loaded
	 */
	surveyLoaded?: boolean;
	/**
	 * Preview of the answers for this join survey
	 */
	preview?: ReadOnlyAnswer[];
	/**
	 * Status of this member in the group
	 */
	status?: GroupMemberStatusEnum;
	/**
	 * Slack user id for this member
	 */
	slackUserId?: string;
	/**
	 * Accept this user into the group
	 */
	accept(): Observable<unknown>;
	/**
	 * Reject this member from the group
	 */
	reject(): Observable<unknown>;
	/**
	 * Remove this member from the group
	 */
	remove(admin: boolean): Observable<unknown>;
	/**
	 * Update this member's profile
	 */
	update?: (body: ClubsUpdateSingleMemberRequest) => Observable<unknown>;
	/**
	 * Update the profile of the underlying user
	 * @param body
	 */
	updateUserProfile?: (body: UpdateUserRequest) => Observable<unknown>;
	/**
	 * Change whether this member is active or not
	 * @param status
	 */
	changeActiveStatus?: (status: boolean) => Observable<void>;
	/**
	 * Retrieves detailed info on this user
	 */
	getProfile(): void;
	/**
	 * Get members recommended to this member to meet
	 */
	getRecommendations?: (
		page: number,
		matchingRoundId: number,
		allowRematching: boolean
	) => void;
	/**
	 * Add tag to this member
	 * @param tag
	 */
	addTag?: (tag: string) => void;
	/**
	 * Remove tag from this member
	 * @param tag
	 */
	removeTag?: (tag: string) => void;
	/**
	 * Check if the given tag applies to the members
	 * @param tag
	 */
	hasTag?: (tag: string) => boolean;
	/**
	 * Resend the invite to this member
	 */
	reinvite?: () => Observable<unknown>;
}

/**
 * Interfaces parameters for creating a Group Member
 */
export interface IGroupMemberParams {
	/**
	 * The member profile model
	 */
	model: MemberProfileModel;
	/**
	 * The group display id
	 */
	displayId: string;
}

export class GroupMember implements IGroupMember {
	/**
	 * Display id of the group to which this group member belongs
	 */
	private groupDisplayId: string;
	/**
	 * Map of page number to recommendations for this member
	 */
	@observable
	private pageToRecommendations: ObservableMap<
		number,
		number[]
	> = new ObservableMap<number, number[]>();
	/**
	 * Unique id of the user associated with this group member
	 */
	@observable
	public uid: number;
	@observable
	public name: string;
	@observable
	public email: string;
	@observable
	public phone: string;
	@observable
	public discordTag: string;
	@observable
	public dateJoined: string;
	@observable
	public surveyMap: ObservableMap<number, SurveyQuestionResponse>;
	@observable
	public profileImageUrl: string;
	@observable
	public title: string;
	@observable
	public company: string;
	@observable
	public bio: string;
	@observable
	public location: string;
	@observable
	public pronouns: string;
	@observable
	public twitter: string;
	@observable
	public linkedIn: string;
	@observable
	public site: string;
	@observable
	public education: string;
	@observable
	public degrees: string;
	@observable
	public active: boolean;
	@observable
	public inviteSent: Date;
	@observable
	public dateLeft: Date;
	@observable
	public inviteAccepted: Date;
	@observable
	public uploaded: boolean;
	@observable
	public accepted: boolean;
	@observable
	public rejected: boolean;
	@observable
	public emailBounced: boolean;
	@observable
	public suspended: boolean;
	@observable
	public slackUserId: string;
	@observable
	public enableAutomaticBookings: boolean = false;
	@observable
	public enableAvailableHours: boolean = false;
	@observable
	public surveyLoaded: boolean = false;

	/**
	 * Tags for this user
	 */
	@observable
	public tags: IProfileTag[] = [];

	/**
	 * Quick stats for this member
	 */
	@observable
	public stats: datamodels__models__groupMember__types__MemberQuickStats;

	/**
	 * Number of pages of recommendations for this member
	 */
	@observable
	public numRecommendationsPages: number = 0;

	/**
	 * True if recommendations are loading
	 */
	@observable
	public recommendationsLoading: boolean = false;

	/**
	 * Fires when the member profile has been loaded
	 */
	public profileLoaded: Subject<void> = new Subject();

	constructor(params: IGroupMemberParams) {
		makeObservable(this);
		this.name = params.model.name;
		this.email = params.model.email;
		this.phone = params.model.phone;

		this.uid = params.model.uid;

		this.company = params.model.company;
		this.bio = params.model.bio;
		this.linkedIn = params.model.linkedIn;
		this.twitter = params.model.twitter;
		this.site = params.model.site;
		this.location = params.model.location;
		this.pronouns = params.model.pronouns;
		this.education = params.model.education;
		this.degrees = params.model.degrees;
		this.discordTag = params.model.discord;
		this.groupDisplayId = params.displayId;
		this.title = params.model.jobTitle;
		this.profileImageUrl = params.model.image;
		this.active = params.model.active;
		this.uploaded = params.model.uploaded;
		this.accepted = params.model.accepted;
		this.rejected = params.model.rejected;
		this.emailBounced = params.model.emailBounced;
		this.suspended = params.model.suspended;
		this.inviteSent = params.model.inviteSent
			? new Date(params.model.inviteSent)
			: undefined;
		this.dateLeft = params.model.dateLeft
			? new Date(params.model.dateLeft)
			: undefined;
		this.inviteAccepted = params.model.inviteAccepted
			? new Date(params.model.inviteAccepted)
			: undefined;
		this.tags = params.model.tags;
		this.stats = params.model.stats;
		this.dateJoined = params.model.joined?.split(' ')[0];
		this.enableAutomaticBookings = params.model.enableAutomaticBookings;
		this.enableAvailableHours = params.model.enableAvailableHours;
		this.slackUserId = params.model.slackUserId;
	}

	@computed
	public get initials(): string {
		return this.name
			.split(' ')
			.map((val: string) => val.charAt(0))
			.join('');
	}

	@computed
	public get removeable(): boolean {
		return (
			this.active ||
			process.env.REACT_APP_FORCE_MEMBERS_REMOVEABLE === 'true'
		);
	}

	@computed
	public get survey(): SurveyQuestionResponse[] {
		return Array.from(this.surveyMap?.values() || []);
	}

	/**
	 * Users recommended for this member to meet
	 */
	@computed
	public get recommendations(): number[] {
		return Array.from(this.pageToRecommendations.values()).flatMap(
			(values) => values
		);
	}

	/**
	 * Return the status of this member in the Club
	 */
	@computed
	public get status(): GroupMemberStatusEnum {
		if (this.emailBounced) {
			return GroupMemberStatusEnum.Attention;
		} else if (
			!this.active &&
			!this.uploaded &&
			this.dateLeft !== undefined
		) {
			return GroupMemberStatusEnum.Inactive;
		} else if (
			!this.active &&
			!this.inviteSent &&
			this.dateLeft === undefined &&
			!this.suspended
		) {
			return GroupMemberStatusEnum.Pending;
		} else if (!this.active && this.inviteSent && !this.inviteAccepted) {
			return GroupMemberStatusEnum.Invited;
		} else if (
			!this.active &&
			!this.inviteSent &&
			this.dateLeft === undefined &&
			!this.suspended
		) {
			return GroupMemberStatusEnum.Uploaded;
		} else {
			return GroupMemberStatusEnum.Active;
		}
	}

	public update(body: ClubsUpdateSingleMemberRequest): Observable<void> {
		return RestApiClient.serviceRequest({
			generator: () =>
				MembersService.updateMemberClubsDisplayIdMembersMemberIdPut({
					displayId: encodeURIComponent(this.groupDisplayId),
					memberId: this.uid,
					requestBody: body
				})
		}).pipe(
			map(() => {
				if (body.introduction !== undefined) {
					this.setBio(body.introduction);
				}
				if (body.enableAutomaticBookings !== undefined) {
					this.setEnableAutomaticBookings(
						body.enableAutomaticBookings
					);
				}
				if (body.enableAvailableHours !== undefined) {
					this.setEnableAvailableHours(body.enableAvailableHours);
				}
			})
		);
	}

	public updateUserProfile(body: UpdateUserRequest) {
		return RestApiClient.serviceRequest({
			generator: () =>
				UsersService.updateUserInfoUsersUidPut({
					uid: this.uid,
					requestBody: body
				})
		}).pipe(
			map(() => {
				if (body.email) {
					this.email = body.email;
				}
				this.emailBounced = false;
			})
		);
	}

	public accept() {
		return RestApiClient.serviceRequest({
			generator: () =>
				MembersService.updateMemberClubsDisplayIdMembersMemberIdPut({
					displayId: encodeURIComponent(this.groupDisplayId),
					memberId: this.uid,
					requestBody: {
						acceptMember: true
					}
				}),
			userToken: AppState.user?.cookie
		}).pipe(
			map(() => {
				this.accepted = true;
			})
		);
	}

	public reject() {
		return RestApiClient.serviceRequest({
			generator: () =>
				MembersService.updateMemberClubsDisplayIdMembersMemberIdPut({
					displayId: encodeURIComponent(this.groupDisplayId),
					memberId: this.uid,
					requestBody: {
						rejectMember: true
					}
				}),
			userToken: AppState.user?.cookie
		}).pipe(
			map(() => {
				this.rejected = true;
				this.dateLeft = new Date();
			})
		);
	}

	public remove(admin: boolean) {
		if (admin) {
			return RestApiClient.serviceRequest({
				generator: () =>
					MembersService.removeMemberClubsDisplayIdMembersMemberIdDelete(
						{
							displayId: encodeURIComponent(this.groupDisplayId),
							memberId: this.uid
						}
					),
				userToken: AppState.user?.cookie
			});
		} else {
			return RestApiClient.serviceRequest({
				generator: () =>
					MembersService.updateMemberClubsDisplayIdMembersMemberIdPut(
						{
							displayId: encodeURIComponent(this.groupDisplayId),
							memberId: this.uid,
							requestBody: {
								activate: false
							}
						}
					),
				userToken: AppState.user?.cookie
			});
		}
	}

	public changeActiveStatus(status: boolean): Observable<void> {
		return RestApiClient.serviceRequest({
			generator: () =>
				MembersService.updateMemberClubsDisplayIdMembersMemberIdPut({
					displayId: encodeURIComponent(this.groupDisplayId),
					memberId: this.uid,
					requestBody: {
						activate: status || !this.active,
						acceptInvite: status
					}
				})
		}).pipe(
			map(() => {
				this.active = status || !this.active;
			})
		);
	}

	/**
	 * Load more detailed member info
	 */
	public getProfile() {
		// guard against repetitive and unnecessary requests
		if (this.survey.length < 1 || this.bio === undefined) {
			RestApiClient.serviceRequest<GetIndividualMemberProfileResponse>({
				generator: () =>
					MembersService.getMemberProfileClubsDisplayIdMembersMemberIdGet(
						{
							displayId: encodeURIComponent(this.groupDisplayId),
							memberId: this.uid
						}
					),
				userToken: AppState.user?.cookie
			}).subscribe((data: GetIndividualMemberProfileResponse) => {
				// update this group members info
				this.surveyMap = new ObservableMap<
					number,
					SurveyQuestionResponse
				>(
					Object.entries(
						data.survey.questionResponses
					).map((value: [string, SurveyQuestionResponse]) => [
						parseInt(value[0]),
						value[1]
					])
				);

				this.bio = data.bio;
				this.active = data.active;
				this.accepted = data.accepted;
				this.rejected = data.rejected;
				this.suspended = data.suspended;
				this.uploaded = data.uploaded;
				this.dateJoined = data.joined;
				this.inviteSent = data.inviteSent
					? new Date(data.inviteSent)
					: undefined;
				this.inviteAccepted = data.inviteAccepted
					? new Date(data.inviteAccepted)
					: undefined;
				// TODO: return tags
				// this.tags = data.tags;
				this.stats = data.stats;
				this.enableAutomaticBookings = data.enableAutomaticBookings;
				this.enableAvailableHours = data.enableAvailableHours;
				this.profileLoaded.next();
				// set survey loaded
				this.surveyLoaded = true;
			});
		} else {
			// emit profile already loaded
			this.profileLoaded.next();
		}
	}

	public getRecommendations(
		page: number,
		matchingRoundId: number,
		allowRematching: boolean
	) {
		this.recommendationsLoading = true;
		RestApiClient.serviceRequest<GetMemberRecommendations>({
			generator: () =>
				MembersService.getMemberRecommendationsClubsDisplayIdMembersMemberIdRecommendationsGet(
					{
						displayId: encodeURIComponent(this.groupDisplayId),
						memberId: this.uid,
						page: page,
						matchingRoundId: matchingRoundId,
						allowRematching: allowRematching
					}
				)
		}).subscribe((data: GetMemberRecommendations) => {
			this.recommendationsLoading = false;
			this.setRecommendations(page, data.recommendations);
			this.setRecommendationsTotalPages(data.total_pages);
		});
	}

	public hasTag(tag: string): boolean {
		return this.tags.map((tag) => tag.label)?.includes(tag);
	}

	@action
	public addTag(tag: string) {
		this.tags.push({
			label: tag,
			visible: true
		});
	}

	@action
	public removeTag(label: string) {
		this.tags = this.tags.filter((tag) => tag.label !== label);
	}

	/**
	 * Resend the invite to this member
	 */
	@action
	public reinvite() {
		return RestApiClient.serviceRequest({
			generator: () =>
				MembersService.sendMemberInviteClubsDisplayIdMembersMemberIdInvitePost(
					{
						displayId: encodeURIComponent(this.groupDisplayId),
						memberId: this.uid
					}
				),
			userToken: AppState.user?.cookie
		}).pipe(
			map(() => {
				this.inviteSent = new Date();
			})
		);
	}

	/**
	 * Update the bio for this member
	 * @param bio
	 */
	@action
	private setBio(bio: string) {
		this.bio = bio;
	}

	/**
	 * Set recommendations for this user
	 * @param recommendations
	 */
	@action
	private setRecommendations(page: number, recommendations: number[]) {
		this.pageToRecommendations.set(page, recommendations);
	}

	/**
	 * Set number of recommendations pages for this user
	 * @param totalPages
	 */
	@action
	private setRecommendationsTotalPages(totalPages: number) {
		this.numRecommendationsPages = totalPages;
	}

	/**
	 * Set whether automatic bookings should be enabled or disabled
	 * @param enabled
	 */
	@action
	private setEnableAutomaticBookings(enabled: boolean) {
		this.enableAutomaticBookings = enabled;
	}

	/**
	 * Set whether available hours should be enabled or disabled
	 * @param enabled
	 */
	@action
	private setEnableAvailableHours(enabled: boolean) {
		this.enableAvailableHours = enabled;
	}
}
