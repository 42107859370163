/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MatchActionPutRequest } from '../models/MatchActionPutRequest';
import type { MatchActionsEnum } from '../models/MatchActionsEnum';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MatchActionsService {
	/**
	 * Report a match as missed, cancelled, or archived
	 * PUT /matches/{hash}/missed
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static reportMissedMatchMatchesHashActionPut({
		hash,
		action,
		requestBody
	}: {
		hash: string;
		action: MatchActionsEnum;
		requestBody: MatchActionPutRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/matches/{hash}/{action}',
			path: {
				hash: hash,
				action: action
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}
}
