/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetUserReports } from '../models/GetUserReports';
import type { RecordOnboardingMetadataRequest } from '../models/RecordOnboardingMetadataRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DefaultService {
	/**
	 * Get a list of reports for this user
	 * GET /clubs/{displayId}/reports
	 * @returns GetUserReports Successful Response
	 * @throws ApiError
	 */
	public static getReportsCacheUsersUidReportsGet({
		uid
	}: {
		uid: number;
	}): CancelablePromise<GetUserReports> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/users/{uid}/reports',
			path: {
				uid: uid
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Record onboarding metadata for a user
	 * POST /users/{uid}/setup
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static recordOnboardingMetadataUsersUidSetupPost({
		uid,
		requestBody
	}: {
		uid: number;
		requestBody: RecordOnboardingMetadataRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/users/{uid}/setup',
			path: {
				uid: uid
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}
}
