import { QuestionBankEntry, SurveyQuestionModel } from '../../../api-client';

/**
 * Interfaces the likert metadata
 */
export interface ILikertMetadata {
	/**
	 * Value to display on the leftmost end of the scale
	 */
	left: string;
	/**
	 * Value to display between the left and middle options of the scale
	 */
	leftMid: string;
	/**
	 * Value to display in the middle of the scale
	 */
	middle: string;
	/**
	 * Value to display between the middle and right options of the scale
	 */
	rightMid: string;
	/**
	 * Value to display on the rightmost end of the scale
	 */
	right: string;
}

/**
 * Interfaces parameters for creating a survey question
 */
export interface ICreateQuestionParams {
	/**
	 * Question model to load from
	 */
	questionModel?: SurveyQuestionModel;
	/**
	 * Question bank entry to load from
	 */
	questionBankEntry?: QuestionBankEntry;
	/**
	 * True if we should suppress conditional logic
	 */
	suppressConditionalLogic?: boolean;
}

/**
 * Interfaces the ranking metadata
 */
export interface IRankingMetadata {
	/**
	 * Label to display on the red portion of the scale
	 */
	redLabel: string;
	/**
	 * Label to display on the yellow portion of the scale
	 */
	yellowLabel: string;
	/**
	 * Label to display on the green portion of the scale
	 */
	greenLabel: string;
}

/**
 * Enumizes server-side question types
 */
export enum SurveyQuestionTypeEnum {
	Multiselect = 'multiselect',
	TwoPart = 'TwoPart',
	Likert = 'likert',
	Intentional = 'intentional',
	Freetext = 'freetext',
	Radio = 'radio',
	Ranking = 'ranking'
}

/**
 * Enumizes server-side algo param types
 */
export enum AlgoParamTypeEnum {
	Comparison = 'comparison',
	Intentional = 'intentional',
	SameGroup = 'same-group',
	CrossGroup = 'cross-group',
	Custom = 'custom'
}

/**
 * Enumizes server-side visibility options for this question
 */
export enum QuestionVisibilityEnum {
	VISIBLE = 'visible',
	PROFILE = 'profile',
	ONBOARDING = 'onboarding',
	HIDDEN = 'hidden'
}

/**
 * Represents a read only answer to a question
 */
export interface ReadOnlyAnswer {
	/**
	 * The question
	 */
	question: string;
	/**
	 * The answer string
	 */
	answer: string;
}
