/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enumizes the types of export reports available to the group
 */
export enum GroupExportTypeEnum {
	IDENTITY = 'identity',
	EXPANDED = 'expanded',
	SURVEY = 'survey',
	OVERVIEW = 'overview',
	ENGAGEMENT = 'engagement',
	FEEDBACK = 'feedback'
}
