/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PreSignedUrlRequest } from '../models/PreSignedUrlRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class S3Service {
	/**
	 * Generates a Presigned Url for S3 upload
	 * POST /internal/integrations/webhooks/s3/generate-presigned-url
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static generatePresignedUrlInternalIntegrationsWebhooksS3GeneratePresignedUrlPost({
		requestBody
	}: {
		requestBody: PreSignedUrlRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/internal/integrations/webhooks/s3/generate-presigned-url',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}
}
