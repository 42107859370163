/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MeetingRoomModel } from '../models/MeetingRoomModel';
import type { NewMeetingRoomModel } from '../models/NewMeetingRoomModel';
import type { UpdateMeetingRoomModel } from '../models/UpdateMeetingRoomModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MeetingService {
	/**
	 * Get the meeting room for the match with a specified hash
	 * GET /matches/{hash}/meeting-room
	 * @returns MeetingRoomModel Successful Response
	 * @throws ApiError
	 */
	public static getMeetingRoomMatchesHashMeetingRoomGet({
		hash,
		userId
	}: {
		hash: string;
		userId?: number;
	}): CancelablePromise<MeetingRoomModel> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/matches/{hash}/meeting-room',
			path: {
				hash: hash
			},
			query: {
				user_id: userId
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Create a new meeting room for a match
	 * POST /match/{hash}/meeting-room
	 * @returns NewMeetingRoomModel Successful Response
	 * @throws ApiError
	 */
	public static createMeetingRoomMatchesHashMeetingRoomPost({
		hash
	}: {
		hash: string;
	}): CancelablePromise<NewMeetingRoomModel> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/matches/{hash}/meeting-room',
			path: {
				hash: hash
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Record a user joining or leaving a room
	 * PUT /match/{hash}/meeting-room
	 * TODO: we need to rewrite our endpoints to all be authed and work on the way we allow access from Discord
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static updateMeetingRoomMatchesHashMeetingRoomPut({
		hash,
		requestBody
	}: {
		hash: string;
		requestBody: UpdateMeetingRoomModel;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/matches/{hash}/meeting-room',
			path: {
				hash: hash
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}
}
