/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enum for the type of message to send
 */
export enum MessageTypeEnum {
	WELCOME = 'welcome',
	INTRO = 'intro',
	OPT_IN = 'opt-in',
	OPT_OUT = 'opt-out',
	INVITE = 'invite',
	UPLOAD = 'upload'
}
