/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Describe the sort direction of field on which we have sorted
 */
export enum SortDirection {
	ASC = 'asc',
	DESC = 'desc'
}
