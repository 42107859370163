import { action, computed, makeObservable, observable } from 'mobx';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from '../../../AppModel';
import { IErrorResponse } from '../../../data-models/response/error/ErrorResponse';
import { IInviteRoundDataModel } from '../../../data-models/shared/inviteRound/inviteRound';
import { CommunicationModeEnum } from '../../../data-models/shared/matchingParams/types';
import { RestApiClient } from '../../api/rest/restApiClientModel';
import { HttpMethodEnum } from '../../api/rest/types';
import { ICreateInviteRoundBody, IUpdateInviteRoundBody } from '../types';
import { InviteRoundModel } from '../../../api-client/models/InviteRoundModel';
import {
	CommunicationTypeEnum,
	InvitesService,
	UpdateInviteRoundRequest
} from '../../../api-client';
import { RecurrenceSettings } from '../../../components/experiences/schedule-intro-round-overlay/add-edit-round-panel/add-edit-round-panel';

/**
 * Interfaces our InviteRoundModel
 */
export interface IInviteRoundModel {
	/**
	 * Unique id for this invite round
	 */
	id: number;
	/**
	 * Time of the invite round
	 */
	time: Date;
	/**
	 * True if this round is active, false otherwise
	 */
	active: boolean;
	/**
	 * Recurrence for this invite round
	 */
	recurrence: RecurrenceSettings;
	/**
	 * True when the model is dirty and requires saving
	 */
	dirty: boolean;
	/**
	 * Channel on which to send this invite
	 */
	channel: CommunicationTypeEnum;
	/**
	 * Unique id of the match associated with this invite round
	 */
	match?: number;
	/**
	 * True if the invite should only send to uninvited members
	 */
	onlyUninvited: boolean;
	/**
	 * Update the date of this invite round
	 */
	updateDate(date: Date): void;
	/**
	 * Update the time fo this invite round
	 */
	updateTime(time: Date): void;
	/**
	 * Clear the time of this invite round
	 */
	clearDateTime(): void;
	/**
	 * Set recurrence for the invite round
	 */
	setRecurrence(recurrence: RecurrenceSettings): void;
	/**
	 *
	 */
	setActive(active: boolean): void;
	/**
	 * Update this invite round
	 */
	update(body: UpdateInviteRoundRequest): Observable<void>;
	/**
	 * Cancel this upcoming invite round
	 */
	cancel(): Observable<void>;
}

/**
 * Params for instantiating our invite round model
 */
export interface IInviteRoundModelParams {
	/**
	 * Display id associated with the group for this Invite round
	 */
	displayId: string;
	/**
	 * Underlying data from which to construct our model
	 */
	model: InviteRoundModel;
	/**
	 * Callback to trigger the parent group to reload their matches
	 */
	onReloadInvites(): void;
}

/**
 * Adds state to our Invite round data models
 */
export class InviteRoundClassModel implements IInviteRoundModel {
	@observable
	public time: Date;
	@observable
	public active: boolean;
	@observable
	public recurrence: RecurrenceSettings;
	@observable
	public match: number;
	@observable
	public channel: CommunicationTypeEnum = CommunicationTypeEnum.EMAIL;
	@observable
	public onlyUninvited: boolean;

	public id: number;
	/**
	 * Display id associated with the group in which this Invite round exists
	 */
	private _displayId: string;
	/**
	 *
	 */
	private _originalModel: InviteRoundModel;
	/**
	 * Reload all invites in the group
	 */
	private reloadInvites: () => void;

	/**
	 * Setup the model from an IInviteRoundDataModel
	 */
	constructor(params: IInviteRoundModelParams) {
		makeObservable(this);
		const model: InviteRoundModel = params.model;
		this.id = model.id;
		this._displayId = params.displayId;
		this.time = model?.time ? new Date(model.time + 'Z') : undefined;
		this.active = model.active;
		this.recurrence = this.numberOfHoursToRecurrenceSetting(
			model.recurrence
		);
		this.match = model.associatedMatch;
		this.channel = model.channel;
		this._originalModel = model;
		this.reloadInvites = params.onReloadInvites;
		this.onlyUninvited = model.onlyUninvited;
	}

	@computed
	public get dirty(): boolean {
		return (
			this.time?.getTime() !==
				new Date(this._originalModel.time + 'Z').getTime() ||
			this.active !== this._originalModel.active ||
			this.recurrence !==
				this.numberOfHoursToRecurrenceSetting(
					this._originalModel.recurrence
				)
		);
	}

	@action
	public updateDate(date: Date) {
		// Initialize the time if it is not set already
		if (!this.time) {
			const intermediateDate: Date = new Date();
			intermediateDate.setHours(12, 0, 0, 0);
			this.time = intermediateDate;
		}

		// Create a copy of the date and modify the date
		const _time = new Date(this.time?.getTime());

		// Update the date portion of this.time
		_time.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());

		// update the time that set
		this.time = _time;
	}

	@action
	public updateTime(time: Date) {
		// Initialize the time if it is not set already
		if (!this.time) {
			this.time = new Date();
		}
		// Create a copy of the date and modify the date
		const _time = new Date(this.time?.getTime());

		// Update the time portion of this.time
		_time.setHours(time?.getHours(), time?.getMinutes(), 0, 0);

		// Ensure that the date portion of this.time is not modified
		_time.setFullYear(time.getFullYear(), time.getMonth(), time.getDate());

		// update the time that set
		this.time = _time;
	}

	@action
	public clearDateTime() {
		this.time = undefined;
	}

	@action
	public setRecurrence(recurrence: RecurrenceSettings) {
		this.recurrence = recurrence;
	}

	/**
	 * Update whether this match is active
	 */
	@action
	public setActive(active: boolean) {
		this.active = active;
	}

	/**
	 * Update whether we should send invites to only uninvited members
	 */
	@action
	private setOnlyUninvited(onlyUninvited: boolean) {
		this.onlyUninvited = onlyUninvited;
	}

	public update(body: UpdateInviteRoundRequest) {
		// construct body for request
		const request_body: any = {
			cookie: AppState.user?.cookie
		};
		if (
			body.time &&
			new Date(body.time)?.getTime() !==
				new Date(this._originalModel.time + 'Z').getTime()
		) {
			this.updateDate(new Date(body.time));
			this.updateTime(new Date(body.time));
			request_body.time = this.time;
		}
		if (
			body.active !== undefined &&
			body.active !== this._originalModel.active
		) {
			this.setActive(body.active);
			request_body.active = this.active;
		}

		if (body.recurrence !== this._originalModel.recurrence) {
			const recurrence = this.numberOfHoursToRecurrenceSetting(
				body.recurrence
			);
			this.setRecurrence(recurrence);
			request_body.recurrence = body.recurrence;
		}

		if (
			body.onlyUninvited !== undefined &&
			body.onlyUninvited !== this._originalModel.onlyUninvited
		) {
			this.setOnlyUninvited(body.onlyUninvited);
			request_body.onlyUninvited = this.onlyUninvited;
		}

		return RestApiClient.serviceRequest({
			generator: () =>
				InvitesService.updateInviteRoundClubsDisplayIdInvitesIridPut({
					irid: this.id,
					displayId: encodeURIComponent(this._displayId),
					requestBody: body
				}),
			userToken: AppState.user?.cookie
		}).pipe(
			map(() => {
				if (body.recurrence) {
					this.reloadInvites();
				}
			})
		);
	}

	public cancel() {
		return this.update({ active: false });
	}

	/**
	 * Consume the number of hours between rounds and output our user readable recurrence setting
	 * @param hours
	 */
	private numberOfHoursToRecurrenceSetting(
		hours: number
	): RecurrenceSettings {
		if (hours) {
			const multiple: number = hours / 168;
			switch (multiple) {
				case 1:
					return RecurrenceSettings.Weekly;
				case 2:
					return RecurrenceSettings.BiWeekly;
				case 3:
					return RecurrenceSettings.TriWeekly;
				case 4:
					return RecurrenceSettings.Monthly;
			}
		}
		return RecurrenceSettings.None;
	}
}
