import { action, computed, makeObservable, observable } from 'mobx';
import { ISurveyQuestionSubmissionData } from '../../data-models/request/rest/groups/members/GroupMembersPostRequest';
import { RestApiClient } from '../api/rest/restApiClientModel';
import posthog from 'posthog-js';
import { IDynamicQuestionModel } from '../survey/question/dynamic/dynamic-question';
import { IFreetextSurveyQuestionModel } from '../survey/question/dynamic/freetext/freetext-survey-question';
import { ILikertSurveyQuestionModel } from '../survey/question/dynamic/likert/likert-survey-question';
import { IMultiSelectSurveyQuestionModel } from '../survey/question/dynamic/radio/multiselect/multiselect-survey-question';
import { IRadioSurveyQuestionModel } from '../survey/question/dynamic/radio/radio-survey-question';
import { IRankingSurveyQuestionModel } from '../survey/question/dynamic/ranking/ranking-survey-question';
import { SurveyQuestionTypeEnum } from '../survey/question/types';
import { ISurveyModel, SurveyClassModel } from '../survey/survey';
import {
	AnsweredQuestionModel,
	FeedbackService,
	MatchActionsEnum,
	MatchActionsService,
	MatchSummaryInfo,
	SurveyTypes,
	routes__matches__types__MatchStatusEnum
} from '../../api-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatchStatusEnum } from './types';

/**
 * Interfaces parameters for user identification
 */
export interface IUserIdentityParams {
	/**
	 * Cookie for the user
	 */
	cookie?: string;
	/**
	 * Unique id of the user
	 */
	uid?: number;
}

export interface IMatch {
	/**
	 * Unique id of the match
	 */
	mid: number;
	/**
	 * Unique hash of the match
	 */
	hash: string;
	/**
	 * Name of the user matched
	 */
	matchName: string;
	/**
	 * Bio of the user matched
	 */
	matchBio: string;
	/**
	 * Date of the match
	 */
	date: string;
	/**
	 * True if the members have met using the Intros scheduler
	 */
	haveMet?: boolean;
	/**
	 * Unique id of the user we have matched with
	 */
	matchedUserId?: number;
	/**
	 * The times which have been proposed for this match
	 */
	proposedTimes?: Date[];
	/**
	 * Status of the match request
	 */
	status?: MatchStatusEnum;
	/**
	 * Custom note left during the proposal
	 */
	proposalNote?: string;
	/**
	 * Review of this match
	 */
	review: AnsweredQuestionModel[];
	/**
	 * Review survey for this match
	 */
	reviewSurvey: IDynamicQuestionModel[];
	/**
	 * true if the user has reviewed this match
	 */
	reviewed: boolean;
	/**
	 * True when we want to disable submitting the review survey
	 */
	disableReviewSurveySubmission: boolean;
	/**
	 * Get the review of this match for the user with the given cookie
	 */
	getReview(params: IUserIdentityParams): Observable<unknown>;
	/**
	 * Archive the match
	 */
	archive(): Observable<void>;
	/**
	 * Submit review fo this match for the user with the given cookie and logging data
	 */
	submitReview(uid: number): Observable<unknown>;
}

export class Match implements IMatch {
	@observable
	public matchName: string;
	@observable
	public matchBio: string;
	@observable
	public date: string;
	@observable
	public mid: number;
	@observable
	public hash: string;
	@observable
	public haveMet?: boolean;
	@observable
	public proposedTimes: Date[];
	@observable
	public status: MatchStatusEnum;
	@observable
	public proposalNote: string;
	@observable
	public matchedUserId: number;
	/**
	 * Display id of the group to which this match belongs
	 */
	private groupDisplayId: string;

	@observable
	public review: AnsweredQuestionModel[];
	/**
	 * The review survey for this match
	 */
	@observable
	private _reviewSurvey: ISurveyModel;

	/**
	 * True when we want to notify that we have submitted the review survey
	 */
	@observable
	private _reviewSubmitted: boolean = false;

	constructor(data: Partial<MatchSummaryInfo>, displayId: string) {
		makeObservable(this);
		this.mid = data.mid;
		this.hash = data.hash;
		this.groupDisplayId = displayId;

		this.matchName = data.name;
		this.matchBio = data.bio;
		this.date = data.dateOfMatch;
		this.haveMet = data.haveMet;
		this.matchedUserId = data.matchedUserId;

		this.proposedTimes = [];
		this.status = data.status;
		this.proposalNote = data.note;
	}

	public getReview(params: IUserIdentityParams): Observable<unknown> {
		// block loading the review survey until we have a group display id available
		if (this.groupDisplayId && !this.review) {
			// use hash if available, otherwise use mid
			return RestApiClient.serviceRequest<AnsweredQuestionModel[]>({
				generator: () =>
					FeedbackService.getFeedbackForMatchMatchesHashFeedbackGet({
						hash: this.hash,
						uid: params.uid
					}),
				userToken: params.cookie
			}).pipe(
				map((data: AnsweredQuestionModel[]) => {
					this.setReview(data);
					if (!this._reviewSurvey) {
						this.getReviewSurvey();
					}
				})
			);
		}
	}

	@computed
	public get reviewed(): boolean {
		return (
			this.status ===
			routes__matches__types__MatchStatusEnum.COMPLETED_FEEDBACK
		);
	}

	@computed
	public get reviewSurvey(): IDynamicQuestionModel[] {
		return (
			this._reviewSurvey &&
			(this._reviewSurvey.questions as IDynamicQuestionModel[])
		);
	}

	@computed
	public get disableReviewSurveySubmission(): boolean {
		return this._reviewSurvey && !this._reviewSurvey.validForSubmission;
	}

	public archive(): Observable<void> {
		return RestApiClient.serviceRequest({
			generator: () =>
				MatchActionsService.reportMissedMatchMatchesHashActionPut({
					hash: this.hash,
					action: MatchActionsEnum.ARCHIVE,
					requestBody: {}
				})
		});
	}

	public submitReview(uid?: number) {
		// establish survey to submit
		const survey: ISurveyQuestionSubmissionData[] = [];
		this.reviewSurvey.forEach((question: IDynamicQuestionModel) => {
			// set the question model
			const submissionData: ISurveyQuestionSubmissionData = {
				qid: question.id,
				answer: undefined
			};
			let answered = false;

			// set the question answers
			switch (question.type) {
				case SurveyQuestionTypeEnum.Likert:
					submissionData.answer = (question as ILikertSurveyQuestionModel).value;
					answered = !!(question as ILikertSurveyQuestionModel).value;
					break;
				case SurveyQuestionTypeEnum.Freetext:
					submissionData.answer = (question as IFreetextSurveyQuestionModel).answer;
					answered = !!(question as IFreetextSurveyQuestionModel)
						.answer;
					break;
				case SurveyQuestionTypeEnum.Radio:
					submissionData.answer = [
						(question as IRadioSurveyQuestionModel).selectedOptionId
					];
					answered = !!(question as IRadioSurveyQuestionModel)
						.selectedOptionId;
					break;
				case SurveyQuestionTypeEnum.Ranking:
					submissionData.answer = (question as IRankingSurveyQuestionModel).value;
					answered = !!(question as IRankingSurveyQuestionModel)
						.value;
					break;
				default:
					submissionData.answer = (question as IMultiSelectSurveyQuestionModel).selectedAnswers;
					// if free response answer given, add that to freetext data
					if (
						(question as IMultiSelectSurveyQuestionModel)
							.freeResponseAnswerValid
					) {
						submissionData.otherText = (question as IMultiSelectSurveyQuestionModel).freeResponseAnswer;
					}
					answered =
						!!(question as IMultiSelectSurveyQuestionModel)
							.selectedAnswers.length ||
						!!(question as IMultiSelectSurveyQuestionModel)
							.freeResponseAnswer;
					break;
			}

			// If the question is optional and answer given, or required then submit
			if (answered || !question.optional) {
				// add this to the survey to submit
				survey.push(submissionData);
			}
		});

		// submitted review
		return RestApiClient.serviceRequest({
			generator: () =>
				FeedbackService.submitFeedbackForMatchMatchesHashFeedbackPost({
					hash: this.hash,
					requestBody: {
						survey: survey,
						uid: uid
					}
				})
		}).pipe(
			map(() => {
				this.setReviewSubmitted();

				// get existing groups
				const existingGroups = posthog.getGroups();
				// set match as group
				posthog.group('match', this.mid?.toString());
				// record event in posthog
				posthog.capture('Match Feedback Submitted', {
					source: 'web',
					hash: this.hash,
					mid: this.mid
				});
				// reset all groups
				posthog.resetGroups();
				// register all prior groups
				posthog.register({ $groups: { ...existingGroups } });
			})
		);
	}

	@action
	public setReview(data: AnsweredQuestionModel[]) {
		this.review = data;
	}

	/**
	 * Force set the review submitted
	 */
	private setReviewSubmitted() {
		this._reviewSubmitted = true;
	}

	/**
	 * Load this matches review survey
	 */
	private getReviewSurvey() {
		const model = new SurveyClassModel({
			displayId: this.groupDisplayId,
			type: SurveyTypes.FEEDBACK,
			admin: false
		});
		model.loaded.subscribe(() => {
			this._reviewSurvey = model;
		});
	}
}
