import {
	action,
	computed,
	makeObservable,
	observable,
	ObservableMap,
	runInAction
} from 'mobx';
import { Observable, Subject } from 'rxjs';
import { RestApiClient } from '../api/rest/restApiClientModel';
import { SurveyDataModel } from '../api/rest/types';
import { IDynamicQuestionModel } from './question/dynamic/dynamic-question';
import { SurveyQuestionTypeEnum } from './question/types';
import {
	IRadioSurveyQuestionModel,
	RadioSurveyQuestionModel
} from './question/dynamic/radio/radio-survey-question';
import {
	IMultiSelectSurveyQuestionModel,
	MultiSelectSurveyQuestionModel
} from './question/dynamic/radio/multiselect/multiselect-survey-question';
import { IntentionalSurveyQuestionModel } from './question/dynamic/radio/multiselect/intentional/intentional-survey-question';
import {
	ILikertSurveyQuestionModel,
	LikertSurveyQuestionModel
} from './question/dynamic/likert/likert-survey-question';
import { FreetextSurveyQuestionModel } from './question/dynamic/freetext/freetext-survey-question';
import { AppState } from '../../AppModel';
import { map, mergeMap } from 'rxjs/operators';
import { ISurveyAnswerOptionModel } from './answer/survey-answer-option';
import { RankingSurveyQuestionModel } from './question/dynamic/ranking/ranking-survey-question';
import { IAbstractQuestionModel } from './question/abstract-question';
import {
	CreateQuestionPostRequest,
	CreateQuestionPostResponse,
	ProfilesFeedbackService,
	QuestionsService,
	SurveyQuestionModel,
	SurveyTypes,
	UpdateQuestionPutRequest,
	UpdateQuestionPutResponse
} from '../../api-client';

export interface ISurveyModel {
	/**
	 * Questions which make up this survey
	 */
	questions: IAbstractQuestionModel[];
	/**
	 * True if text is enabled for this group (need to collect phone number)
	 */
	textEnabled: boolean;
	/**
	 * True if we should include the frequency question in our join survey
	 */
	includeFrequencyQuestion: boolean;
	/**
	 * True if this survey is valid for submission
	 */
	validForSubmission: boolean;
	/**
	 * True when the survye is loading
	 */
	loading: boolean;
	/**
	 * Remove a question from this survey
	 */
	removeQuestion(qid: number): Observable<unknown>;
	/**
	 * Update a question's option in the survey
	 */
	updateQuestionOption(
		qid: number,
		id: number,
		value: string
	): Observable<void>;
	/**
	 * Add a new question to the survey
	 * @param params
	 * @param callback to trigger after executing
	 */
	addSurveyQuestion(
		params: CreateQuestionPostRequest
	): Observable<CreateQuestionPostResponse>;
	/**
	 * Returns a subset of answers for the question specified with the given id
	 */
	getPreviewAnswersForQuestion(qid: number): string[];
	/**
	 * Loads conditional logic for this survey
	 */
	loadConditionalLogic(): void;
}

export interface ISurveyModelParams {
	/**
	 * Unique id of the group for this survey
	 */
	gid?: number;
	/**
	 * Display id of the group for this survey
	 */
	displayId: string;
	/**
	 * Type of the survey
	 */
	type: SurveyTypes;
	/**
	 * True if this is admin access, false otherwise
	 */
	admin: boolean;
}

export class SurveyClassModel implements ISurveyModel {
	public textEnabled: boolean;
	/**
	 * Map of question ids to thei IBaseSurveyQuestionModel
	 */
	@observable
	protected questionMap: ObservableMap<
		number,
		IAbstractQuestionModel
	> = new ObservableMap<number, IAbstractQuestionModel>();
	/**
	 * Unique id of the group the survey belongs to
	 */
	private groupId: number;
	/**
	 * Display id of the group the survey to which the group belongs
	 */
	protected groupDisplayId: string;
	/**
	 * Type of this survey
	 */
	protected type: SurveyTypes;
	/**
	 * True if we should include the frequency quesiton
	 */
	@observable
	public includeFrequencyQuestion: boolean = true;
	/**
	 * True when the survey is loading
	 */
	@observable
	public loading: boolean = false;

	/**
	 * Create a subject for listeners to subscribe on
	 */
	public loaded: Subject<undefined> = new Subject();

	constructor(params: ISurveyModelParams) {
		makeObservable(this);
		this.groupId = params.gid as number;
		this.groupDisplayId = params.displayId;
		this.type = params.type;
		this.getSurvey(params.type, params.admin).subscribe();
	}

	public getSurvey(
		type: SurveyTypes,
		admin: boolean
	): Observable<SurveyDataModel> {
		// boolean to track if the admin is requesting survey, or a member
		const activeOnly = admin ? '0' : '1';
		// set loading state
		this.setLoading(true);
		// make request
		return RestApiClient.serviceRequest<SurveyDataModel>({
			generator: () =>
				ProfilesFeedbackService.getSurveyClubsDisplayIdSurveysSurveyTypeGet(
					{
						displayId: encodeURIComponent(this.groupDisplayId),
						surveyType: type,
						activeOnly: !admin
					}
				)
		}).pipe(
			map((data: SurveyDataModel) => {
				// set the survey data
				this.setSurveyData(data);
				// fire subject
				this.loaded.next();
				// disable loading state
				this.setLoading(false);
				// return
				return data;
			})
		);
	}

	public loadConditionalLogic(): void {
		(this.questions as IDynamicQuestionModel[]).forEach(
			(question: IDynamicQuestionModel) => {
				if (
					[
						SurveyQuestionTypeEnum.Radio,
						SurveyQuestionTypeEnum.Multiselect,
						SurveyQuestionTypeEnum.Intentional
					].includes(question.type)
				) {
					question.fetchConditionalLogic(this.groupDisplayId);
				}
			}
		);
	}

	@computed
	public get questions(): IAbstractQuestionModel[] {
		return Array.from(this.questionMap.values())?.sort(
			(a, b) => a.index - b.index
		);
	}

	@computed
	public get validForSubmission(): boolean {
		return (this.questions as IDynamicQuestionModel[]).every(
			(question: IDynamicQuestionModel) => question.validForSubmission
		);
	}

	public getPreviewAnswersForQuestion(qid: number): string[] {
		if (this.questionMap?.has(qid)) {
			const question: IDynamicQuestionModel = this.questionMap?.get(
				qid
			) as IDynamicQuestionModel;
			if (question.type === SurveyQuestionTypeEnum.Radio) {
				const parsed: IRadioSurveyQuestionModel = question as IRadioSurveyQuestionModel;
				if (parsed.answers) {
					return [
						parsed.answers[
							Math.floor(parsed.answers.length * Math.random())
						].value
					];
				}
				return [];
			} else if (question.type === SurveyQuestionTypeEnum.Likert) {
				const parsed: ILikertSurveyQuestionModel = question as ILikertSurveyQuestionModel;
				const val = Math.floor(5 * Math.random());
				switch (val) {
					case 0:
						return [parsed.left];
					case 1:
						return [parsed.leftMid];
					case 2:
						return [parsed.middle];
					case 3:
						return [parsed.rightMid];
					case 4:
						return [parsed.right];
				}
			} else if (question.type === SurveyQuestionTypeEnum.Freetext) {
				return [`Mock Answer for "${question.title}"`];
			} else {
				const parsed: IMultiSelectSurveyQuestionModel = question as IMultiSelectSurveyQuestionModel;
				if (parsed.answers) {
					return [...parsed.answers]
						?.sort(() => Math.random() - Math.random())
						.slice(0, Math.min(3, parsed.answers.length / 2))
						.map((ans: ISurveyAnswerOptionModel) => ans.value);
				}
				return [];
			}
		}
		return [];
	}

	public addSurveyQuestion(params: CreateQuestionPostRequest) {
		return RestApiClient.serviceRequest<CreateQuestionPostResponse>({
			generator: () =>
				QuestionsService.createQuestionClubsDisplayIdSurveysSurveyTypeQuestionsPost(
					{
						displayId: encodeURIComponent(this.groupDisplayId),
						surveyType: this.type,
						requestBody: params
					}
				),
			userToken: AppState.user?.cookie
		}).pipe(
			mergeMap((data: CreateQuestionPostResponse) => {
				// on success, force refresh of survey and then return data
				return this.getSurvey(this.type, true).pipe(map(() => data));
			})
		);
	}

	public editSurveyQuestion(qid: number, params: UpdateQuestionPutRequest) {
		// get the question from the params
		const question: IDynamicQuestionModel = this.questionMap.get(
			qid
		) as IDynamicQuestionModel;
		// Set new answer indexes if provided. This helps to smooth out the transition when reordering answers
		if (params.optionOrder) {
			question.answers.forEach((ans: ISurveyAnswerOptionModel) => {
				ans.setIndex(params.optionOrder?.indexOf(ans.id));
			});
		}
		// declare body - TODO: Strictly type
		const body: UpdateQuestionPutRequest = {
			optionsToAdd: params.optionsToAdd,
			optionsToRemove: params.optionsToRemove,
			optionOrder: params.optionOrder
		};
		// remove options that have been provided
		params.optionsToRemove?.forEach((val: number) =>
			(question as IRadioSurveyQuestionModel).deactivateOption(val)
		);
		// if linked question, remove from intentional question
		if (question.type === SurveyQuestionTypeEnum.Intentional) {
			const linkedQuestion = (Array.from(
				this.questionMap.values()
			) as IDynamicQuestionModel[]).find(
				(ques: IDynamicQuestionModel) =>
					ques.title === question.linkedQuestion
			);
			if (linkedQuestion) {
				params.optionsToRemove?.forEach((val: number) => {
					const answer = (question as IRadioSurveyQuestionModel).answers.find(
						(ans: ISurveyAnswerOptionModel) => ans.id === val
					);
					const linkedAnswerId = (linkedQuestion as IRadioSurveyQuestionModel).answers.find(
						(ans: ISurveyAnswerOptionModel) =>
							ans.value === answer.value
					).id;
					(linkedQuestion as IRadioSurveyQuestionModel).deactivateOption(
						linkedAnswerId
					);
				});
			}
		}
		// determine if title has been edited
		if (params.title && question.title !== params.title) {
			body.title = params.title;
			question.title = params.title;
		}
		// determine if minRequired options has been edited
		if (
			params.minRequired &&
			question.minRequired !== params.minRequired &&
			question.minRequired !== undefined
		) {
			body.minRequired = params.minRequired;
			question.minRequired = params.minRequired;
		}
		// determine if algo weight has been edited
		if (params.weight && question.weight !== params.weight) {
			body.weight = params.weight;
			question.weight = params.weight;
		}
		// determine if allow override has been edited
		// if (
		// 	params.allowOverride &&
		// 	question.allowOverride !== params.allowOverride
		// ) {
		// 	body.allowOverride = params.allowOverride;
		// 	question.allowOverride = params.allowOverride;
		// }
		// if the question is a similarity scale question
		if (question.type === SurveyQuestionTypeEnum.Likert) {
			if (
				params.likertMetadata &&
				(question.metadata.left !== params.likertMetadata.left ||
					question.metadata.leftMid !==
						params.likertMetadata.leftMid ||
					question.metadata.middle !== params.likertMetadata.middle ||
					question.metadata.rightMid !==
						params.likertMetadata.rightMid ||
					question.metadata.right !== params.likertMetadata.right)
			) {
				body.likertMetadata = params.likertMetadata;
				question.metadata = params.likertMetadata;
			}
		}
		// if we are reordering the question
		if (params.index !== undefined) {
			body.index = params.index;
		}
		return RestApiClient.serviceRequest<UpdateQuestionPutResponse>({
			generator: () =>
				QuestionsService.updateQuestionClubsDisplayIdSurveysSurveyTypeQuestionsQidPut(
					{
						displayId: encodeURIComponent(this.groupDisplayId),
						surveyType: this.type,
						qid: qid,
						requestBody: body
					}
				),
			userToken: AppState.user?.cookie
		}).pipe(
			map((data: UpdateQuestionPutResponse) => {
				this.setSurveyData({
					locked: false,
					questions: Object.values(data.questions)
				});
				return data;
			})
		);
	}

	public toggleVisibilityForSurveyQuestion(qid: number) {
		if (this.questionMap.has(qid)) {
			const question: IDynamicQuestionModel = this.questionMap.get(
				qid
			) as IDynamicQuestionModel;
			return question.toggleVisibility();
		}
	}

	public removeQuestion(qid: number) {
		// if question exists
		if (this.questionMap.has(qid)) {
			// deactivate this question
			return RestApiClient.serviceRequest({
				generator: () =>
					QuestionsService.deleteQuestionClubsDisplayIdSurveysSurveyTypeQuestionsQidDelete(
						{
							displayId: encodeURIComponent(this.groupDisplayId),
							surveyType: this.type,
							qid: qid
						}
					),
				userToken: AppState.user?.cookie
			}).pipe(
				map(() => {
					// delete the question from map
					runInAction(() => this.questionMap.delete(qid));
				})
			);
		}
	}

	/**
	 * Update the string value used as the answer to a survey question
	 * @param qid
	 * @param id
	 * @param value
	 */
	public updateQuestionOption(qid: number, id: number, value: string) {
		return RestApiClient.serviceRequest({
			generator: () =>
				QuestionsService.editAnswerClubsDisplayIdSurveysSurveyTypeQuestionsQidAnswersAidPut(
					{
						displayId: encodeURIComponent(this.groupDisplayId),
						surveyType: this.type,
						qid: qid,
						aid: id,
						requestBody: {
							answer: value
						}
					}
				),
			userToken: AppState.user?.cookie
		}).pipe(
			map(() => {
				// Get the question from qid
				const question: IDynamicQuestionModel = this.questionMap.get(
					qid
				) as IDynamicQuestionModel;
				// Get all answers for the question
				const answers: ISurveyAnswerOptionModel[] = question.answers;
				// Find the answer corresponding to id
				const answer: ISurveyAnswerOptionModel = answers.find(
					(answer) => answer.id === id
				);
				// Update the value if it exists
				if (answer) {
					answer.setValue(value);
				}
			})
		);
	}

	@action
	protected setSurveyData(data: SurveyDataModel) {
		const questionsMap = new ObservableMap<number, IDynamicQuestionModel>();
		data.questions.forEach((question: SurveyQuestionModel) => {
			switch (question.type) {
				case SurveyQuestionTypeEnum.Radio:
					// create radio question
					questionsMap.set(
						question.qid,
						new RadioSurveyQuestionModel({
							questionModel: question
						})
					);
					break;
				case SurveyQuestionTypeEnum.Likert:
					// create likert question
					questionsMap.set(
						question.qid,
						new LikertSurveyQuestionModel({
							questionModel: question
						})
					);
					break;
				case SurveyQuestionTypeEnum.Freetext:
					// create freetext question
					questionsMap.set(
						question.qid,
						new FreetextSurveyQuestionModel({
							questionModel: question
						})
					);
					break;
				case SurveyQuestionTypeEnum.Ranking:
					// create ranking question
					questionsMap.set(
						question.qid,
						new RankingSurveyQuestionModel({
							questionModel: question
						})
					);
					break;
				case SurveyQuestionTypeEnum.Intentional:
					// create intentional question
					questionsMap.set(
						question.qid,
						new IntentionalSurveyQuestionModel({
							questionModel: question,
							suppressConditionalLogic: true
						})
					);
					break;
				case SurveyQuestionTypeEnum.Multiselect:
					if (question.linkedQuestion) {
						// create intentional question
						questionsMap.set(
							question.qid,
							new IntentionalSurveyQuestionModel({
								questionModel: question,
								suppressConditionalLogic: true
							})
						);
					} else {
						// create multiselect question
						questionsMap.set(
							question.qid,
							new MultiSelectSurveyQuestionModel({
								questionModel: question,
								suppressConditionalLogic: true
							})
						);
					}
					break;
				default:
					console.error(
						'[setSurveyData] Invalid survey question type...'
					);
					throw Error(
						'[setSurveyData] Invalid survey question type...'
					);
			}
		});
		// set the question map
		this.questionMap.merge(questionsMap);
	}

	/**
	 * Update the survey's loading state
	 * @param loading
	 */
	@action
	private setLoading(loading: boolean) {
		this.loading = loading;
	}
}
