/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum FeatureEnum {
	ZAPIER = 'zapier',
	DIRECTORY = 'directory',
	TAGGING = 'tagging',
	CONDITIONAL_MATCHING = 'conditionalMatching',
	EMAIL_VERIFICATION = 'emailVerification',
	NOTIFICATIONS = 'notifications',
	DATA_ENRICHMENT = 'dataEnrichment',
	INTROS_FOOTER = 'introsFooter',
	INTERCOM_SUPPORT = 'intercomSupport',
	IDENTITY_REPORT = 'identityReport',
	EXPANDED_REPORT = 'expandedReport',
	SURVEY_REPORT = 'surveyReport',
	OVERVIEW_REPORT = 'overviewReport',
	ENGAGEMENT_REPORT = 'engagementReport',
	FEEDBACK_REPORT = 'feedbackReport',
	SLACK = 'slack',
	DISCORD = 'discord',
	MEMBER_DEFINED_WEIGHTS = 'memberDefinedWeights',
	CUSTOM_BRAND_COLORS = 'customBrandColors',
	CHATBOT = 'chatbot'
}
