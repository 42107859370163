import { SurveyQuestionTypeEnum } from '../../models/survey/question/types';

/**
 * Types the data that we need to properly edit a user's survey answer from the update_member page
 */
export interface IEditSurveyQuestionParams {
	/**
	 * Unique id of the question we are editing
	 */
	id: number;
	/**
	 * Answer to the question
	 */
	value: string | number;
	/**
	 * Type of question
	 */
	type: SurveyQuestionTypeEnum;
	/**
	 * Optional action, only used for multiselect answers
	 */
	action?: 'added' | 'removed';
}

export interface IEditSurveyQuestionWeightParams {
	/**
	 * Unique id of the question we are editing
	 */
	id: number;
	/**
	 * Weight of the question
	 */
	value: number;
}

/**
 * Enumizes the Member Profile Tabs
 */
export enum MemberProfileTabs {
	// home tab
	Home,
	// directory tab
	Directory,
	// experiences tab
	Upcoming,
	// approve intros tab
	ApproveIntros,
	// opt-in
	OptIn,
	// preferences tab
	Preferences,
	// ai chat tab
	AIChat,
	// favorites tab
	Favorites,
	// profile tab
	Profile,
	// requests tab
	Requests,
	// activity tab
	Activity,
	// proposal response tab
	ProposalResponse,
	// meeting page for intros
	Meet,
	// scheduling page for intros
	Schedule,
	// rescheduling page for intros
	Reschedule,
	// cancelling match page for intros
	Cancel
}

/**
 * Enumizes social profiles we connect to the user
 */
export enum SocialMediaEnum {
	Google = 'Google',
	Facebook = 'Facebook',
	LinkedIn = 'LinkedIn',
	Twitter = 'Twitter'
}

/**
 * Enumizes path names for the member profile
 */
export enum MemberProfilePathNamesEnum {
	Home = 'home',
	ApproveIntros = 'approve-intros',
	OptIn = 'opt-in',
	Directory = 'directory',
	AIChat = 'ai-chat',
	Experiences = 'upcoming-events',
	Profile = 'member-profile',
	Preferences = 'preferences',
	Requests = 'requests',
	History = 'history',
	ProposalResponse = 'proposal-response',
	Match = 'match',
	Meet = 'meet',
	Schedule = 'schedule',
	Reschedule = 'reschedule',
	Cancel = 'cancel'
}

/**
 * Types the data that we need to properly edit a user's survey answer from the update_member page
 */
export interface IEditSurveyQuestionParams {
	/**
	 * Unique id of the question we are editing
	 */
	id: number;
	/**
	 * Answer to the question
	 */
	value: string | number;
	/**
	 * Type of question
	 */
	type: SurveyQuestionTypeEnum;
	/**
	 * Optional action, only used for multiselect answers
	 */
	action?: 'added' | 'removed';
}

export interface IEditSurveyQuestionWeightParams {
	/**
	 * Unique id of the question we are editing
	 */
	id: number;
	/**
	 * Weight of the question
	 */
	value: number;
}

export interface IUpdateMemberProfileParams {
	/**
	 * Users first name
	 */
	firstName?: string;
	/**
	 * Users last name
	 */
	lastName?: string;
	/**
	 * Users pronouns
	 */
	pronouns?: string;
	/**
	 * Discord tag for this user
	 */
	discord?: string;
	/**
	 * Phone number for this user
	 */
	phone?: string;
	/**
	 * Users company
	 */
	company?: string;
	/**
	 * Users title
	 */
	title?: string;
	/**
	 * Users introduction
	 */
	introduction?: string;
	/**
	 * Where the user is based
	 */
	location?: string;
	/**
	 * Linkedin of the user
	 */
	linkedIn?: string;
	/**
	 * Twitter of the user
	 */
	twitter?: string;
	/**
	 * School of the user
	 */
	education?: string;
	/**
	 * Degress this user has obtained
	 */
	degrees?: string;
	/**
	 * Personal site of the user
	 */
	personalSite?: string;
	/**
	 * Users selected profile photo source
	 */
	profilePhoto?: SocialMediaEnum;
	/**
	 * True if we should allow automatic bookings
	 */
	enableAutomaticBookings?: boolean;
	/**
	 * True if we should enable setting available hours
	 */
	enableAvailableHours?: boolean;
	/**
	 * Timezone for this user
	 */
	timezone?: string;
}

/**
 * Interfaces parameters for event registration
 */
export interface IChangeEventRegistrationParams {
	/**
	 * Unique id of the matching round
	 */
	eid: number;
	/**
	 * Unique hash specifying the event we are registering
	 */
	hash: string;
	/**
	 * True if this is a sign up request, False if it is a cancellation
	 */
	signUp: boolean;
}

/**
 * Enumizes the possible responses from a proposer
 */
export enum ProposerResponseActionEnum {
	Accept = 'accept',
	Reject = 'reject'
}

/**
 * Enumize available responses for a proposal
 */
export enum ProposalResponses {
	// Yes and wants to meet now
	Yes = 0,
	// Yes, but can only meet later
	YesLater = 1,
	// No, but available to meet someone else
	No = 2,
	// No, and busy for the next few weeks
	NoAndBusy = 3
}
