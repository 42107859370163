/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetInvitesResponse } from '../models/GetInvitesResponse';
import type { ScheduleInvitesRequest } from '../models/ScheduleInvitesRequest';
import type { UpdateInviteRoundRequest } from '../models/UpdateInviteRoundRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InvitesService {
	/**
	 * Get all scheduled Invites for this club
	 * GET /clubs/<display id>/invites
	 * @returns GetInvitesResponse Successful Response
	 * @throws ApiError
	 */
	public static getInvitesClubsDisplayIdInvitesGet({
		displayId,
		active
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		active?: boolean;
	}): CancelablePromise<GetInvitesResponse> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/clubs/{displayId}/invites',
			path: {
				displayId: displayId
			},
			query: {
				active: active
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Invite members to join the Intros Club, or schedule an invite for an upcoming matching round
	 * POST /clubs/<displayId>/invites
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static inviteMembersClubsDisplayIdInvitesPost({
		displayId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: ScheduleInvitesRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/clubs/{displayId}/invites',
			path: {
				displayId: displayId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Update an invite round
	 * PUT /clubs/{displayId}/invites/{irid}
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static updateInviteRoundClubsDisplayIdInvitesIridPut({
		displayId,
		irid,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		irid: number;
		requestBody: UpdateInviteRoundRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/clubs/{displayId}/invites/{irid}',
			path: {
				displayId: displayId,
				irid: irid
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}
}
