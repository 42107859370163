import { action, makeObservable, observable } from 'mobx';
import { SurveyAnswerOptionDataModel } from '../../api/rest/types';

export interface ISurveyAnswerOptionModel {
	/**
	 * The value of this survey answer
	 */
	value: string;
	/**
	 * Unique id of the survey answer
	 */
	id: number;
	/**
	 * Group id to which this survey answer belongs
	 */
	gid: number;
	/**
	 * True if this answer is active
	 */
	active: boolean;
	/**
	 * True if this answer is selected
	 */
	selected: boolean;
	/**
	 * Index of this answer in a corresponding list of answers
	 */
	index?: number;
	/**
	 * Modified whether or not this option is selected
	 */
	setSelected(selected: boolean): void;
	/**
	 * Modify whether or not this option is active
	 */
	setActive(active: boolean): void;
	/**
	 * Set the index of this answer in a corresponding list of answers
	 * @param index
	 */
	setIndex(index: number): void;
	/**
	 * Set the value of this answer
	 */
	setValue(value: string): void;
}

export class SurveyAnswerOptionModel implements ISurveyAnswerOptionModel {
	@observable
	public value: string;
	public id: number;
	@observable
	public active: boolean;
	@observable
	public selected: boolean;
	@observable
	public index?: number;
	public gid: number;

	constructor(data: SurveyAnswerOptionDataModel) {
		makeObservable(this);
		this.value = data.value;
		this.id = data.id;
		this.active = data.active;
		this.gid = data.gid;
		this.index = data.index;
		this.selected = false;
	}

	@action
	public setSelected(selected: boolean) {
		this.selected = selected;
	}

	@action
	public setActive(active: boolean) {
		this.active = active;
	}

	@action
	public setIndex(index: number) {
		this.index = index;
	}

	@action
	public setValue(value: string) {
		this.value = value;
	}
}
