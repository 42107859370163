import styled from '@emotion/styled';
import { CSSProperties } from 'react';
import { PulseLoader as ReactSpinnerPulseLoader } from 'react-spinners';
import { FlexWrapper } from '../wrappers/styled-divs';

/**
 * Interfaces props for our loader
 */
export interface ILoaderProps {
	/**
	 * size of the loader
	 */
	size?: number;
	/**
	 * Styles to apply to the container
	 */
	styles?: CSSProperties;
}

/**
 * Modularizes a pulse loader
 */
export const PulseLoader = (props: ILoaderProps) => (
	<Wrapper style={props.styles}>
		<ReactSpinnerPulseLoader
			color="#9D7FEE"
			margin={2}
			size={props.size || 12}
			speedMultiplier={0.5}
		/>
	</Wrapper>
);

const Wrapper = styled(FlexWrapper)`
	width: fit-content;
`;
