/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateMatchingRuleRequest } from '../models/CreateMatchingRuleRequest';
import type { TagMatchingRule } from '../models/TagMatchingRule';
import type { UpdateMatchingRuleRequest } from '../models/UpdateMatchingRuleRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TagBasedMatchingLogicService {
	/**
	 * Get all Tag-based matching rules for a given club
	 * GET /clubs/<displayId>/tags/matching-logic
	 * @returns TagMatchingRule Successful Response
	 * @throws ApiError
	 */
	public static getMatchingRulesClubsDisplayIdTagsMatchingLogicGet({
		displayId
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
	}): CancelablePromise<Array<TagMatchingRule>> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/clubs/{displayId}/tags/matching-logic',
			path: {
				displayId: displayId
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Create a new Tag-based matching rule for a given club
	 * POST /clubs/<displayId>/tags/matching-logic
	 * @returns TagMatchingRule Successful Response
	 * @throws ApiError
	 */
	public static createMatchingRuleClubsDisplayIdTagsMatchingLogicPost({
		displayId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: CreateMatchingRuleRequest;
	}): CancelablePromise<TagMatchingRule> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/clubs/{displayId}/tags/matching-logic',
			path: {
				displayId: displayId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Update a Tag-based matching rule for a given club
	 * PUT /clubs/<displayId>/tags/matching-logic
	 * @returns TagMatchingRule Successful Response
	 * @throws ApiError
	 */
	public static updateMatchingRuleClubsDisplayIdTagsMatchingLogicPut({
		displayId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: UpdateMatchingRuleRequest;
	}): CancelablePromise<TagMatchingRule> {
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/clubs/{displayId}/tags/matching-logic',
			path: {
				displayId: displayId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}
}
