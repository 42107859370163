/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ScoredMember } from './ScoredMember';

/**
 * Model for AI chat message
 */
export type AiChatMessageModel = {
	/**
	 * Id of the message
	 */
	messageId: string;
	/**
	 * AI chat message text
	 */
	message: string;
	/**
	 * Role of the entity that sent the message
	 */
	role: string;
	/**
	 * Date and time the message was created
	 */
	createdAt: string;
	/**
	 * List of members mentioned in the message
	 */
	mentionedMembers?: Array<ScoredMember> | null;
	/**
	 * The type of the message
	 */
	type: AiChatMessageModel.type;
};

export namespace AiChatMessageModel {
	/**
	 * The type of the message
	 */
	export enum type {
		STREAM = 'stream',
		COMPLETE = 'complete'
	}
}
