/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MessagingData } from '../models/MessagingData';
import type { TestMessageRequest } from '../models/TestMessageRequest';
import type { UpdateMessagingDataRequest } from '../models/UpdateMessagingDataRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MessagingService {
	/**
	 * Get the data included in the intro or opt-in message
	 * GET /clubs/<displayId>/messaging
	 * @returns MessagingData Successful Response
	 * @throws ApiError
	 */
	public static getDataUsageClubsDisplayIdMessagingGet({
		displayId
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
	}): CancelablePromise<Array<MessagingData>> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/clubs/{displayId}/messaging',
			path: {
				displayId: displayId
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Modify the data included in the intro or opt-in message
	 * PUT /clubs/<display id>/messaging
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static updateDataUsageClubsDisplayIdMessagingPut({
		displayId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: UpdateMessagingDataRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/clubs/{displayId}/messaging',
			path: {
				displayId: displayId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Request a test message
	 * POST /clubs/<displayId>/messaging/test
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static requestTestMessageClubsDisplayIdMessagingTestPost({
		displayId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: TestMessageRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/clubs/{displayId}/messaging/test',
			path: {
				displayId: displayId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}
}
