/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EngagementSearchParameters } from '../models/EngagementSearchParameters';
import type { EventDetails } from '../models/EventDetails';
import type { GetEngagementResponse } from '../models/GetEngagementResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EngagementService {
	/**
	 * Search for engagement
	 * @returns GetEngagementResponse Successful Response
	 * @throws ApiError
	 */
	public static getEngagementClubsDisplayIdEngagementSearchPost({
		displayId,
		requestBody,
		page,
		size = 25
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: EngagementSearchParameters;
		page?: number;
		size?: number;
	}): CancelablePromise<GetEngagementResponse> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/clubs/{displayId}/engagement/search',
			path: {
				displayId: displayId
			},
			query: {
				page: page,
				size: size
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Fetch additional details on a singular engagement event
	 * GET /clubs/{displayId}/engagement/{id}
	 * @returns EventDetails Successful Response
	 * @throws ApiError
	 */
	public static getEngagementDetailsClubsDisplayIdEngagementIdGet({
		displayId,
		id
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		id: number;
	}): CancelablePromise<Array<EventDetails>> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/clubs/{displayId}/engagement/{id}',
			path: {
				displayId: displayId,
				id: id
			},
			errors: {
				422: `Validation Error`
			}
		});
	}
}
