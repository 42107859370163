import styled, { CSSObject } from '@emotion/styled';
import { CSSProperties } from 'react';
import Select, {
	MultiValue,
	components,
	ControlProps,
	Props,
	CSSObjectWithLabel,
	ClearIndicatorProps,
	MenuProps
} from 'react-select';

/**
 * Interfaces a single option for the react-select component
 */
export interface ISelectOption {
	/**
	 * Value for this option
	 */
	value: string | number;
	/**
	 * Label for this option
	 */
	label: string;
	/**
	 * True if the option is disabled
	 */
	disabled?: boolean;
}

/**
 * Extension of ISelectOption interface for similarity scale questions
 */
export interface ILikertSelectOption extends ISelectOption {
	/**
	 * Value for this option
	 */
	value: number;
	/**
	 * Optional id to provide on the option
	 */
	id: number;
}

export interface IIntrosSelectProps {
	/**
	 * Placeholder for this react select component
	 */
	placeholder?: string;
	/**
	 * options for this intros select
	 */
	options: ISelectOption[];
	/**
	 * Value for this select
	 */
	value: ISelectOption | ISelectOption[];
	/**
	 * Optional image to show alongside the option
	 */
	image?: string;
	/**
	 * Optional custom width for this intros select
	 */
	width?: number | string;
	/**
	 * Optional custom height for this intros select
	 */
	height?: number;
	/**
	 * Optional margin right for the intros select
	 */
	marginRight?: number | string;
	/**
	 * Optional margin left for the intros select
	 */
	marginLeft?: number | string;
	/**
	 * Font-size for the value container
	 */
	valueFontSize?: number;
	/**
	 * True if this select can take multiple values
	 */
	isMulti?: boolean;
	/**
	 * True if this select value is clearable
	 */
	isClearable?: boolean;
	/**
	 * Override for the z-index of the select
	 */
	zIndex?: number;
	/**
	 * True if we should disable this select
	 */
	disabled?: boolean;
	/**
	 * True if this select should be open by default
	 */
	open?: boolean;
	/**
	 * Custom style overrides for the Select dropdown
	 */
	styles?: CSSObjectWithLabel;
	/**
	 * Optional component to display at the bottom of the menu
	 */
	menuBottomFixedOption?: React.ReactNode;
	/**
	 * Callback to trigger on value change
	 */
	onChange: (value: SelectCallbackInput) => void;
}

/**
 * Union type as semantic sugar for the onChange callback
 */
export type SelectCallbackInput = ISelectOption | MultiValue<ISelectOption>;

/**
 * Extends the existinng props of the React Select Library
 */
interface ExtendedSelectProps extends Props<ISelectOption> {
	/**
	 * Optional image to show alongside the option
	 */
	image?: string;
}

/**
 * Wraps the react-select component while receiving our extended props
 * @param props
 */
const ExtendedSelect = (props: ExtendedSelectProps) => <Select {...props} />;

/**
 * Custom control component for showing an icon alongside the value
 * @param param0
 */
const ControlWithIcon = ({
	children,
	...props
}: ControlProps<ISelectOption>) => {
	const { image } = props.selectProps as ExtendedSelectProps;

	return (
		<components.Control {...props}>
			<MinifiedLogo src={image} alt="group-image" />
			{children}
		</components.Control>
	);
};

/**
 * Our shareable intros select component
 * @param props
 */
export const IntrosSelect = (props: IIntrosSelectProps) => {
	const {
		placeholder,
		options,
		value,
		image,
		width,
		height,
		marginRight,
		marginLeft,
		valueFontSize,
		isMulti,
		isClearable,
		zIndex,
		disabled,
		open,
		styles,
		menuBottomFixedOption,
		onChange
	} = props;

	const ClearIndicator = (
		props: ClearIndicatorProps<ISelectOption, true>
	) => {
		const {
			children = <img src="/assets/svgs/shared/select_clear.svg" />,
			getStyles,
			innerProps: { ref, ...restInnerProps }
		} = props;
		return (
			<div
				{...restInnerProps}
				ref={ref}
				style={getStyles('clearIndicator', props) as CSSProperties}
			>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					{children}
				</div>
			</div>
		);
	};

	const Menu = (props: MenuProps) => {
		const handleFixedOptionClick = () => {
			if (menuBottomFixedOption && props.selectProps.onMenuClose) {
				props.selectProps.onMenuClose();
			}
		};

		return (
			<components.Menu {...props}>
				{props.children}
				{menuBottomFixedOption && (
					<div onClick={handleFixedOptionClick}>
						{menuBottomFixedOption}
					</div>
				)}
			</components.Menu>
		);
	};

	return (
		<ExtendedSelect
			menuPlacement="auto"
			menuPortalTarget={document.getElementById('root')}
			placeholder={placeholder}
			options={options}
			isOptionDisabled={(option) => option.disabled}
			isMulti={(!image && isMulti) as any}
			isClearable={isClearable}
			onChange={onChange}
			value={value ? value : null}
			image={image}
			components={{
				...(image ? { Control: ControlWithIcon } : {}),
				ClearIndicator: (props) => ClearIndicator(props as any),
				Menu: (props) => Menu(props as any)
			}}
			isDisabled={disabled}
			menuIsOpen={open}
			styles={{
				container: (provided) => ({
					...provided,
					width: width ? width : 'auto',
					height: height ? height : 'auto',
					marginRight:
						marginRight !== undefined ? marginRight : 'none',
					marginLeft: marginLeft ? marginLeft : 'none',
					outline: 'none',
					border: 'none',
					borderRadius: '8px',
					pointerEvents: 'auto',
					cursor: disabled ? 'not-allowed' : 'auto',
					...styles
				}),
				indicatorSeparator: () => ({
					display: 'none'
				}),
				clearIndicator: (
					provided: CSSObject,
					state: ClearIndicatorProps<ISelectOption, boolean>
				): CSSObject => ({
					...provided,
					padding: 0
				}),
				control: (provider) => ({
					...provider,
					cursor: disabled ? 'not-allowed' : 'pointer',
					outline: 'none',
					minHeight: 'unset',
					border: '1px solid var(--light-grey-border)',
					'&:hover': {
						border: '1px solid var(--light-grey-border)'
					},
					borderRadius: '8px',
					boxShadow: 'none',
					backgroundColor: 'none',
					height: height ? height : 'auto'
				}),

				placeholder: (provided) => ({
					...provided,
					textAlign: 'start',
					fontSize: valueFontSize ? valueFontSize : 'auto'
				}),
				input: (provided) => ({
					...provided
				}),
				dropdownIndicator: (provided) => ({
					...provided,
					padding: '0px 8px',
					paddingRight: '16px'
				}),
				indicatorsContainer: (provided) => ({
					...provided,
					alignSelf: 'unset',
					height: height ? height : 'auto'
				}),
				menuPortal: (provided) => ({
					...provided,
					zIndex: zIndex ? zIndex : 5
				}),
				valueContainer: (provided) => ({
					...provided,
					textAlign: 'start',
					paddingLeft: '16px',
					fontSize: valueFontSize ? valueFontSize : '14px',
					fontFamily: 'var(--fonts)',
					height: height ? height : 'auto',
					overflow: 'auto'
				}),
				option: (provided, state) => ({
					...provided,
					fontFamily: 'var(--fonts',
					color: 'var(--subtext-grey)',
					textAlign: 'start',
					fontSize: '12px',
					fontWeight: 700,
					backgroundColor: state.isSelected
						? 'var(--select-active)'
						: state.isFocused
						? 'var(--select-focused)'
						: state.isDisabled
						? 'var(--lighter-grey)'
						: '',
					'&:active': {
						backgroundColor: 'var(--select-active)'
					}
				})
			}}
		/>
	);
};

const MinifiedLogo = styled.img`
	margin-left: 8px;
	width: 24px;
	height: 24px;
	object-fit: contain;
`;
