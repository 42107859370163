/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enumize all actions that members can take within the
 */
export enum MemberActivityEnum {
	SIGNED_UP = 'signed up',
	INVITE_SENT = 'invite sent',
	INVITE_ACCEPTED = 'invite accepted',
	PROFILE_UPLOADED = 'profile uploaded',
	OPT_IN = 'opt-in',
	OPT_OUT = 'opt-out',
	INTRO_SENT = 'intro sent',
	TIMES_PROPOSED = 'times proposed',
	TIME_CONFIRMED = 'time confirmed',
	INTRO_JOINED = 'intro joined',
	INTRO_LEFT = 'intro left',
	INTRO_REQUESTED = 'intro requested',
	MEMBERS_MET = 'members met',
	MEMBERS_NOT_MET = 'members not met',
	FEEDBACK_SUBMITTED = 'feedback submitted',
	PROFILE_VISITED = 'profile visited',
	PREFERENCES_VISITED = 'preferences visited',
	DIRECTORY_VISITED = 'directory visited',
	HOME_VISITED = 'home visited',
	REQUESTS_VISITED = 'requests visited',
	ACTIVITY_VISITED = 'activity visited',
	PROFILE_UPDATED = 'profile updated',
	PREFERENCES_UPDATED = 'preferences updated',
	LEFT_GROUP = 'left group',
	REJOINED_GROUP = 'rejoined group'
}
