/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum SubscriptionIntervalEnum {
	MONTH = 'month',
	YEAR = 'year',
	WEEK = 'week',
	DAY = 'day'
}
