/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enumizes our group connection methods
 */
export enum ConnectionTypeEnum {
	AUTOMATED = 'Automated',
	AIASSISTANT = 'AIAssistant',
	DIRECTORY = 'Directory'
}
