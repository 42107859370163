/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enumizes question types
 */
export enum QuestionType {
	MULTISELECT = 'multiselect',
	RADIO = 'radio',
	LIKERT = 'likert',
	FREETEXT = 'freetext',
	INTENTIONAL = 'intentional'
}
