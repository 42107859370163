/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConditionalLogicModel } from '../models/ConditionalLogicModel';
import type { CreateConditionalLogicRequest } from '../models/CreateConditionalLogicRequest';
import type { GetConditionalLogicResponse } from '../models/GetConditionalLogicResponse';
import type { SurveyTypes } from '../models/SurveyTypes';
import type { UpdateConditionalLogicRequest } from '../models/UpdateConditionalLogicRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ConditionalLogicService {
	/**
	 * Get the conditional logic for a question
	 * GET /clubs/<display id>/surveys/<survey type>/questions/<qid>/conditional-logic
	 * @returns GetConditionalLogicResponse Successful Response
	 * @throws ApiError
	 */
	public static getConditionalLogicClubsDisplayIdSurveysSurveyTypeQuestionsQidConditionalLogicGet({
		displayId,
		surveyType,
		qid,
		active,
		aid,
		target
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		surveyType: SurveyTypes;
		qid: number;
		active?: boolean;
		aid?: number;
		target?: number;
	}): CancelablePromise<GetConditionalLogicResponse> {
		return __request(OpenAPI, {
			method: 'GET',
			url:
				'/clubs/{displayId}/surveys/{surveyType}/questions/{qid}/conditional-logic',
			path: {
				displayId: displayId,
				surveyType: surveyType,
				qid: qid
			},
			query: {
				active: active,
				aid: aid,
				target: target
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Create a conditional logic rule for a question
	 * POST /clubs/<display id>/surveys/<survey type>/questions/<qid>/conditional-logic
	 * @returns ConditionalLogicModel Successful Response
	 * @throws ApiError
	 */
	public static createConditionalLogicClubsDisplayIdSurveysSurveyTypeQuestionsQidConditionalLogicPost({
		displayId,
		surveyType,
		qid,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		surveyType: SurveyTypes;
		qid: number;
		requestBody: CreateConditionalLogicRequest;
	}): CancelablePromise<ConditionalLogicModel> {
		return __request(OpenAPI, {
			method: 'POST',
			url:
				'/clubs/{displayId}/surveys/{surveyType}/questions/{qid}/conditional-logic',
			path: {
				displayId: displayId,
				surveyType: surveyType,
				qid: qid
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Update a conditional logic rule for a question
	 * PUT /clubs/<display id>/surveys/<survey type>/questions/<qid>/conditional-logic/<cid>
	 * @returns ConditionalLogicModel Successful Response
	 * @throws ApiError
	 */
	public static updateConditionalLogicClubsDisplayIdSurveysSurveyTypeQuestionsQidConditionalLogicCidPut({
		displayId,
		surveyType,
		qid,
		cid,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		surveyType: SurveyTypes;
		qid: number;
		cid: number;
		requestBody: UpdateConditionalLogicRequest;
	}): CancelablePromise<ConditionalLogicModel> {
		return __request(OpenAPI, {
			method: 'PUT',
			url:
				'/clubs/{displayId}/surveys/{surveyType}/questions/{qid}/conditional-logic/{cid}',
			path: {
				displayId: displayId,
				surveyType: surveyType,
				qid: qid,
				cid: cid
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}
}
