/**
 * Interfaces a connection received from the backend
 */
export interface IConnectionDataModel {
	/**
	 * Unique id associated with this connection
	 */
	cid: number;
	/**
	 * Name of the connected user
	 */
	name: string;
	/**
	 * Status of the connections
	 */
	status: PendingConnectionStatusEnum;
}

export interface IUserDataModel {
	/**
	 * Unique id for this user
	 */
	uid: number;
	/**
	 * First name for this user
	 */
	firstName: string;
	/**
	 * Last name for this user
	 */
	lastName: string;
	/**
	 * Pronouns for this user
	 */
	pronouns: string;
	/**
	 * Profile image for this user
	 */
	profileImageUrl: string;
	/**
	 * Company title for this user
	 */
	title: string;
	/**
	 * Company for this user
	 */
	company: string;
	/**
	 * Bio for this user
	 */
	bio: string;
	/**
	 * Location of this user
	 */
	location: string;
	/**
	 * Twitter of this user
	 */
	twitter: string;
	/**
	 * LinkedIn of this user
	 */
	linkedIn: string;
	/**
	 * Personal site of this user
	 */
	site: string;
}

/**
 * Enumizes the state of a non-rejected Intro Opt-In
 */
export enum PendingConnectionStatusEnum {
	/**
	 * Neither member has responded to the opt-in
	 */
	BothPending = 'Pending',
	/**
	 * The other member has approved the opt-in
	 */
	OtherMemberApproved = 'One Approval',
	/**
	 * The current member has approved the opt-in
	 */
	SelfApproved = 'Self Approved',
	/**
	 * Both members have approved
	 */
	Approved = 'Approved'
}
