/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enumizes the statuses of various matches
 */
export enum datamodels__models__match__types__MatchStatusEnum {
	UNSCHEDULED = 'Unscheduled',
	FIRST_MEMBER_PROPOSED_TIMES = 'First Member Proposed Times',
	SECOND_MEMBER_PROPOSED_TIMES = 'Second Member Proposed Times',
	SCHEDULED = 'Scheduled',
	FIRST_MEMBER_NO_SHOW = 'First Member No Show',
	SECOND_MEMBER_NO_SHOW = 'Second Member No Show',
	MEMBERS_MET = 'Members Met',
	REVIEWED = 'Reviewed'
}
