/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetUsersAvailabilityCompare } from '../models/GetUsersAvailabilityCompare';
import type { UserAvailabilityMap } from '../models/UserAvailabilityMap';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AvailabilityService {
	/**
	 * Get user availability information
	 * GET /users/{uid}/availability
	 * @returns UserAvailabilityMap Successful Response
	 * @throws ApiError
	 */
	public static getUserAvailabilityUsersUidAvailabilityGet({
		uid
	}: {
		uid: string;
	}): CancelablePromise<UserAvailabilityMap> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/users/{uid}/availability',
			path: {
				uid: uid
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Get the availability for users and the array of conflicting dates between them
	 * Get the schedule for a match given its unique hash
	 * @returns GetUsersAvailabilityCompare Successful Response
	 * @throws ApiError
	 */
	public static getCompareUserAvailabilityUsersAvailabilityCompareGet({
		otherUserId
	}: {
		otherUserId?: number;
	}): CancelablePromise<GetUsersAvailabilityCompare> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/users/availability/compare',
			query: {
				otherUserId: otherUserId
			},
			errors: {
				422: `Validation Error`
			}
		});
	}
}
