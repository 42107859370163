/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enumizes all possible QuestionMatchingRule.typeOf values
 */
export enum QuestionMatchingRuleTypeEnum {
	AGE = 'age',
	LOCATION = 'location',
	COMPARISON = 'comparison',
	INTENTIONAL = 'intentional',
	SAME_GROUP = 'same-group',
	CROSS_GROUP = 'cross-group',
	CUSTOM = 'custom'
}
