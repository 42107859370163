/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enumizes the fixed slides for a group's join form
 */
export enum FixedSlidesEnum {
	EMAIL = 'email',
	BASICS = 'basics',
	TIMES = 'times'
}
