import Axios from 'axios-observable';
import { ApiErrorResponse, HttpMethodEnum, HttpRequestParams } from './types';
import { Observable, of, from, throwError } from 'rxjs';

import { AxiosError, AxiosResponse } from 'axios';
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { catchError, map } from 'rxjs/operators';
import { IErrorResponse } from '../../../data-models/response/error/ErrorResponse';
import { IBaseResponse } from '../../../data-models/response/BaseResponse';
import {
	ApolloClient,
	DocumentNode,
	InMemoryCache,
	ObservableQuery
} from '@apollo/client';
import * as ZenObservable from 'zen-observable';

import { CancelablePromise } from '../../../api-client/core/CancelablePromise';
import { OpenAPI } from '../../../api-client';

/**
 * Define a type to be used as input to the service request
 */
type PromiseGenerator = () => CancelablePromise<any>;

export interface IRestApiClientModel {
	/**
	 * Send an api request
	 */
	request(
		path: string,
		params: HttpRequestParams,
		managementAPI: boolean
	): Observable<IBaseResponse | IErrorResponse>;

	/**
	 * Send a GraphQL api request
	 */
	graphqlRequest(
		query: DocumentNode
	): Observable<IBaseResponse | IErrorResponse>;
}

export class RestApiClientModel implements IRestApiClientModel {
	/**
	 * An axios instance that we will use to send requests to our api
	 */
	private instance: Axios = Axios.create({});
	private apolloInstance = new ApolloClient({
		uri: process.env.REACT_APP_BASE_URL + '/graphql',
		cache: new InMemoryCache()
	});

	/**
	 * Responsible for configuring our axios instance
	 */
	constructor() {
		// set base url of our axios instance
		OpenAPI.BASE = process.env.REACT_APP_BASE_URL;
		OpenAPI.HEADERS = {};
	}

	setUser(token: string) {
		(OpenAPI.HEADERS as Record<string, string>)['X-User-Token'] = token;
	}

	graphqlRequest<T = IBaseResponse>(query: DocumentNode) {
		var response: ObservableQuery<T> = this.apolloInstance.watchQuery<T>({
			query: query
		});
		const zenToRx = <T>(zenObservable: ZenObservable<T>): Observable<T> =>
			new Observable((observer) => zenObservable.subscribe(observer));
		return zenToRx<T>((response as unknown) as ZenObservable<T>).pipe(
			map((value: T) => {
				return value;
			}),
			catchError((error: any) => {
				console.log(error);
				return of(error as IErrorResponse);
			})
		);
	}

	slackServiceRequest<T = IBaseResponse>(
		path: string,
		params: HttpRequestParams
	) {
		// Send the correct bearer token in request headers
		this.instance.defaults.headers.common['Authorization'] = ('Bearer ' +
			process.env.REACT_APP_SLACK_SERVICE_PASSWORD) as string;

		// execute the request
		let response: AxiosObservable<T>;
		switch (params.method) {
			case HttpMethodEnum.GET:
				response = this.instance.get(path);
				break;
			case HttpMethodEnum.POST:
				response = this.instance.post(
					process.env.REACT_APP_SLACK_URL + path,
					params.body
				);
				break;
			case HttpMethodEnum.PUT:
				response = this.instance.put(path, params.body);
				break;
			case HttpMethodEnum.DELETE:
				response = this.instance.delete(path, {
					data: params.body
				});
				break;
		}
		return response.pipe(
			map((value: AxiosResponse<T>) => {
				return value.data as T;
			}),
			catchError((error: AxiosError<IErrorResponse>) => {
				console.log(error);
				return of(error.response?.data as IErrorResponse);
			})
		);
	}

	serviceRequest<T>(params: {
		generator: PromiseGenerator;
		managementAPI?: boolean;
		userToken?: string;
	}): Observable<T> {
		/**
		 * Unpack params
		 */
		const { generator, managementAPI, userToken } = params;
		/**
		 * Set headers
		 */
		const headers: Record<string, string> = {};
		// set the user token
		// if (userToken) {
		// 	headers['X-User-Token'] = userToken;
		// }
		// set auth token
		if (managementAPI) {
			(OpenAPI.HEADERS as Record<string, string>)[
				'Authorization'
			] = ('Bearer ' +
				process.env.REACT_APP_AUTH0_MGM_ACCESS_TOKEN) as string;
		} else {
			(OpenAPI.HEADERS as Record<string, string>)[
				'Authorization'
			] = ('Bearer ' +
				process.env.REACT_APP_AUTH0_ACCESS_TOKEN) as string;
		}

		// make request and return observable
		// return from(generator())
		return from(generator()).pipe(
			catchError((error: ApiErrorResponse) => {
				// TODO: handle logging in hhere
				console.log(error);
				return throwError(error);
			})
		) as Observable<T>;
	}

	request<T = IBaseResponse>(
		path: string,
		params: HttpRequestParams,
		managementAPI: boolean = false
	) {
		// Send the correct bearer token in request headers
		if (managementAPI) {
			this.instance.defaults.headers.common[
				'Authorization'
			] = ('Bearer ' +
				process.env.REACT_APP_AUTH0_MGM_ACCESS_TOKEN) as string;
		} else {
			this.instance.defaults.headers.common[
				'Authorization'
			] = ('Bearer ' +
				process.env.REACT_APP_AUTH0_ACCESS_TOKEN) as string;
		}

		// execute the request
		let response: AxiosObservable<T>;
		switch (params.method) {
			case HttpMethodEnum.GET:
				response = this.instance.get(path);
				break;
			case HttpMethodEnum.POST:
				response = this.instance.post(path, params.body);
				break;
			case HttpMethodEnum.PUT:
				response = this.instance.put(path, params.body);
				break;
			case HttpMethodEnum.DELETE:
				response = this.instance.delete(path, {
					data: params.body
				});
				break;
		}
		return response.pipe(
			map((value: AxiosResponse<T>) => {
				return value.data as T;
			}),
			catchError((error: AxiosError<IErrorResponse>) => {
				console.log(error);
				return of(error.response?.data as IErrorResponse);
			})
		);
	}
}

export const RestApiClient = new RestApiClientModel();
