import { action, computed, makeObservable, observable } from 'mobx';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from '../../../AppModel';
import { RestApiClient } from '../../api/rest/restApiClientModel';
import { IGroupIdentificationData } from '../types';
import {
	ClubsAccessGetResponse,
	ClubsAccessUpdateRequest,
	FeatureAccessService
} from '../../../api-client';

/**
 * Interfaces our group feature access
 */
export interface IGroupAccess {
	/**
	 * True if the group can access zapier integration
	 */
	hasZapierAccess: boolean;
	/**
	 * True if the group has access to Slack
	 */
	hasSlackAccess: boolean;
	/**
	 * True if the group can access member defined weights
	 */
	hasMemberDefinedWeightsAccess: boolean;
	/**
	 * True if the group can define its own brand colors
	 */
	hasCustomBrandColorsAccess: boolean;
	/**
	 * True if the group has access to the AI chatbot
	 */
	hasChatbotAccess: boolean;
	/**
	 * True if the group can define its own directory
	 */
	hasCustomDirectoryAccess: boolean;
	/**
	 * True if the group can access member scheduling
	 */
	hasMemberSchedulingAccess: boolean;
	/**
	 * True if the group can access directory intro requests
	 */
	hasDirectoryIntroRequestsAccess: boolean;
	/**
	 * True if the group can access connection history
	 */
	hasConnectionHistoryAccess: boolean;
	/**
	 * True if the group can access its directory
	 */
	hasDirectoryAccess: boolean;
	/**
	 * True if the group can access internal tagging
	 */
	hasInternalTaggingAccess: boolean;
	/**
	 * True if the group can access conditional matching
	 */
	hasConditionalMatchingAccess: boolean;
	/**
	 * True if the group can access email verification
	 */
	hasEmailVerificationAccess: boolean;
	/**
	 * True if the group can access text notifications
	 */
	hasNotificationAccess: boolean;
	/**
	 * True if the group can access data enrichment
	 */
	hasDataEnrichmentAccess: boolean;
	/**
	 * True if we should include the Intros footer in all group emails
	 */
	includeIntrosFooter: boolean;
	/**
	 * True if group members should have access to intercom support
	 */
	membersHaveIntercomSupport: boolean;
	/**
	 * True if group members should have access the identity report
	 */
	canAccessIdentityReport: boolean;
	/**
	 * True if group members should have access the expanded member info report
	 */
	canAccessExpandedReport: boolean;
	/**
	 * True if group members should have access the survey report
	 */
	canAccessSurveyReport: boolean;
	/**
	 * True if group members should have access the insights overview report
	 */
	canAccessOverviewReport: boolean;
	/**
	 * True if group members should have access the insights engagement report
	 */
	canAccessEngagementReport: boolean;
	/**
	 * True if group members should have access the insights feedback report
	 */
	canAccessFeedbackReport: boolean;
	/**
	 * Change the group's access to a feature
	 * @param feature
	 * @param access
	 */
	changeFeatureAccess(params: ClubsAccessUpdateRequest): Observable<unknown>;
}

export class GroupAccess implements IGroupAccess {
	/**
	 * Unique user-readable id for the group to which this config belongs
	 */
	private displayId: string;

	@observable
	public loading: boolean = false;

	/**
	 * Greater than 0 if the group can access zapier integration
	 */
	@observable
	private _zapier: boolean;
	/**
	 * Greater than 0 if the group has access to Slack
	 */
	@observable
	private _slack: boolean;
	/**
	 * Greater than 0 if the group can access its directory
	 */
	@observable
	private _memberDefinedWeights: boolean;
	/**
	 * Greater than 0 if the group can define its own brand colors
	 */
	@observable
	private _customBrandColors: boolean;
	/**
	 * Greater than 0 if the group has access to the AI chatbot
	 */
	@observable
	private _chatbot: boolean;
	/**
	 * Greater than 0 if the group can define its own directory
	 */
	@observable
	private _customDirectory: boolean;
	/**
	 * Greater than 0 if the group can access member scheduling
	 */
	@observable
	private _memberScheduling: boolean;
	/**
	 * Greater than 0 if the group can access directory intro requests
	 */
	@observable
	private _directoryIntroRequests: boolean;
	/**
	 * Greater than 0 if the group can access connection history
	 */
	@observable
	private _connectionHistory: boolean;
	/**
	 * Greater than 0 if the group can access its directory
	 */
	@observable
	private _directory: boolean;
	/**
	 * Greater than 0 if the group can access internal tagging
	 */
	@observable
	private _tagging: boolean;
	/**
	 * Greater than 0 if the group can access conditional matching
	 */
	@observable
	private _conditionalMatching: boolean;
	/**
	 * Greater than 0 if the group can access email verification
	 */
	@observable
	private _emailVerification: boolean;
	/**
	 * Greater than 0 if the group can access text notifications
	 */
	@observable
	private _notifications: boolean;
	/**
	 * Greater than 0 if the group can access data enrichment
	 */
	@observable
	private _dataEnrichment: boolean;

	/**
	 * Greater than 0 if we should include the Intros footer in all group emails
	 */
	@observable
	private _introsFooter: boolean;
	/**
	 * Greater than 0 if group members should have access to intercom support
	 */
	@observable
	private _intercomSupport: boolean;
	/**
	 * Greater than 0 if the group admin should have access the identity report
	 */
	@observable
	private _identityReport: boolean;
	/**
	 * Greater than 0 if the group admin should have access the expanded member info report
	 */
	@observable
	private _expandedReport: boolean;
	/**
	 * Greater than 0 if the group admin should have access the survey report
	 */
	@observable
	private _surveyReport: boolean;
	/**
	 * Greater than 0 if the group admin should have access the insights overview report
	 */
	@observable
	private _overviewReport: boolean;
	/**
	 * Greater than 0 if the group admin should have access the insights engagement report
	 */
	@observable
	private _engagementReport: boolean;
	/**
	 * Greater than 0 if the group admin should have access the insights feedback report
	 */
	@observable
	private _feedbackReport: boolean;

	constructor(params: IGroupIdentificationData) {
		makeObservable(this);
		this.displayId = params.displayId;
		this.loadAccess();
	}

	@computed
	public get hasZapierAccess(): boolean {
		return this._zapier;
	}

	@computed
	public get hasSlackAccess(): boolean {
		return this._slack;
	}

	@computed
	public get hasMemberDefinedWeightsAccess(): boolean {
		return this._memberDefinedWeights;
	}

	@computed
	public get hasCustomBrandColorsAccess(): boolean {
		return this._customBrandColors;
	}

	@computed
	public get hasChatbotAccess(): boolean {
		return this._chatbot;
	}

	@computed
	public get hasCustomDirectoryAccess(): boolean {
		return this._customDirectory;
	}

	@computed
	public get hasMemberSchedulingAccess(): boolean {
		return this._memberScheduling;
	}

	@computed
	public get hasDirectoryIntroRequestsAccess(): boolean {
		return this._directoryIntroRequests;
	}

	@computed
	public get hasConnectionHistoryAccess(): boolean {
		return this._connectionHistory;
	}

	@computed
	public get hasDirectoryAccess(): boolean {
		return this._directory;
	}

	@computed
	public get hasConditionalMatchingAccess(): boolean {
		return this._conditionalMatching;
	}

	@computed
	public get hasInternalTaggingAccess(): boolean {
		return this._tagging;
	}

	@computed
	public get hasEmailVerificationAccess(): boolean {
		return this._emailVerification;
	}

	@computed
	public get hasNotificationAccess(): boolean {
		return this._notifications;
	}

	@computed
	public get hasDataEnrichmentAccess(): boolean {
		return this._dataEnrichment;
	}

	@computed
	public get includeIntrosFooter(): boolean {
		return this._introsFooter;
	}

	@computed
	public get membersHaveIntercomSupport(): boolean {
		return this._intercomSupport;
	}

	@computed
	public get canAccessIdentityReport(): boolean {
		return this._identityReport;
	}

	@computed
	public get canAccessExpandedReport(): boolean {
		return this._expandedReport;
	}

	@computed
	public get canAccessSurveyReport(): boolean {
		return this._surveyReport;
	}

	@computed
	public get canAccessOverviewReport(): boolean {
		return this._overviewReport;
	}

	@computed
	public get canAccessEngagementReport(): boolean {
		return this._engagementReport;
	}

	@computed
	public get canAccessFeedbackReport(): boolean {
		return this._feedbackReport;
	}

	@action
	public changeFeatureAccess(
		params: ClubsAccessUpdateRequest
	): Observable<unknown> {
		return RestApiClient.serviceRequest({
			generator: () =>
				FeatureAccessService.updateClubAccssClubsAccessDisplayIdPut({
					displayId: encodeURIComponent(this.displayId),
					requestBody: params
				}),
			userToken: AppState.user?.cookie
		}).pipe(
			map(() => {
				// set the access
				if (params.zapier !== undefined) {
					this._zapier = params.zapier;
				}
				if (params.slack !== undefined) {
					this._slack = params.slack;
				}
				if (params.directory !== undefined) {
					this._directory = params.directory;
				}
				if (params.conditionalMatching !== undefined) {
					this._conditionalMatching = params.conditionalMatching;
				}
				if (params.tagging !== undefined) {
					this._tagging = params.tagging;
				}
				if (params.emailVerification !== undefined) {
					this._emailVerification = params.emailVerification;
				}
				if (params.introsFooter !== undefined) {
					this._introsFooter = params.introsFooter;
				}
				if (params.intercomSupport !== undefined) {
					this._intercomSupport = params.intercomSupport;
				}
				if (params.identityReport !== undefined) {
					this._identityReport = params.identityReport;
				}
				if (params.expandedReport !== undefined) {
					this._expandedReport = params.expandedReport;
				}
				if (params.surveyReport !== undefined) {
					this._surveyReport = params.surveyReport;
				}
				if (params.overviewReport !== undefined) {
					this._overviewReport = params.overviewReport;
				}
				if (params.engagementReport !== undefined) {
					this._engagementReport = params.engagementReport;
				}
				if (params.feedbackReport !== undefined) {
					this._feedbackReport = params.feedbackReport;
				}
				if (params.memberDefinedWeights !== undefined) {
					this._memberDefinedWeights = params.memberDefinedWeights;
				}
				if (params.customBrandColors !== undefined) {
					this._customBrandColors = params.customBrandColors;
				}
				if (params.chatbot !== undefined) {
					this._chatbot = params.chatbot;
				}
			})
		);
	}

	/**
	 * Load acc
	 * @returns
	 */
	private loadAccess() {
		if (!this.displayId) {
			console.error(
				'[loadAccess] Cannot load group feature access without display id...'
			);
			return;
		}
		this.setLoading(true);
		RestApiClient.serviceRequest<ClubsAccessGetResponse>({
			generator: () =>
				FeatureAccessService.getClubAccessClubsAccessDisplayIdGet({
					displayId: encodeURIComponent(this.displayId)
				})
		}).subscribe((data: ClubsAccessGetResponse) => {
			// set the access
			this.setAccess(data);
			this.setLoading(false);
		});
	}

	/**
	 * Set group access from the data received from GET /groups/{display id}/access
	 * @param accessData
	 */
	@action
	private setAccess(accessData: ClubsAccessGetResponse) {
		this._zapier = accessData.zapier;
		this._conditionalMatching = accessData.conditionalMatching;
		this._directory = accessData.directory;
		this._emailVerification = accessData.emailVerification;
		// this._notifications = accessData.notification;
		this._tagging = accessData.tagging;
		// this._dataEnrichment = accessData.dataEnrichment;
		this._introsFooter = accessData.introsFooter;
		this._intercomSupport = accessData.intercomSupport;
		this._identityReport = accessData.identityReport;
		this._expandedReport = accessData.expandedReport;
		this._surveyReport = accessData.surveyReport;
		this._overviewReport = accessData.overviewReport;
		this._engagementReport = accessData.engagementReport;
		this._feedbackReport = accessData.feedbackReport;
		this._slack = accessData.slack;
		this._memberDefinedWeights = accessData.memberDefinedWeights;
		this._customBrandColors = accessData.customBrandColors;
		this._chatbot = accessData.chatbot;
	}

	/**
	 * Updates the loading state of the config
	 * @param loading
	 */
	@action
	private setLoading(loading: boolean) {
		this.loading = loading;
	}
}
