import { action, computed, makeObservable, observable } from 'mobx';
import { Observable, ReplaySubject, Subject, combineLatest, of } from 'rxjs';
import { getCookie } from './components/shared/utils/cookies';
import { GroupModel, IGroupModel } from './models/group/group';
import { LoginModel } from './models/login/login';
import {
	UserProfile,
	IUserProfile,
	TutorialEnum
} from './models/user/UserProfile';
import { IDeveloperApiCredentialsDataModel } from './data-models/developer/credentials/DeveloperApiCredentials';
import React, { RefObject } from 'react';
import posthog, { Properties } from 'posthog-js';
import { INotificationData } from './components/intros/types';
import { User } from '@auth0/auth0-spa-js';
import { RestApiClient } from './models/api/rest/restApiClientModel';
import { catchError, map } from 'rxjs/operators';
import {
	IGroupAccessFeature,
	IValidateFeatureAccessParams
} from './models/group/access/types';
import { AuthService, ClubAdmin, ClubsService } from './api-client';
import { UserLoginResponse } from './api-client/models/UserLoginResponse';

export type IAppModel = Readonly<AppModel>;

// Define constants
// Paywalling constraints - 5000ms * 60 = poll every 5 secods for a maximum of 5 minutes
const PAYWALL_POLLING_FREQUENCY = 5000; //ms
const PAYWALL_POLLING_LIMIT = 0; // Replace with the original value (60) if we want to poll for a successful plan upgrade followed by calling a success callback

class AppModel implements IAppModel {
	/**
	 * User id of the application user
	 */
	@observable
	public user: IUserProfile;
	/**
	 * Group that is selected for viewing
	 */
	@observable
	public selectedGroup: IGroupModel;
	/**
	 * Tutorial selected for execution by the user
	 */
	@observable
	public selectedTutorial: TutorialEnum;
	/**
	 * Notification to display on the intros page
	 */
	@observable
	public notification: INotificationData;
	/**
	 * True when we should show the demo video for the page we are on
	 */
	@observable
	public showDemoVideo: boolean = false;
	/**
	 * Subject to notify subscribers when the user has been initialized
	 */
	public userInitialized: ReplaySubject<undefined>;
	/**
	 * Subject to notify subscribers of a failed login / should redirect to home
	 * TODO: use on pages
	 */
	public redirectToLogin: Subject<undefined>;
	/**
	 * Subject to notify subscribers when a group has been selected
	 */
	public groupSelected: Subject<undefined>;
	/**
	 * Set to true after the redirect is triggered
	 */
	public redirectTriggered: boolean = false;
	/**
	 * Set to true if we are in the midst of inviting members
	 */
	public inviteMemberFlow: boolean = false;
	/**
	 * Ref to be passed to various ReactJoyride tours
	 */
	public tutorialRef: RefObject<unknown> = React.createRef();
	/**
	 * True if we should show the paywall
	 */
	@observable
	public showPaywallModal: boolean = false;
	/**
	 * True if we should show the club trial info modal
	 */
	@observable
	public showClubTrialInfoModal: boolean = false;
	/**
	 * True if we should show the club unpaid subscription modal
	 */
	@observable
	public showClubUnpaidSubscriptionModal: boolean = false;
	/**
	 * Paywall to display over any feature access
	 */
	@observable
	public paywallFeature: IGroupAccessFeature;
	/**
	 * The callback function that we will call when a successful payment on salesbricks occurrs and our polling detects it.
	 */
	@observable
	public paywallSuccessCallback: () => void;
	/**
	 * The interval used to poll for a successful salesbricks payment
	 */
	private checkPlanPurchasedInterval: NodeJS.Timeout;
	/**
	 * The number of times that we polled for a successful salesbricks payment
	 */
	@observable
	public numTimesCheckForPlanPurchased: number = 0;

	/**
	 * Admin Uid that is saved when we emulate a client
	 */
	@observable
	public savedAdminUid: number;
	/**
	 * Admin Cookie that is saved when we emulate a client
	 */
	@observable
	private savedAdminCookie: string;
	/**
	 * Admin email that is saved when we emulate a client
	 */
	@observable
	private savedAdminEmail: string;

	constructor() {
		makeObservable(this);
		this.userInitialized = new ReplaySubject();
		this.redirectToLogin = new Subject();
		this.groupSelected = new Subject();
	}

	@computed
	public get userId(): number {
		return this.user?.uid;
	}

	@computed
	public get adminInitials(): string {
		if (this.user?.name) {
			const names = this.user?.name?.split(' ');
			const initials = names.shift().charAt(0) + names.pop().charAt(0);
			return initials.toUpperCase();
		}
		return '';
	}

	@computed
	public get adminFirstName(): string {
		return this.user?.name ? this.user?.name?.split(' ')[0] : '';
	}

	@computed
	public get adminLastName(): string {
		return this.user?.name ? this.user.name?.split(/[ ]+/)[1] : '';
	}

	@computed
	public get adminEmail(): string {
		return this.user?.email;
	}

	/**
	 * True if we should execute the homepage tutorial
	 */
	@computed
	public get runHomepageTutorial(): boolean {
		return (
			(this.user?.tutorialExperience || false) &&
			((this.selectedTutorial &&
				this.selectedTutorial === TutorialEnum.Homepage) ||
				!this.user?.tutorialExperience.homepage) &&
			!this.runOnboardingTutorial
		);
	}

	/**
	 * True if we should execute the flow page tutorial
	 */
	@computed
	public get runFlowPageTutorial(): boolean {
		return (
			(this.user?.tutorialExperience || false) &&
			!this.runMatchingTutorial &&
			((this.selectedTutorial &&
				this.selectedTutorial === TutorialEnum.Flow) ||
				!this.user?.tutorialExperience.flowPage)
		);
	}

	/**
	 * True if we should execute the matching tutorial
	 */
	@computed
	public get runMatchingTutorial(): boolean {
		return (
			(this.user?.tutorialExperience || false) &&
			((this.selectedTutorial &&
				this.selectedTutorial === TutorialEnum.Matching) ||
				!this.user?.tutorialExperience.matching)
		);
	}

	/**
	 * True if we should execute the welcome tutorial
	 */
	@computed
	public get runWelcomeTutorial(): boolean {
		return (
			(this.user?.tutorialExperience || false) &&
			((this.selectedTutorial &&
				this.selectedTutorial === TutorialEnum.Welcome) ||
				!this.user?.tutorialExperience.welcome)
		);
	}

	/**
	 * True if we should execute the Intro tutorial
	 */
	@computed
	public get runIntroTutorial(): boolean {
		return (
			(this.user?.tutorialExperience || false) &&
			((this.selectedTutorial &&
				this.selectedTutorial === TutorialEnum.Intro) ||
				!this.user?.tutorialExperience.intro)
		);
	}

	/**
	 * True if we should execute the Review tutorial
	 */
	@computed
	public get runReviewTutorial(): boolean {
		return (
			(this.user?.tutorialExperience || false) &&
			((this.selectedTutorial &&
				this.selectedTutorial === TutorialEnum.Review) ||
				!this.user?.tutorialExperience.review)
		);
	}

	/**
	 * True if we should execute the members tutorial
	 */
	@computed
	public get runMembersTutorial(): boolean {
		return (
			(this.user?.tutorialExperience || false) &&
			((this.selectedTutorial &&
				this.selectedTutorial === TutorialEnum.Members) ||
				!this.user?.tutorialExperience.members)
		);
	}

	/**
	 * True if we should execute the insights tutorial
	 */
	@computed
	public get runInsightsTutorial(): boolean {
		return (
			(this.user?.tutorialExperience || false) &&
			((this.selectedTutorial &&
				this.selectedTutorial === TutorialEnum.Insights) ||
				!this.user?.tutorialExperience.insights)
		);
	}

	/**
	 * True if we should execute the help center tutorial
	 */
	@computed
	public get runHelpTutorial(): boolean {
		return (
			(this.user?.tutorialExperience || false) &&
			((this.selectedTutorial &&
				this.selectedTutorial === TutorialEnum.Help) ||
				!this.user?.tutorialExperience.help)
		);
	}

	/**
	 * True if we should execute the onboarding tutorial
	 */
	@computed
	public get runOnboardingTutorial(): boolean {
		return (
			(this.user?.tutorialExperience || false) &&
			((this.selectedTutorial &&
				this.selectedTutorial === TutorialEnum.Onboarding) ||
				!this.user?.tutorialExperience.onboarding) &&
			this.selectedGroup?.isConfigInitialized
		);
	}

	@computed
	public get groups(): IGroupModel[] {
		return (
			this.user?.adminGroups?.map(
				(admin: ClubAdmin) => new GroupModel(admin.club)
			) || []
		);
	}

	@computed
	public get clientApps(): IDeveloperApiCredentialsDataModel[] {
		return this.user?.clientApps;
	}

	/**
	 * True when we are in internal Intros app
	 */
	@computed
	public get internal(): boolean {
		return process.env.REACT_APP_INTERNAL === 'true';
	}

	/**
	 * True when we are in client emulation mode
	 */
	@computed
	public get inClientEmulation(): boolean {
		return !!this.savedAdminUid;
	}

	/**
	 * Take the Auth0 user and sync credentials with those stored in our database
	 * @param user
	 */
	public syncAuth0Login(user: User): Observable<UserLoginResponse> {
		return RestApiClient.serviceRequest<UserLoginResponse>({
			generator: () =>
				AuthService.loginAuthLoginPost({
					requestBody: {
						email: user.email
					},
					auth0Verified: 'J_FQrshG6FabHM9ZB7wX'
				})
		}).pipe(
			map((data: UserLoginResponse) => {
				// set the current user
				this.setUser(
					data.uid,
					data.cookie,
					data.name?.split(' ')[0],
					data.name?.split(/[ ]+/)[1],
					data.email
				);
				return data;
			}),
			catchError((error) => {
				// The currently logged in user was not found by email. Since Auth0 is the source of truth, this means that we need to create a new user in our backend to keep things in sync.
				UserProfile.createUser({
					email: user.email
				}).subscribe((data: UserLoginResponse) => {
					return data;
				});
				return of(error);
			})
		);
	}

	public attemptCookieLogin() {
		const cookie = getCookie('user');
		if (cookie) {
			// must subscribe to this login to make it fire
			LoginModel.login({ cookie }).subscribe(
				() => {},
				() => {
					// fire failed login so we can redirect to home page
					this.redirectToLogin.next();
					this.redirectTriggered = true;
				}
			);
		} else {
			// fire failed login so we can redirect to home page
			this.redirectToLogin.next();
			this.redirectTriggered = true;
		}
	}

	public selectMostRecentlyUsedFlow() {
		const index = getCookie('selectionIndex');
		if (this.groups?.length) {
			this.setSelectedGroup(Number(index));
		}
	}

	/**
	 * Logout the user and redirect to login page
	 */
	public logout() {
		// remove user cookie
		this.deleteCookie('user');
		// log out of posthog
		posthog.reset();
	}

	/**
	 * Close the paywall by setting the paywall info to undefined
	 */
	public closePaywall() {
		this.setShowPaywallModal(false);
	}

	/**
	 * Close the club trial info modal
	 */
	public closeClubTrialInfoModal() {
		this.setShowClubTrialInfoModal(false);
	}

	/**
	 * Close the club unpaid subscription modal
	 */
	public closeClubUnpaidSubscriptionModal() {
		this.setShowClubUnpaidSubscriptionModal(false);
	}

	/**
	 * Set the category and id cookie for the app
	 */
	public setCategoryAndIdCookie(category: string, id: number | string) {
		this.setCookie('selectionCategory', category);
		this.setCookie('selectionIndex', id.toString());
	}

	/**
	 * Update the value of inviteMemberFlow
	 * @param val
	 */
	public setInviteMembersFlow(val: boolean) {
		this.inviteMemberFlow = val;
	}

	/**
	 * Start a tutorial
	 * @param tutorial
	 */
	@action
	public setSelectedTutorial(tutorial: TutorialEnum) {
		this.selectedTutorial = tutorial;
	}

	/**
	 * Show the demo video for the current workspace
	 * @param show
	 */
	@action
	public setShowDemoVideo(show: boolean) {
		this.showDemoVideo = show;
	}

	/**
	 * Complete a tutorial
	 */
	@action
	public completeTutorial(tutorial: TutorialEnum) {
		this.selectedTutorial = undefined;
		this.user.completedTutorial(tutorial);
	}

	/**
	 * Save the admin who is logged in if this is the first client we are emulating
	 * Then, set the logged in user to be the client we aim to emulate
	 * @param clientUid
	 */
	@action
	public setEmulatedClient(clientUid: number, cookie: string) {
		if (this.savedAdminUid === undefined) {
			this.savedAdminUid = this.user.uid;
			this.savedAdminCookie = this.user.cookie;
			this.savedAdminEmail = this.user.email;
		}
		// set the user
		this.setUser(clientUid, cookie);
		// subscribe to load group feature access on load
		this.userInitialized.subscribe(() => {
			this.groups?.forEach((group: IGroupModel) =>
				group.loadFeatureAccess()
			);
		});
	}

	/**
	 * Logout of client emulation and restore the previous admin profile
	 */
	@action
	public logoutClientEmulation() {
		this.setUser(
			this.savedAdminUid,
			this.savedAdminCookie,
			undefined,
			undefined,
			this.savedAdminEmail,
			true
		);
		this.savedAdminUid = undefined;
		this.savedAdminCookie = undefined;
		this.savedAdminEmail = undefined;
	}

	@action
	public setUser(
		value: number,
		cookie: string,
		firstName?: string,
		lastName?: string,
		email?: string,
		saveCookie?: boolean
	) {
		// set user auth token
		RestApiClient.setUser(cookie);
		// set email and name in metadata if provided
		const identify_metadata: Properties = {
			uid: value
		};
		if (email) {
			identify_metadata['email'] = email;
		}
		if (firstName && lastName) {
			identify_metadata['name'] = `${firstName} ${lastName}`;
		}
		// identify user with posthog
		posthog.identify(value.toString(), identify_metadata);
		// creat user profile
		this.user = new UserProfile({
			uid: value,
			cookie: cookie,
			email: email,
			firstName: firstName,
			lastName: lastName
		});
		// initialize use groups
		combineLatest([this.user.adminGroupsInitialized]).subscribe(() => {
			this.userInitialized.next();
		});
		// check if cookie is defined
		if (saveCookie) {
			// set user cookie
			this.deleteCookie('user');
			this.setCookie('user', cookie);
		}
		this.redirectTriggered = false;
	}

	@action
	public setSelectedGroup(id: number) {
		// set the selected group
		const selectedGroup =
			this.groups.find((group: IGroupModel) => group.gid === id) ||
			this.groups[0];
		this.selectedGroup = selectedGroup;
		// set the cookie associated with this group
		this.setCategoryAndIdCookie('group', selectedGroup?.gid);
		// set the club for associating events
		posthog.group('club', this.selectedGroup.gid.toString(), {
			name: this.selectedGroup.name,
			gid: this.selectedGroup.gid
		});
		// capture an event
		posthog.capture('Admin Dashboard Visted', {
			group: this.selectedGroup?.name,
			source: 'web'
		});
		this.selectedGroup?.loadGroupWorkspace();
		this.selectedGroup?.loadMemberStatusCount().subscribe();
		// Notify subscribers that a group has been selected
		this.groupSelected.next();
	}

	/**
	 * Sets the notification on the page to the matching data
	 * @param data
	 */
	@action
	public setNotification = (data: INotificationData | undefined) => {
		this.notification = data as INotificationData;
		if (this.notification && !data.infinite) {
			setTimeout(() => this.setNotification(undefined), 2500);
		}
	};

	/**
	 * Set the paywall data to display to the user
	 * @param data
	 */
	@action
	public setPaywallFeature(feature: IGroupAccessFeature) {
		this.paywallFeature = feature;
		this.showPaywallModal = true;
	}

	/**
	 * Set the value of showPaywallModal to show/hide the paywall modal
	 */
	@action
	public setShowPaywallModal(value: boolean) {
		this.showPaywallModal = value;
	}

	/**
	 * Open the pricing page on the Intros site
	 */
	@action
	public openIntrosPricingPage() {
		window.open('https://www.intros.ai/pricing', '_blank');
	}

	/**
	 * Set the value of showClubTrialInfoModal to show/hide the club trial info modal
	 */
	@action
	public setShowClubTrialInfoModal(value: boolean) {
		this.showClubTrialInfoModal = value;
	}

	/**
	 * Set the value of showClubUnpaidSubscriptionModal to show/hide the club unpaid subscription modal
	 */
	@action
	public setShowClubUnpaidSubscriptionModal(value: boolean) {
		this.showClubUnpaidSubscriptionModal = value;
	}

	/**
	 * Set the callback that will run on a successful salesbricks payment
	 */
	@action
	public setPaywallSuccessCallback(callback: () => void) {
		this.paywallSuccessCallback = callback;
	}

	/**
	 * Validate that the current user of the currently selected group has access to a feature
	 */
	@action
	public validateFeatureAccess(params: IValidateFeatureAccessParams): void {
		const {
			feature,
			showModalOnFailure,
			immediateSuccessCallback,
			onUpgradeCallback,
			onFailureCallback
		} = params;
		RestApiClient.serviceRequest<boolean>({
			generator: () =>
				ClubsService.checkClubFeatureAccessClubsDisplayIdAccessFeatureGet(
					{
						displayId: encodeURIComponent(
							this.selectedGroup?.displayId
						),
						feature: feature?.codeName
					}
				),
			userToken: AppState.user?.cookie
		}).subscribe((data: boolean) => {
			if (data) {
				if (this.paywallSuccessCallback) {
					// Stop the timer and reset numTimesCheckForPlanPurchased
					clearInterval(this.checkPlanPurchasedInterval);
					this.numTimesCheckForPlanPurchased = 0;
					// Call the post-payment success callback if there is one
					this.paywallSuccessCallback();
					// Reset the paywallSuccessCallback function
					this.paywallSuccessCallback = undefined;
				} else if (immediateSuccessCallback) {
					// Call the immediate success callback if there was one provided
					immediateSuccessCallback();
				}
			} else {
				if (showModalOnFailure) {
					// Display the modal and set the proper feature
					this.setPaywallFeature(feature);
					this.setShowPaywallModal(true);
				}
				if (onUpgradeCallback) {
					// Set the callback to be run when a successful payment is detected
					this.setPaywallSuccessCallback(onUpgradeCallback);
				}
				if (onFailureCallback) {
					// Run the onFailure callback if one was provided
					onFailureCallback();
				}

				//  * TODO - Make user-level request as fallback to failed group-level check
				// RestApiClient.serviceRequest<boolean>({
				// 	generator: () =>
				// 		FeatureAccessService.checkUserFeatureAccessUsersUidAccessFeatureGet(
				// 			{
				// 				uid: 1, //<TODO>,
				// 				feature: feature?.codeName
				// 			}
				// 		),
				// 	userToken: AppState.user?.cookie
				// }).subscribe((data: boolean) => {
				// 	if (data) {
				// 		immediateSuccessCallback();
				// 	} else {
				// 		this.setPaywallFeature(feature);
				// 		onFailureCallback();
				// 	}
				// });
			}
		});
	}

	/**
	 * Navigate to the salesbricks products page, hide the paywall modal, and start polling for a successful payment
	 */
	@action
	public launchPlanUpgradeAndPoll(): void {
		// Open the page for an admin to upgrade their plan
		window.open(process.env.REACT_APP_SALESBRICKS_PRO_PLAN, '_blank');

		// Close the modal
		this.setShowPaywallModal(false);

		// Start timer to check for the plan to be upgraded
		let intervalFunction = () => {
			this.checkIfPlanPurchased();
		};
		this.checkPlanPurchasedInterval = setInterval(
			intervalFunction,
			PAYWALL_POLLING_FREQUENCY
		);
	}

	/**
	 * Check if the current payment feature is now accessible. If we have reached the paywall polling limit, reset the timer and counter
	 */
	@action
	public checkIfPlanPurchased(): void {
		if (this.numTimesCheckForPlanPurchased < PAYWALL_POLLING_LIMIT) {
			this.numTimesCheckForPlanPurchased =
				this.numTimesCheckForPlanPurchased + 1;
			this.validateFeatureAccess({
				feature: this.paywallFeature
			});
		} else {
			clearInterval(this.checkPlanPurchasedInterval);
			this.numTimesCheckForPlanPurchased = 0;
		}
	}

	/**
	 * Sets a cookie for this app
	 * @param name
	 * @param val
	 */
	private setCookie(name: string, val: string) {
		const date = new Date();
		const value = val;

		// Set it expire in 7 days
		date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);

		// Set it
		document.cookie =
			name + '=' + value + '; expires=' + date.toUTCString() + '; path=/';
	}

	/**
	 * Remove a cookie with the given name
	 * @param name
	 */
	private deleteCookie(name: string) {
		const date = new Date();

		// Set it expire in -1 days
		date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);

		// Set it
		document.cookie =
			name + '=; expires=' + date.toUTCString() + '; path=/';
	}
}

export const AppState = new AppModel();
