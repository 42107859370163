/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClubsConfigGetResponse } from '../models/ClubsConfigGetResponse';
import type { ClubsConfigPutRequest } from '../models/ClubsConfigPutRequest';
import type { ClubsConfigPutResponse } from '../models/ClubsConfigPutResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ConfigurationService {
	/**
	 * Load an Intros Club's Configuration.
	 * Used to load an Intros Club's configuration via the API
	 * @returns ClubsConfigGetResponse Successful Response
	 * @throws ApiError
	 */
	public static getConfigClubsDisplayIdConfigGet({
		displayId
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
	}): CancelablePromise<ClubsConfigGetResponse> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/clubs/{displayId}/config',
			path: {
				displayId: displayId
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Update an Intros Club's Configuration.
	 * Used to update an Intros Club's configuration via the API
	 * @returns ClubsConfigPutResponse Successful Response
	 * @throws ApiError
	 */
	public static updateConfigClubsDisplayIdConfigPut({
		displayId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: ClubsConfigPutRequest;
	}): CancelablePromise<ClubsConfigPutResponse> {
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/clubs/{displayId}/config',
			path: {
				displayId: displayId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}
}
