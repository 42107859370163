import moment from 'moment';

/**
 * Convert the given time (selected with the timezone provided) and convert that to the
 * members local timezone
 * @param date
 * @param timezone
 * @returns
 */
export const convertToLocalTimezone = (date: Date, timezone: string) => {
	// get the date given in the target timezone - then strip off timezone
	const dateWithoutZone = moment
		.tz(date, timezone)
		.format('YYYY-MM-DDTHH:mm:ss.SSS');
	// get the local timezone
	const localZone = moment(dateWithoutZone).format('Z');
	// merge the strings to create a date string with timezone
	const dateWithLocalZone = [dateWithoutZone, localZone].join('');
	// create new date from string
	return new Date(dateWithLocalZone);
};
