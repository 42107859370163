import posthog from 'posthog-js';
import { action, computed, makeObservable, observable } from 'mobx';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapStringToInviteTypeEnum } from '../../../components/experiences/invite-editor/types';
import { RecurrenceSettings } from '../../../components/experiences/schedule-intro-round-overlay/add-edit-round-panel/add-edit-round-panel';
import { RoundSchedulingSetting } from '../../../components/experiences/shared/round-scheduling-details/round-scheduling-details';
import { IRoundSchedulingData } from '../../../components/experiences/shared/select-calendar-mode';
import { RestApiClient } from '../../api/rest/restApiClientModel';
import {
	CommunicationTypeEnum,
	MatchingRoundDetailsModel,
	MatchingRoundModel,
	MatchingRoundTypeEnum,
	MatchingRoundsService,
	UpdateMatchingRoundRequest
} from '../../../api-client';

/**
 * Interfaces our MatchingRoundModel
 */
export interface IMatchingRoundModel {
	/**
	 * Unique id for this matching round
	 */
	id: number;
	/**
	 * Unique hash for this matching round
	 */
	hash: string;
	/**
	 * Time of the matching round
	 */
	time: Date;
	/**
	 * True if this round is active, false otherwise
	 */
	active: boolean;
	/**
	 * True if we should send a calendar invite with matches for this round
	 */
	sendCalendarInvite: boolean;
	/**
	 * True if we should send a scheduling link with matches for this round
	 */
	sendSchedulingLink: boolean;
	/**
	 * True if we should send a calendar hold when members sign up for this round
	 */
	sendCalendarHold: boolean;
	/**
	 * Duration of the matching round, in minutes
	 */
	duration: number;
	/**
	 * The number of members that have opted in for this matching round
	 */
	numOptIns: number;
	/**
	 * The number of matches that were part of this matching round
	 */
	numMatches: number;
	/**
	 * Recurrence for this matching round
	 */
	recurrence: RecurrenceSettings;
	/**
	 * True when the model is dirty and requires saving
	 */
	dirty: boolean;
	/**
	 * True when recurrence is dirty
	 */
	recurrenceDirty: boolean;
	/**
	 * Description of the scheduling details associated with this match
	 */
	schedulingDesc: string;
	/**
	 * Channel of communication over which the matching round occurs
	 */
	channel: CommunicationTypeEnum;
	/**
	 * Type of invite to be sent with this matching round
	 */
	inviteType: MatchingRoundTypeEnum;
	/**
	 * Date at which invites were just sent
	 */
	invitesLastSent?: Date;
	/**
	 * Id of the invite round for this matching round
	 */
	inviteId?: number;
	/**
	 * Date that invites are set to send out
	 */
	inviteTime?: Date;
	/**
	 * Ids of members who are signed up
	 */
	signUps: number[];
	/**
	 * The event Id for the event associated with this matching round
	 */
	eventId?: number;
	/**
	 * Id of the flow subscription to which this matching round belongs
	 */
	fid?: number;
	/**
	 * Load sign ups for this matching round
	 */
	loadSignUps(): void;
	/**
	 * Change event registration for current user for this matching round
	 * @param signUp
	 */
	changeEventRegistration(signUp: boolean): Observable<void>;
	/**
	 * Update the date of this matching round
	 */
	updateDate(date: Date): void;
	/**
	 * Update the time fo this matching round
	 */
	updateTime(time: Date): void;
	/**
	 * Set recurrence for the matching round
	 */
	setRecurrence(recurrence: RecurrenceSettings): void;
	/**
	 * Set invite type for the matching round
	 */
	setInviteType(type: MatchingRoundTypeEnum): void;
	/**
	 * Update whether this match is active
	 */
	setActive(active: boolean): void;
	/**
	 * Update the scheduling details for this round
	 */
	updateSchedulingDetails(data: IRoundSchedulingData): void;
	/**
	 * Update this matching round
	 * @param editEntireFlowSubscription True if we should edit all future matching rounds on this flow
	 */
	update(editEntireFlowSubscription?: boolean): Observable<void>;
	/**
	 * Cancel this upcoming matching round
	 */
	cancel(): Observable<void>;
}

/**
 * Params for instantiating our matching round model
 */
export interface IMatchingRoundModelParams {
	/**
	 * Display id associated with the group for this matching round
	 */
	displayId: string;
	/**
	 * Underlying data from which to construct our model
	 */
	model: MatchingRoundModel;
	/**
	 * Callback to trigger the parent group to reload their matches
	 */
	onReloadMatches(): void;
}

/**
 * Adds state to our matching round data models
 */
export class MatchingRoundClassModel implements IMatchingRoundModel {
	@observable
	public time: Date;
	@observable
	public active: boolean;
	@observable
	public sendCalendarInvite: boolean;
	@observable
	public sendSchedulingLink: boolean;
	@observable
	public sendCalendarHold: boolean;
	@observable
	public duration: number;
	@observable
	public numOptIns: number;
	@observable
	public numMatches: number;
	@observable
	public invitesLastSent: Date;
	@observable
	public recurrence: RecurrenceSettings;
	@observable
	public channel: CommunicationTypeEnum;
	@observable
	public inviteType: MatchingRoundTypeEnum;
	@observable
	public signUps: number[] = [];
	public id: number;
	public fid: number;
	public hash: string;
	/**
	 * Underlying data model from which this matching round is created
	 */
	@observable
	private _originalModel: MatchingRoundModel;
	/**
	 * Display id associated with the group in which this matching round exists
	 */
	private _displayId: string;
	/**
	 * Reload all matches in the group
	 */
	private reloadMatches: () => void;

	/**
	 * Setup the model from an IMatchingRoundDataModel
	 */
	constructor(params: IMatchingRoundModelParams) {
		makeObservable(this);
		const model: MatchingRoundModel = params.model;
		this.id = model.id;
		this.fid = model.fid;
		this.hash = model.hash;
		this._displayId = params.displayId;
		this.time = model.time ? new Date(model.time + 'Z') : undefined;
		this.active = model.active;
		this.sendCalendarInvite =
			model.schedulingSettings.shouldSendCalendarInvite;
		this.sendSchedulingLink =
			model.schedulingSettings.shouldSendSchedulingLink;
		this.sendCalendarHold = model.schedulingSettings.shouldSendCalendarHold;
		this.duration = model.duration;
		this.numOptIns = model.numOptIns;
		this.numMatches = model.numMatches;
		this.invitesLastSent = model.invitesLastSent
			? new Date(model.invitesLastSent)
			: undefined;
		this.inviteType = mapStringToInviteTypeEnum(model.inviteType);
		this.channel = model.channel;
		// determine recurrent from the model
		this.recurrence = this.numberOfHoursToRecurrenceSetting(
			model.recurrence
		);
		this._originalModel = model;
		this.reloadMatches = params.onReloadMatches;
	}

	@computed
	public get schedulingDesc(): string {
		return this.sendCalendarInvite
			? RoundSchedulingSetting.CALENDAR_INVITE
			: this.sendSchedulingLink
			? RoundSchedulingSetting.SCHEDULE_LINK
			: RoundSchedulingSetting.NONE;
	}

	@computed
	public get recurrenceDirty(): boolean {
		return (
			this.recurrence !==
			this.numberOfHoursToRecurrenceSetting(
				this._originalModel.recurrence
			)
		);
	}

	@computed
	public get dirty(): boolean {
		return (
			this.time?.getTime() !==
				new Date(this._originalModel.time + 'Z')?.getTime() ||
			this.active !== this._originalModel.active ||
			this.sendCalendarInvite !==
				this._originalModel.schedulingSettings
					.shouldSendCalendarInvite ||
			this.sendCalendarHold !==
				this._originalModel.schedulingSettings.shouldSendCalendarHold ||
			this.sendSchedulingLink !==
				this._originalModel.schedulingSettings
					.shouldSendSchedulingLink ||
			this.duration !== this._originalModel.duration ||
			this.inviteType.toLowerCase() !== this._originalModel.inviteType ||
			this.recurrenceDirty
		);
	}

	@action
	public updateSchedulingDetails(data: IRoundSchedulingData) {
		this.sendCalendarInvite = data.shouldSendCalendarInvite > 0;
		this.sendSchedulingLink = data.shouldSendSchedulingLink > 0;
		this.sendCalendarHold = data.shouldSendCalendarHold > 0;
		this.duration = data.duration;
	}

	@action
	public updateDate(date: Date) {
		// Initialize the time if it is not set already
		if (!this.time) {
			const intermediateDate: Date = new Date();
			intermediateDate.setHours(12, 0, 0, 0);
			this.time = intermediateDate;
		}

		// Create a copy of the date and modify the date
		const _time = new Date(this.time?.getTime());

		// Update the date portion of this.time
		_time.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());

		// update the time that set
		this.time = _time;
	}

	@action
	public updateTime(time: Date) {
		// Initialize the time if it is not set already
		if (!this.time) {
			this.time = new Date();
		}
		// Create a copy of the date and modify the date
		const _time = new Date(this.time?.getTime());

		// Update the time portion of this.time
		_time.setHours(time?.getHours(), time?.getMinutes(), 0, 0);

		// Ensure that the date portion of this.time is not modified
		_time.setFullYear(time.getFullYear(), time.getMonth(), time.getDate());

		// update the time that set
		this.time = _time;
	}

	@action
	public setRecurrence(recurrence: RecurrenceSettings) {
		this.recurrence = recurrence;
	}

	@action
	public setInviteType(type: MatchingRoundTypeEnum) {
		this.inviteType = type;
	}

	/**
	 * Update the date that invites were last sent
	 */
	@action
	private setInvitesLastSent(date: Date) {
		this.invitesLastSent = date;
	}

	/**
	 * Delete this matching round
	 */
	@action
	public delete(editEntireFlow: boolean = false) {
		return RestApiClient.serviceRequest({
			generator: () =>
				MatchingRoundsService.deleteMatchingRoundClubsDisplayIdMatchingRoundsMatchingRoundIdDelete(
					{
						displayId: encodeURIComponent(this._displayId),
						matchingRoundId: this.id,
						requestBody: {
							editEntireFlow: editEntireFlow
						}
					}
				)
		}).pipe(
			map(() => {
				this._originalModel.active = false;
			})
		);
	}

	public update(editEntireFlowSubscription?: boolean) {
		// construct body for request
		const body: UpdateMatchingRoundRequest = {
			schedulingSettings: {
				shouldSendCalendarHold: this.sendCalendarHold,
				shouldSendCalendarInvite: this.sendCalendarInvite,
				shouldSendSchedulingLink: this.sendSchedulingLink
			},
			editEntireFlow: editEntireFlowSubscription
		};
		if (
			this.time?.getTime() !==
			new Date(this._originalModel.time + 'Z')?.getTime()
		) {
			body.time = this.time?.toISOString();
		}
		if (
			this.sendCalendarInvite !==
			this._originalModel.schedulingSettings.shouldSendCalendarInvite
		) {
			body.schedulingSettings.shouldSendCalendarInvite = this.sendCalendarInvite;
		}
		if (
			this.sendCalendarHold !==
			this._originalModel.schedulingSettings.shouldSendCalendarHold
		) {
			body.schedulingSettings.shouldSendCalendarHold = this.sendCalendarHold;
		}
		if (
			this.sendSchedulingLink !==
			this._originalModel.schedulingSettings.shouldSendSchedulingLink
		) {
			body.schedulingSettings.shouldSendSchedulingLink = this.sendSchedulingLink;
		}
		if (this.duration !== this._originalModel.duration) {
			body.duration = this.duration;
		}
		if (this.active !== this._originalModel.active) {
			body.active = this.active;
		}
		if (
			this.recurrence !==
			this.numberOfHoursToRecurrenceSetting(
				this._originalModel.recurrence
			)
		) {
			// calculate recurrence
			let recurrence: number = 0;
			switch (this.recurrence) {
				case RecurrenceSettings.Weekly:
					recurrence = 168;
					break;
				case RecurrenceSettings.BiWeekly:
					recurrence = 336;
					break;
				case RecurrenceSettings.TriWeekly:
					recurrence = 504;
					break;
				case RecurrenceSettings.Monthly:
					recurrence = 672;
					break;
			}
			body.recurrence = recurrence;
		}
		if (this.inviteType !== this._originalModel.inviteType) {
			body.optInStyle = this.inviteType;
		}

		return RestApiClient.serviceRequest({
			generator: () =>
				MatchingRoundsService.updateMatchingRoundClubsDisplayIdMatchingRoundsMatchingRoundIdPut(
					{
						displayId: encodeURIComponent(this._displayId),
						matchingRoundId: this.id,
						requestBody: body
					}
				)
		}).pipe(
			map(() => {
				if (body.recurrence) {
					// reload matches if we updated recurrence
					this.reloadMatches();
				} else {
					// otherwise update just this match using the passed body
					Object.entries(body).forEach((pair: [string, unknown]) => {
						if (pair[0] === 'optInStyle') {
							(this._originalModel as any)['inviteType'] =
								pair[1];
						} else {
							(this._originalModel as any)[pair[0]] = pair[1];
						}
					});
				}
			})
		);
	}

	/**
	 * Update whether this match is active
	 */
	@action
	public setActive(active: boolean) {
		this.active = active;
	}

	/**
	 * Revert all fields to the value of the original model
	 */
	@action
	public discardChanges() {
		this.time = new Date(this._originalModel.time + 'Z');
		this.active = this._originalModel.active;
		this.sendCalendarInvite = this._originalModel.schedulingSettings.shouldSendCalendarInvite;
		this.sendCalendarHold = this._originalModel.schedulingSettings.shouldSendCalendarHold;
		this.sendSchedulingLink = this._originalModel.schedulingSettings.shouldSendSchedulingLink;
		this.duration = this._originalModel.duration;
		this.inviteType = mapStringToInviteTypeEnum(
			this._originalModel.inviteType
		);
		this.recurrence = this.numberOfHoursToRecurrenceSetting(
			this._originalModel.recurrence
		);
	}

	/**
	 * Update the channel of this matching round
	 * @param channel
	 */
	@action
	public setChannel(channel: CommunicationTypeEnum) {
		this.channel = channel;
	}

	public cancel() {
		this.setActive(false);
		return this.update();
	}

	public changeEventRegistration(signUp: boolean) {
		return RestApiClient.serviceRequest<number>({
			generator: () =>
				MatchingRoundsService.respondToInviteForMatchingRoundClubsDisplayIdMatchingRoundsMatchingRoundIdInvitesPut(
					{
						displayId: encodeURIComponent(this._displayId),
						matchingRoundId: this.id,
						requestBody: {
							accept: signUp
						}
					}
				)
		}).pipe(
			map((uid: number) => {
				if (signUp) {
					this.signUps.push(uid);
				} else {
					this.signUps = this.signUps.filter(
						(id: number) => id !== uid
					);
				}
				// capture action
				posthog.capture(
					`Opted-${signUp ? 'In to' : 'Out of'} Matching Round`,
					{
						displayId: encodeURIComponent(this._displayId),
						mrid: this.id,
						source: 'web'
					}
				);
			})
		);
	}

	public loadSignUps() {
		RestApiClient.serviceRequest<MatchingRoundDetailsModel>({
			generator: () =>
				MatchingRoundsService.getMatchingRoundDetailsClubsDisplayIdMatchingRoundsMatchingRoundIdGet(
					{
						displayId: encodeURIComponent(this._displayId),
						matchingRoundId: this.id
					}
				)
		}).subscribe((data: MatchingRoundDetailsModel) => {
			this.signUps = data.signUps;
		});
	}

	/**
	 * Consume the number of hours between rounds and output our user readable recurrence setting
	 * @param hours
	 */
	private numberOfHoursToRecurrenceSetting(
		hours: number
	): RecurrenceSettings {
		if (hours) {
			const multiple: number = hours / 168;
			switch (multiple) {
				case 1:
					return RecurrenceSettings.Weekly;
				case 2:
					return RecurrenceSettings.BiWeekly;
				case 3:
					return RecurrenceSettings.TriWeekly;
				case 4:
					return RecurrenceSettings.Monthly;
			}
		}
		return RecurrenceSettings.None;
	}
}
