/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enumizes the types of surveys
 */
export enum SurveyTypes {
	PROFILE = 'profile',
	FEEDBACK = 'feedback'
}
