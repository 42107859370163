/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enumizes our group communication types
 */
export enum CommunicationTypeEnum {
	EMAIL = 'email',
	TEXT = 'text',
	DISCORD = 'discord',
	SLACK = 'slack'
}
