/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateTagPostRequest } from '../models/CreateTagPostRequest';
import type { GetTagsResponse } from '../models/GetTagsResponse';
import type { routes__clubs__tags__ClubsTagsRouter__MemberTagModel } from '../models/routes__clubs__tags__ClubsTagsRouter__MemberTagModel';
import type { UpdateTagRequest } from '../models/UpdateTagRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TaggingService {
	/**
	 * Get all tags for a group
	 * GET /clubs/{displayId}/tags
	 * @returns GetTagsResponse Successful Response
	 * @throws ApiError
	 */
	public static getTagsClubsDisplayIdTagsGet({
		displayId
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
	}): CancelablePromise<GetTagsResponse> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/clubs/{displayId}/tags',
			path: {
				displayId: displayId
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Create a new tag for a group
	 * @returns routes__clubs__tags__ClubsTagsRouter__MemberTagModel Successful Response
	 * @throws ApiError
	 */
	public static createTagClubsDisplayIdTagsPost({
		displayId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: CreateTagPostRequest;
	}): CancelablePromise<routes__clubs__tags__ClubsTagsRouter__MemberTagModel> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/clubs/{displayId}/tags',
			path: {
				displayId: displayId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Delete a tag for a group
	 * DELETE /clubs/{displayId}/tags/{tid}
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static deleteTagClubsDisplayIdTagsTidDelete({
		displayId,
		tid
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		tid: number;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/clubs/{displayId}/tags/{tid}',
			path: {
				displayId: displayId,
				tid: tid
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Update a tag for a group
	 * PUT /clubs/{displayId}/tags/{tid}
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static updateTagClubsDisplayIdTagsTidPut({
		displayId,
		tid,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		tid: number;
		requestBody: UpdateTagRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/clubs/{displayId}/tags/{tid}',
			path: {
				displayId: displayId,
				tid: tid
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}
}
