/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { QuestionBankEntry } from '../models/QuestionBankEntry';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class QuestionBankService {
	/**
	 * Get all questions in the question bank
	 * GET /internal/question-bank
	 * @returns QuestionBankEntry Successful Response
	 * @throws ApiError
	 */
	public static getQuestionBankInternalQuestionBankGet(): CancelablePromise<
		Array<QuestionBankEntry>
	> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/internal/question-bank'
		});
	}
}
