/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enumizes event priorities for our monitor
 */
export enum SystemMonitorEventPriority {
	INFO = 'Info',
	WARNING = 'Warning',
	ALERT = 'Alert'
}
