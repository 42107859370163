import styled from '@emotion/styled';
import {
	IntrosSelect,
	ISelectOption
} from '../../../shared/select/intros-select';
import { ChangeEvent } from 'react';
import { observer } from 'mobx-react';

/**
 * Enumize all round scheduling options
 */
export enum RoundSchedulingSetting {
	CALENDAR_INVITE = 'Send Calendar Invite w/ Intro',
	SCHEDULE_LINK = 'Send Scheduling Link w/ Intro',
	NONE = 'No Scheduling or Calendar Links'
}

/**
 * Enumize all calendar hold options
 */
export enum CalendarHoldSetting {
	SEND_CALENDAR_HOLD = 'Send a Calendar Hold when Members Sign Up',
	DO_NOT_SEND_HOLD = 'Do not send a Calendar Hold'
}

/**
 * Data that describes how the round should schedule
 */
export interface IRoundSchedulingData {
	/**
	 * Whether or not a google calendar link should be sent to schedule the Intros
	 */
	shouldSendCalendarInvite: number;
	/**
	 * Whether or not a scheduling link should be sent to schedule the Intros
	 */
	shouldSendSchedulingLink: number;
	/**
	 * Whether we should send a calendar hold when the member signs up
	 */
	shouldSendCalendarHold: number;
	/**
	 * Duration of the calendar invite
	 */
	duration: number;
}

/**
 * Props for our SelectCalendarMode component
 */
export interface ISchedulingDetailsProps {
	/**
	 * True if we should send a calendar invite with this round
	 */
	shouldSendCalendarInvite: boolean;
	/**
	 * True if we should send scheduling link with this round
	 */
	shouldSendSchedulingLink: boolean;
	/**
	 * True if we should send a calendar hold with this round
	 */
	shouldSendCalendarHold: boolean;
	/**
	 * Duration for which we should make the calendar invite
	 */
	duration: number;
	/**
	 * Callback to update the matching round
	 */
	updateMatchingRound: (data: IRoundSchedulingData) => void;
}

/**
 * A select dropdown to configure how members should schedule an event
 */
export const RoundSchedulingDetails = observer(
	(props: ISchedulingDetailsProps) => {
		const {
			shouldSendCalendarInvite,
			shouldSendSchedulingLink,
			duration,
			shouldSendCalendarHold,
			updateMatchingRound
		} = props;
		return (
			<>
				<IntrosSelect
					width={264}
					marginRight={8}
					placeholder={'---'}
					value={{
						value: shouldSendCalendarInvite
							? RoundSchedulingSetting.CALENDAR_INVITE
							: shouldSendSchedulingLink
							? RoundSchedulingSetting.SCHEDULE_LINK
							: RoundSchedulingSetting.NONE,
						label: shouldSendCalendarInvite
							? RoundSchedulingSetting.CALENDAR_INVITE
							: shouldSendSchedulingLink
							? RoundSchedulingSetting.SCHEDULE_LINK
							: RoundSchedulingSetting.NONE
					}}
					options={[
						{
							value: RoundSchedulingSetting.CALENDAR_INVITE,
							label: RoundSchedulingSetting.CALENDAR_INVITE
						},
						{
							value: RoundSchedulingSetting.SCHEDULE_LINK,
							label: RoundSchedulingSetting.SCHEDULE_LINK
						},
						{
							value: RoundSchedulingSetting.NONE,
							label: RoundSchedulingSetting.NONE
						}
					]}
					onChange={
						((value: ISelectOption) => {
							updateMatchingRound({
								shouldSendCalendarInvite:
									value?.value ===
									RoundSchedulingSetting.CALENDAR_INVITE
										? 1
										: 0,
								shouldSendSchedulingLink:
									value?.value ===
									RoundSchedulingSetting.SCHEDULE_LINK
										? 1
										: 0,
								shouldSendCalendarHold:
									value?.value ===
									RoundSchedulingSetting.SCHEDULE_LINK
										? 0
										: shouldSendCalendarHold
										? 1
										: 0,
								duration: duration
							});
						}) as any
					}
				/>
				<MatchInputLabel>for</MatchInputLabel>
				<DurationInput
					type="number"
					value={duration}
					onChange={(e: ChangeEvent<HTMLInputElement>) => {
						updateMatchingRound({
							shouldSendCalendarInvite: shouldSendCalendarInvite
								? 1
								: 0,
							shouldSendSchedulingLink: shouldSendSchedulingLink
								? 1
								: 0,
							shouldSendCalendarHold: shouldSendCalendarHold
								? 1
								: 0,
							duration: e.target.valueAsNumber
						});
					}}
					step={15}
					min={15}
					max={120}
					placeholder="---"
				/>
				{shouldSendCalendarInvite ? (
					<>
						<MatchInputLabel>mins and </MatchInputLabel>
						<IntrosSelect
							width={240}
							marginLeft="auto"
							placeholder={'---'}
							value={{
								value: shouldSendCalendarHold
									? CalendarHoldSetting.SEND_CALENDAR_HOLD
									: CalendarHoldSetting.DO_NOT_SEND_HOLD,
								label: shouldSendCalendarHold
									? CalendarHoldSetting.SEND_CALENDAR_HOLD
									: CalendarHoldSetting.DO_NOT_SEND_HOLD
							}}
							options={[
								{
									value:
										CalendarHoldSetting.SEND_CALENDAR_HOLD,
									label:
										CalendarHoldSetting.SEND_CALENDAR_HOLD
								},
								{
									value: CalendarHoldSetting.DO_NOT_SEND_HOLD,
									label: CalendarHoldSetting.DO_NOT_SEND_HOLD
								}
							]}
							onChange={
								((value: ISelectOption) => {
									updateMatchingRound({
										shouldSendCalendarInvite: 1,
										shouldSendSchedulingLink: 0,
										shouldSendCalendarHold:
											value?.value ===
											CalendarHoldSetting.SEND_CALENDAR_HOLD
												? 1
												: 0,
										duration: duration
									});
								}) as any
							}
						/>
					</>
				) : (
					<MatchInputLabel>mins</MatchInputLabel>
				)}
			</>
		);
	}
);

const MatchInputLabel = styled.div`
	font-size: 14px;
	line-height: 16px;
	color: var(--subtext-grey);
	margin: auto;
	margin-left: 0;
	margin-right: 8px;
`;

const DurationInput = styled.input`
	outline: none;
	height: 30px;
	box-sizing: border-box;
	border-radius: 4px;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 17px;
	letter-spacing: 0.02em;
	text-align: center;
	color: var(--subtext-grey);
	border: 1px solid var(--light-grey);
	padding: 0px 4px;
	align-items: center;
	width: 48px;
	margin-right: 8px;
`;
