/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enumizes operators for filter rows
 */
export enum FilterOperatorEnum {
	AND = 'AND',
	OR = 'OR'
}
