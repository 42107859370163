/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DiscordAddPostRequest } from '../models/DiscordAddPostRequest';
import type { DiscordKickoffPostRequest } from '../models/DiscordKickoffPostRequest';
import type { GetDiscordOauthResponse } from '../models/GetDiscordOauthResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DiscordService {
	/**
	 * Get OAuth URL to authenticate and allow Intros Bot to join a Discord Server
	 * GET /internal/integrations/webhooks/discord/oauth
	 * @returns GetDiscordOauthResponse Successful Response
	 * @throws ApiError
	 */
	public static getOauthUrlInternalIntegrationsWebhooksDiscordOauthGet({
		displayId
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
	}): CancelablePromise<GetDiscordOauthResponse> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/internal/integrations/webhooks/discord/oauth',
			query: {
				displayId: displayId
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Add Discord Server to Intros Club
	 * POST /internal/integrations/webhooks/discord/add
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static addDiscordServerInternalIntegrationsWebhooksDiscordAddPost({
		requestBody
	}: {
		requestBody: DiscordAddPostRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/internal/integrations/webhooks/discord/add',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Kickoff Intros in the Discord Server
	 * POST /internal/integrations/webhooks/discord/kickoff
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static kickoffDiscordServerInternalIntegrationsWebhooksDiscordKickoffPost({
		requestBody
	}: {
		requestBody: DiscordKickoffPostRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/internal/integrations/webhooks/discord/kickoff',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}
}
