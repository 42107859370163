import './App.css';
import { Suspense, lazy, ComponentType } from 'react';
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate
} from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';

import { AppState } from './AppModel';
import { NoMobileAccess } from './components/no-mobile-access/no-mobile-access';
import { UnauthenticatedPage } from './components/unauthenticated-page/unauthenticated-page';

const Intros = lazy(() =>
	import('./components/intros/intros').then((module) => ({
		default: module.Intros
	}))
);
const Onboarding = lazy(() =>
	import('./components/onboarding/onboarding').then((module) => ({
		default: module.Onboarding
	}))
);
const Join = lazy(() =>
	import('./components/join/join').then((module) => ({
		default: module.Join
	}))
);
const MatchPreview = lazy(() =>
	import('./components/match-preview/match-preview').then((module) => ({
		default: module.MatchPreview
	}))
);
const MemberProfileComponent = lazy(() =>
	import('./components/member-profile/member-profile').then((module) => ({
		default: module.MemberProfileComponent
	}))
);
const UnauthenticatedPageComponent = lazy(() =>
	import('./components/unauthenticated-page/unauthenticated-page').then(
		(module) => ({
			default: module.UnauthenticatedPage
		})
	)
);
const GetStarted = lazy(() =>
	import('./components/get-started/get-started').then((module) => ({
		default: module.GetStarted
	}))
);
const MemberSignIn = lazy(() =>
	import('./components/member-sign-in/member-sign-in').then((module) => ({
		default: module.MemberSignIn
	}))
);
const NeverMet = lazy(() =>
	import('./components/never-met/never-met').then((module) => ({
		default: module.NeverMet
	}))
);

const AcceptInvite = lazy(() =>
	import('./components/accept-invite/accept-invite').then((module) => ({
		default: module.AcceptInvite
	}))
);
/**
 * Forces the user to authenticate before accessing a route
 * @param param0
 */
const ProtectedRoute = ({
	component,
	...args
}: {
	component: ComponentType;
}) => {
	const Component = withAuthenticationRequired(component, args);
	return <Component />;
};

function App() {
	return (
		<Suspense fallback={<></>}>
			<Router>
				<Routes>
					{/**
					 * Below paths are accessed by members of communities
					 */}
					{/* Home page for members to approve more members or update their profile */}
					<Route path="/home" element={<MemberProfileComponent />} />
					{/* View your past matches */}
					<Route
						path="/review-matches"
						element={<MemberProfileComponent />}
					/>
					{/* Update your user profile */}
					<Route
						path="/member-profile"
						element={<MemberProfileComponent />}
					/>
					{/* Update your user matching preferences */}
					<Route
						path="/preferences"
						element={<MemberProfileComponent />}
					/>
					{/* Review Future Connections / Opt-In or Reject Matches */}
					<Route
						path="/connections"
						element={<MemberProfileComponent />}
					/>
					{/* Review Future Connections / Opt-In or Reject Matches */}
					<Route
						path="/approve-intros"
						element={<MemberProfileComponent />}
					/>
					{/* Member Directory for their group */}
					<Route
						path="/directory"
						element={<MemberProfileComponent />}
					/>
					{/* Member AI Chat for their group */}
					<Route
						path="/ai-chat"
						element={<MemberProfileComponent />}
					/>
					{/* Member Upcoming Events for their group */}
					<Route
						path="/upcoming-events"
						element={<MemberProfileComponent />}
					/>
					{/* Member Requests for their group */}
					<Route
						path="/requests"
						element={<MemberProfileComponent />}
					/>
					{/* Member History for for their group */}
					<Route
						path="/history"
						element={<MemberProfileComponent />}
					/>
					{/* Accept or Deny a single opt-in */}
					<Route
						path="/opt-in"
						element={<MemberProfileComponent />}
					/>
					{/* Accept or Deny a proposal request */}
					<Route
						path="/proposal-response"
						element={<MemberProfileComponent />}
					/>
					{/* Meet with another member of your community */}
					<Route
						path="match/:match/meet"
						element={<MemberProfileComponent />}
					/>
					{/* Schedule match with another member of your community */}
					<Route
						path="match/:match/schedule"
						element={<MemberProfileComponent />}
					/>
					{/* Reschedule with another member of your community */}
					<Route
						path="match/:match/reschedule"
						element={<MemberProfileComponent />}
					/>
					{/* Cancel match with another member of your community */}
					<Route
						path="match/:match/cancel"
						element={<MemberProfileComponent />}
					/>
					{/* [Unauthenticated] Schedule match with another member of your community */}
					<Route
						path="/schedule/:match"
						element={<UnauthenticatedPageComponent />}
					/>
					{/* [Unauthenticated] Schedule match with another member of your community */}
					<Route
						path="/reschedule/:match"
						element={<UnauthenticatedPageComponent />}
					/>
					{/* [Unauthenticated] Cancel match with another member of your community */}
					<Route
						path="/cancel/:match"
						element={<UnauthenticatedPageComponent />}
					/>
					{/* [Unauthenticated] Meeting with another member of your community */}
					<Route
						path="/meet/:match"
						element={<UnauthenticatedPageComponent />}
					/>
					{/* Redirect uri in order to connect user with LinkedIn profile on profile page */}
					<Route path="/linkedin" element={<LinkedInCallback />} />
					{/* View a preview of your match */}
					<Route path="/match-preview" element={<MatchPreview />} />
					{/* Report match didn't happen */}
					<Route
						path="/missed-connection/:match"
						element={<NeverMet />}
					/>
					{/* Join a community */}
					<Route path="/join/:id" element={<Join />} />
					{/* Default route to redirect admins when screen is too narrow*/}
					<Route
						path="/no-mobile-access"
						element={<NoMobileAccess />}
					/>
					{/* Sign-In / Sign-Up / Forgot PW Routes - No user required to access*/}

					{/* Get started as either a manager or member */}
					<Route path="/get-started" element={<GetStarted />} />
					{/* Request Magic Link for member login */}
					<Route path="/member-sign-in" element={<MemberSignIn />} />
					{/**
					 * Routes below are part of the admin dashboard and require a logged-in user to access
					 */}
					{/* Accept an invite to become a group admin */}
					<Route
						path="/accept-invite"
						element={<ProtectedRoute component={AcceptInvite} />}
					/>
					{/* Discord loading page */}
					<Route
						path="/discord-loading"
						element={<ProtectedRoute component={Intros} />}
					/>
					{/* Central help page where the admin can view FAQ, tutorials, and demoes */}
					<Route
						path="/help-center"
						element={<ProtectedRoute component={Intros} />}
					/>
					{/* Account Profile Settings */}
					<Route
						path="/profile/*"
						element={<ProtectedRoute component={Intros} />}
					/>
					{/* Onboarding path - Requires user - automatically navigated to after Sign Up */}
					<Route
						path="/onboarding"
						element={<ProtectedRoute component={Onboarding} />}
					/>
					{/* Hub page for navigation between different events and groups */}
					<Route
						path="/dashboard"
						element={<ProtectedRoute component={Intros} />}
					/>
					{/* Matching Form path */}
					<Route
						path="/onboarding-form"
						element={<ProtectedRoute component={Intros} />}
					/>
					{/* Matching Form path */}
					<Route
						path="/customize-member-profile"
						element={<ProtectedRoute component={Intros} />}
					/>
					{/* Admin Directory path */}
					<Route
						path="/admin-directory"
						element={<ProtectedRoute component={Intros} />}
					/>
					{/* Admin AI chat path */}
					<Route
						path="/admin-ai-chat"
						element={<ProtectedRoute component={Intros} />}
					/>
					{/* Matching Rules path */}
					<Route
						path="/matching-rules"
						element={<ProtectedRoute component={Intros} />}
					/>
					{/* Intro rounds path */}
					<Route
						path="/intro-rounds"
						element={<ProtectedRoute component={Intros} />}
					/>
					{/* Matches dashboard for admins */}
					<Route
						path="/matches"
						element={<ProtectedRoute component={Intros} />}
					/>
					{/* Feedback path */}
					<Route
						path="/feedback"
						element={<ProtectedRoute component={Intros} />}
					/>
					{/* Member dashboard for admins */}
					<Route
						path="/members"
						element={<ProtectedRoute component={Intros} />}
					/>
					{/* Analytics / Past Matches dashboard for admins */}
					<Route
						path="/insights"
						element={<ProtectedRoute component={Intros} />}
					/>
					{/* Channels dashboard for admins */}
					<Route
						path="/channels"
						element={<ProtectedRoute component={Intros} />}
					/>
					{/* Analytics / Past Matches dashboard for admins */}
					<Route
						path="/settings"
						element={<ProtectedRoute component={Intros} />}
					/>
					<Route
						path="/downloads"
						element={<ProtectedRoute component={Intros} />}
					/>
					{/* Internal analytics dashboard */}
					{AppState.internal && (
						<>
							<Route
								path="/testing"
								element={<ProtectedRoute component={Intros} />}
							/>
							<Route
								path="/client-support"
								element={<ProtectedRoute component={Intros} />}
							/>
							<Route
								path="/admin"
								element={<ProtectedRoute component={Intros} />}
							/>
						</>
					)}
					{/* Default route if a user navigates to path that does not exist - No user required to access*/}
					<Route
						path="*"
						element={<Navigate to="/member-sign-in" replace />}
					/>
				</Routes>
			</Router>
		</Suspense>
	);
}

export default App;
