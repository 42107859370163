/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcceptClubsTeamInviteRequest } from '../models/AcceptClubsTeamInviteRequest';
import type { ClubsTeamDeleteRequest } from '../models/ClubsTeamDeleteRequest';
import type { ClubsTeamPostRequest } from '../models/ClubsTeamPostRequest';
import type { ClubsTeamPostResponse } from '../models/ClubsTeamPostResponse';
import type { ClubsTeamPutRequest } from '../models/ClubsTeamPutRequest';
import type { ClubsTeamsGetResponse } from '../models/ClubsTeamsGetResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TeamService {
	/**
	 * Get team member data for the Intros Club
	 * Get team member data for the Intros Club
	 * @returns ClubsTeamsGetResponse Successful Response
	 * @throws ApiError
	 */
	public static getTeamClubsTeamDisplayIdGet({
		displayId
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
	}): CancelablePromise<ClubsTeamsGetResponse> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/clubs/team/{displayId}',
			path: {
				displayId: displayId
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * invite a team member to the Intros Club
	 * Invite a team member to the Intros Club
	 * @returns ClubsTeamPostResponse Successful Response
	 * @throws ApiError
	 */
	public static inviteTeamMemberClubsTeamDisplayIdPost({
		displayId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: ClubsTeamPostRequest;
	}): CancelablePromise<ClubsTeamPostResponse> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/clubs/team/{displayId}',
			path: {
				displayId: displayId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Remove a team member from the Intros Club
	 * Remove a team member from the Intros Club
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static removeTeamAdminClubsTeamDisplayIdDelete({
		displayId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: ClubsTeamDeleteRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/clubs/team/{displayId}',
			path: {
				displayId: displayId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Accept an invite to manage an Intros Club
	 * Update a team member's admin status
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static acceptInviteClubsTeamAcceptPost({
		requestBody
	}: {
		requestBody: AcceptClubsTeamInviteRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/clubs/team/accept',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Accept an invite to manage an Intros Club
	 * Update a team member's admin status
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static updateGroupAdminClubsTeamPut({
		requestBody
	}: {
		requestBody: ClubsTeamPutRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/clubs/team',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}
}
