/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SystemMonitorGetResponse } from '../models/SystemMonitorGetResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReliabilityService {
	/**
	 * System Monitor
	 * Get System Events Over a Timeframe
	 * @returns SystemMonitorGetResponse Successful Response
	 * @throws ApiError
	 */
	public static getSystemMonitorEventsInternalMonitorGet({
		start,
		end
	}: {
		start: string;
		end: string;
	}): CancelablePromise<SystemMonitorGetResponse> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/internal/monitor',
			query: {
				start: start,
				end: end
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * System Monitor Event Resolution
	 * Resolve a System Event
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static resolveSystemEventInternalMonitorIdResolvePost({
		id
	}: {
		id: number;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/internal/monitor/{id}/resolve',
			path: {
				id: id
			},
			errors: {
				422: `Validation Error`
			}
		});
	}
}
