import { action, observable } from 'mobx';

/**
 * Interface for a dynamic question model
 */
export type IAbstractQuestionModel = {
	/**
	 * Title of this question
	 */
	title: string;
	/**
	 * index of this question - used to order the form
	 */
	index: number;
	/**
	 * True if this question is removable
	 */
	removeable: boolean;
	/**
	 * True if survey question is optional
	 */
	optional: boolean;
	/**
	 * True if this question meets the requirements for submission
	 */
	validForSubmission: boolean;
	/**
	 * Update the questionIndex of a question
	 */
	setQuestionIndex: (idx: number) => void;
};

export abstract class AbstractQuestionModel implements IAbstractQuestionModel {
	@observable
	public title: string;
	@observable
	public index: number;
	public removeable: boolean;
	public optional: boolean;

	public abstract get validForSubmission(): boolean;

	@action
	public setQuestionIndex(idx: number) {
		this.index = idx;
	}
}
