import { action, computed, observable } from 'mobx';
import posthog from 'posthog-js';
import { AppState } from '../../../../AppModel';
import { ISearchOption } from '../../../../data-models/response/rest/users/search/UsersSearchGetResponse';
import { RestApiClient } from '../../../api/rest/restApiClientModel';
import { ISurveyAnswerOptionModel } from '../../answer/survey-answer-option';
import {
	ICreateQuestionParams,
	ILikertMetadata,
	QuestionVisibilityEnum,
	SurveyQuestionTypeEnum
} from '../types';
import {
	AbstractQuestionModel,
	IAbstractQuestionModel
} from '../abstract-question';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
	ConditionalLogicModel,
	ConditionalLogicService,
	UpdateMatchingLogicResponse,
	CreateMatchingLogicTag,
	GetConditionalLogicResponse,
	MatchingService,
	QuestionMatchingRuleTypeEnum,
	QuestionType,
	QuestionsService,
	SurveyTypes,
	TagMatchingRule,
	UpdateConditionalLogicRequest
} from '../../../../api-client';

/**
 * Interface for a dynamic question model
 */
export interface IDynamicQuestionModel extends IAbstractQuestionModel {
	/**
	 * Unique id of the question
	 */
	id: number;
	/**
	 * Abbreviation for this question
	 * Only included if this is a template question
	 */
	abbreviation?: string;
	/**
	 * Type of this question
	 */
	type: SurveyQuestionTypeEnum;
	/**
	 * True if this question is editable
	 */
	editable: boolean;
	/**
	 * True if this question is removable
	 */
	removeable: boolean;
	/**
	 * Determines visibility for this question
	 */
	visibility: QuestionVisibilityEnum;
	/**
	 * True if survey question is optional
	 */
	optional: boolean;
	/**
	 * Algorithm parameter type associated with this question
	 */
	algoType: QuestionMatchingRuleTypeEnum;
	/**
	 * Weight of this question in the groups algorithm
	 */
	weight: number;
	/**
	 * True if we are allowing override of the question's matching parameter
	 */
	allowOverride: boolean;
	/**
	 * True if member modified weight via join form
	 */
	modifiedWeight: boolean;
	/**
	 * True if member modified allow override
	 */
	modifiedAllowOverride: boolean;
	/**
	 * True if this question meets the requirements for submission
	 */
	validForSubmission: boolean;
	/**
	 * True if the question has no matching rule
	 */
	hasNoMatchingRule?: boolean;
	/**
	 * Search option for this question
	 */
	searchOption: ISearchOption;
	/**
	 * Icon label type for this question
	 */
	iconType: QuestionType;
	/**
	 * Linked question - only provided if Intentional question
	 */
	linkedQuestion?: string | boolean;
	/**
	 * Minimum required selection of options - only provided if multiselect question
	 */
	minRequired?: number;
	/**
	 * Answer options for this question - only provided if multiselect
	 */
	answers?: ISurveyAnswerOptionModel[];
	/**
	 * Similarity scale for this question - only provided on likert
	 */
	metadata?: ILikertMetadata;
	/**
	 * True when the metadata for a question is complete - only provided on likert
	 */
	isMetadataComplete?: boolean;
	/**
	 * Conditional logics for this question
	 */
	conditionalLogic?: ConditionalLogicModel[];
	/**
	 * Changes the local algo weight of the question
	 */
	modifyLocalAlgoWeight(weight: number): void;
	/**
	 * Change the local allow override of a question
	 */
	changeAllowOverride(override: boolean): void;
	/**
	 * Changes the algo weight of this question
	 */
	submitAlgoWeightChange(surveyType: string, weight: number): void;
	/**
	 * Toggle the visibility of this question
	 */
	toggleVisibility?: () => Observable<void>;
	/**
	 * Load the conditional logic dependent on the answers to this question
	 */
	fetchConditionalLogic?: (displayId?: string) => void;
	/**
	 * Create matching rules for this question
	 */
	createMatchingRules?: (
		rule: QuestionMatchingRuleTypeEnum,
		newMatchingRules: CreateMatchingLogicTag[],
		deleteExistingRules?: boolean
	) => Observable<void>;
	/**
	 * Update the matching rules for this question model
	 */
	saveMatchingRules?: (rules: TagMatchingRule[]) => Observable<void>;
	/**
	 * Create a conditional logic record
	 */
	createConditionalLogic?: (aid: number, target: number) => void;
	/**
	 * Delete a conditional logic record (set active = 0)
	 */
	deleteConditionalLogic?: (id: number) => void;
	/**
	 Update a conditional logic record
	 */
	updateConditionalLogic?: (
		id: number,
		body: UpdateConditionalLogicRequest
	) => void;
}

/**
 * Base class for all survey question types to derive
 */
export abstract class DynamicQuestionModel
	extends AbstractQuestionModel
	implements IDynamicQuestionModel {
	@observable
	public weight: number;
	@observable
	public allowOverride: boolean;
	@observable
	public modifiedWeight: boolean;
	@observable
	public modifiedAllowOverride: boolean;
	public id: number;
	public type: SurveyQuestionTypeEnum;
	public editable: boolean;

	@observable
	public algoType: QuestionMatchingRuleTypeEnum;
	@observable
	public visibility: QuestionVisibilityEnum;
	public abbreviation: string;
	@observable
	public conditionalLogic: ConditionalLogicModel[];

	constructor(params: ICreateQuestionParams) {
		super();
		if (params.questionBankEntry) {
			this.title = params.questionBankEntry.title;
			this.abbreviation = params.questionBankEntry.abbreviation;
			this.editable = !!params.questionBankEntry.editable;
			this.removeable = !!params.questionBankEntry.removable;
			this.optional = !!params.questionBankEntry.optional;
			this.visibility = params.questionBankEntry.visibility;
			this.index = params.questionBankEntry.index;
			this.id = params.questionBankEntry.qid;
			this.algoType = params.questionBankEntry.algoType;
			this.weight = params.questionBankEntry.weight || undefined;
			this.allowOverride =
				params.questionBankEntry.allowOverride || false;
		} else {
			this.title = params.questionModel.title;
			this.editable = params.questionModel.editable;
			this.removeable = params.questionModel.removable;
			this.optional = params.questionModel.optional;
			this.visibility = params.questionModel.visibility;
			this.index = params.questionModel.index;
			this.id = params.questionModel.qid;
			this.algoType = params.questionModel.algoType;
			this.weight = params.questionModel.weight || undefined;
			this.allowOverride = params.questionModel.allowOverride || false;
		}

		if (!params.suppressConditionalLogic) {
			// Fetch conditional logic for this question
			this.fetchConditionalLogic();
		}
	}

	@computed
	public get iconType(): QuestionType {
		switch (this.type) {
			case SurveyQuestionTypeEnum.Freetext:
				return QuestionType.FREETEXT;
			case SurveyQuestionTypeEnum.Likert:
				return QuestionType.LIKERT;
			case SurveyQuestionTypeEnum.Radio:
				return QuestionType.RADIO;
			case SurveyQuestionTypeEnum.Multiselect:
				return QuestionType.MULTISELECT;
			case SurveyQuestionTypeEnum.Intentional:
				return QuestionType.INTENTIONAL;
		}
		return undefined;
	}

	@computed
	public get hasNoMatchingRule(): boolean {
		return !this.algoType;
	}

	public abstract get searchOption(): ISearchOption;

	/**
	 * Change visibility of this question
	 */
	@action
	public toggleVisibility() {
		let nextVisibility: QuestionVisibilityEnum;
		switch (this.visibility) {
			case QuestionVisibilityEnum.VISIBLE:
				nextVisibility = QuestionVisibilityEnum.PROFILE;
				break;
			case QuestionVisibilityEnum.PROFILE:
				nextVisibility = QuestionVisibilityEnum.ONBOARDING;
				break;
			case QuestionVisibilityEnum.ONBOARDING:
				nextVisibility = QuestionVisibilityEnum.HIDDEN;
				break;
			case QuestionVisibilityEnum.HIDDEN:
				nextVisibility = QuestionVisibilityEnum.VISIBLE;
				break;
		}
		return RestApiClient.serviceRequest({
			generator: () =>
				QuestionsService.updateQuestionClubsDisplayIdSurveysSurveyTypeQuestionsQidPut(
					{
						displayId: encodeURIComponent(
							AppState.selectedGroup.displayId
						),
						surveyType: SurveyTypes.PROFILE,
						qid: this.id,
						requestBody: {
							visibility: nextVisibility
						}
					}
				),
			userToken: AppState.user?.cookie
		}).pipe(
			map(() => {
				// update visibility locally
				this.visibility = nextVisibility;
			})
		);
	}

	/**
	 * EDIT the algo weight of a question and submit to server
	 * @param displayId
	 * @param surveyType
	 * @param weight
	 * @returns
	 */
	@action
	public submitAlgoWeightChange(surveyType: string, weight: number) {
		return RestApiClient.serviceRequest({
			generator: () =>
				QuestionsService.updateQuestionClubsDisplayIdSurveysSurveyTypeQuestionsQidPut(
					{
						displayId: encodeURIComponent(
							AppState.selectedGroup.displayId
						),
						surveyType: SurveyTypes.PROFILE,
						qid: this.id,
						requestBody: {
							weight: weight
						}
					}
				),
			userToken: AppState.user?.cookie
		}).subscribe(
			() => {
				// update visibility locally
				this.weight = weight;
				AppState.setNotification({
					type: 'success',
					message: 'Successfully updated algo weight'
				});
				// capture action
				posthog.capture(
					'Update Algo Weight for Member Profile (Join Survey) Question',
					{
						group: AppState.selectedGroup?.name,
						source: 'web',
						qid: this.id,
						weight: weight
					}
				);
			},
			() => {
				AppState.setNotification({
					type: 'error',
					message: 'Failed to update algo weight'
				});
			}
		);
	}

	/**
	 * Locally change allow override of a question
	 */
	@action
	public changeAllowOverride(override: boolean) {
		this.modifiedAllowOverride = true;
		this.allowOverride = override;
	}

	/**
	 * Locally change the algo weight of a question
	 * @param weight
	 */
	@action
	public modifyLocalAlgoWeight(weight: number) {
		this.modifiedWeight = true;
		this.weight = weight;
	}

	/**
	 * Get all active conditional logics and set this.conditionalLogic accordingly
	 */
	@action
	public fetchConditionalLogic(displayId?: string) {
		if (displayId || AppState.selectedGroup?.displayId) {
			RestApiClient.serviceRequest<GetConditionalLogicResponse>({
				generator: () =>
					ConditionalLogicService.getConditionalLogicClubsDisplayIdSurveysSurveyTypeQuestionsQidConditionalLogicGet(
						{
							displayId: encodeURIComponent(
								displayId || AppState.selectedGroup?.displayId
							),
							surveyType: SurveyTypes.PROFILE,
							qid: this.id,
							active: true
						}
					),
				userToken: AppState.user?.cookie
			}).subscribe(
				(data: GetConditionalLogicResponse) => {
					this.conditionalLogic = data.rules;
				},
				() => {
					this.conditionalLogic = [];
				}
			);
		} else {
			this.conditionalLogic = [];
		}
	}

	/**
	 * Create a conditional logic record for this question, then refetch all conditional logic
	 */
	@action
	public createConditionalLogic(aid: number, target: number) {
		RestApiClient.serviceRequest<ConditionalLogicModel>({
			generator: () =>
				ConditionalLogicService.createConditionalLogicClubsDisplayIdSurveysSurveyTypeQuestionsQidConditionalLogicPost(
					{
						displayId: encodeURIComponent(
							AppState.selectedGroup.displayId
						),
						surveyType: SurveyTypes.PROFILE,
						qid: this.id,
						requestBody: {
							aid: aid,
							target: target
						}
					}
				),
			userToken: AppState.user?.cookie
		}).subscribe((data: ConditionalLogicModel) => {
			if (!this.conditionalLogic) {
				this.conditionalLogic = [];
			}
			let copy = this.conditionalLogic;
			copy.push(data);
			this.conditionalLogic = copy;
			// capture action
			posthog.capture(
				'Added Conditional Logic to Member Profile (Join Survey) Question',
				{
					group: AppState.selectedGroup?.name,
					source: 'web',
					qid: this.id,
					aid: aid,
					target_question: target
				}
			);
		});
	}

	/**
	 * Delete a conditional logic record (set active = 0), then refetch all conditional logic
	 */
	@action
	public deleteConditionalLogic(id: number) {
		RestApiClient.serviceRequest<ConditionalLogicModel>({
			generator: () =>
				ConditionalLogicService.updateConditionalLogicClubsDisplayIdSurveysSurveyTypeQuestionsQidConditionalLogicCidPut(
					{
						displayId: encodeURIComponent(
							AppState.selectedGroup.displayId
						),
						surveyType: SurveyTypes.PROFILE,
						qid: this.id,
						cid: id,
						requestBody: {
							active: 0
						}
					}
				),
			userToken: AppState.user?.cookie
		}).subscribe((data: ConditionalLogicModel) => {
			this.conditionalLogic = this.conditionalLogic.filter(
				(rule) => rule.uid !== id
			);
			// capture action
			posthog.capture(
				'Removed Conditional Logic from Member Profile (Join Survey) Question',
				{
					group: AppState.selectedGroup?.name,
					source: 'web',
					qid: this.id,
					logic_rule_id: id
				}
			);
		});
	}

	/**
	 * Update a specific conditional logic record, then refetch all conditional logic
	 */
	@action
	public updateConditionalLogic(
		id: number,
		body: UpdateConditionalLogicRequest
	) {
		RestApiClient.serviceRequest<ConditionalLogicModel>({
			generator: () =>
				ConditionalLogicService.updateConditionalLogicClubsDisplayIdSurveysSurveyTypeQuestionsQidConditionalLogicCidPut(
					{
						displayId: encodeURIComponent(
							AppState.selectedGroup.displayId
						),
						surveyType: SurveyTypes.PROFILE,
						qid: this.id,
						cid: id,
						requestBody: body
					}
				),
			userToken: AppState.user?.cookie
		}).subscribe((data: ConditionalLogicModel) => {
			this.conditionalLogic = this.conditionalLogic.filter(
				(rule) => rule.uid !== id
			);
			this.conditionalLogic.push(data);
			this.conditionalLogic = this.conditionalLogic.filter(
				(rule) => rule.active
			);
			// capture action
			posthog.capture(
				'Updated Conditional Logic from Member Profile (Join Survey) Question',
				{
					group: AppState.selectedGroup?.name,
					source: 'web',
					qid: this.id,
					logic_rule_id: id,
					body
				}
			);
		});
	}

	public createMatchingRules(
		rule: QuestionMatchingRuleTypeEnum,
		newMatchingRules: CreateMatchingLogicTag[],
		deleteExistingRules?: boolean
	) {
		return RestApiClient.serviceRequest<UpdateMatchingLogicResponse>({
			generator: () =>
				deleteExistingRules
					? QuestionsService.updateMatchingLogicClubsDisplayIdSurveysSurveyTypeQuestionsQidMatchingPut(
							{
								displayId: encodeURIComponent(
									AppState.selectedGroup.displayId
								),
								qid: this.id,
								surveyType: SurveyTypes.PROFILE,
								requestBody: {
									newRules: newMatchingRules,
									algoWeight: this.weight || 3,
									allowOverride: this.allowOverride,
									algoType: rule
								}
							}
					  )
					: MatchingService.applyMatchingLogicClubsDisplayIdSurveysSurveyTypeQuestionsQidMatchingPost(
							{
								displayId: encodeURIComponent(
									AppState.selectedGroup.displayId
								),
								surveyType: SurveyTypes.PROFILE,
								qid: this.id,
								requestBody: {
									algoWeight: this.weight ? this.weight : 3,
									algoType: rule,
									allowOverride: this.allowOverride
								}
							}
					  ),
			userToken: AppState.user?.cookie
		}).pipe(
			map(() => {
				this.algoType = rule;
				this.weight = this.weight ? this.weight : 3;
				this.modifiedWeight = false;
				this.modifiedAllowOverride = false;
				// capture action
				posthog.capture(
					'Create Matching Rule for Member Profile (Join Survey) Question',
					{
						group: AppState.selectedGroup?.name,
						source: 'web',
						qid: this.id,
						rule: rule.valueOf()
					}
				);
			})
		);
	}

	public saveMatchingRules(rules: TagMatchingRule[]) {
		return RestApiClient.serviceRequest<UpdateMatchingLogicResponse>({
			generator: () =>
				MatchingService.updateMatchingLogicClubsDisplayIdSurveysSurveyTypeQuestionsQidMatchingPut(
					{
						displayId: encodeURIComponent(
							AppState.selectedGroup.displayId
						),
						surveyType: SurveyTypes.PROFILE,
						qid: this.id,
						requestBody: {
							algoWeight: this.weight,
							allowOverride: this.allowOverride
						}
					}
				),
			userToken: AppState.user?.cookie
		}).pipe(
			map(() => {
				this.modifiedWeight = false;
				this.modifiedAllowOverride = false;
				/**
				 * Notify the user that the matching rules have been updated
				 * TODO: should probably be handled by UI
				 */
				AppState.setNotification({
					type: 'success',
					message: 'Successfully updated matching rule'
				});
				// capture action
				posthog.capture(
					'Saved Matching Rules for Member Profile (Join Survey) Question',
					{
						group: AppState.selectedGroup?.name,
						source: 'web',
						qid: this.id,
						rules
					}
				);
			})
		);
	}
}
