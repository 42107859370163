/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnsweredQuestionModel } from '../models/AnsweredQuestionModel';
import type { FeedbackDetails } from '../models/FeedbackDetails';
import type { FeedbackSearchParameters } from '../models/FeedbackSearchParameters';
import type { GetFeedbackResponse } from '../models/GetFeedbackResponse';
import type { SubmitMatchFeedbackRequest } from '../models/SubmitMatchFeedbackRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FeedbackService {
	/**
	 * Get Feedback for the Group
	 * Load member data from this Group
	 * @returns GetFeedbackResponse Successful Response
	 * @throws ApiError
	 */
	public static getReviewsClubsDisplayIdFeedbackSearchPost({
		displayId,
		requestBody,
		page,
		size = 25
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: FeedbackSearchParameters;
		page?: number;
		size?: number;
	}): CancelablePromise<GetFeedbackResponse> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/clubs/{displayId}/feedback/search',
			path: {
				displayId: displayId
			},
			query: {
				page: page,
				size: size
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Fetch additional details on a singular match feedback
	 * GET /clubs/{displayId}/engagement/{id}
	 * @returns FeedbackDetails Successful Response
	 * @throws ApiError
	 */
	public static getEngagementDetailsClubsDisplayIdFeedbackIdGet({
		displayId,
		id,
		cookie
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		id: number;
		cookie: string;
	}): CancelablePromise<Array<FeedbackDetails>> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/clubs/{displayId}/feedback/{id}',
			path: {
				displayId: displayId,
				id: id
			},
			query: {
				cookie: cookie
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Get feedback for a match
	 * GET /matches/<hash>/feedback
	 * @returns AnsweredQuestionModel Successful Response
	 * @throws ApiError
	 */
	public static getFeedbackForMatchMatchesHashFeedbackGet({
		hash,
		uid
	}: {
		hash: string;
		uid?: number;
	}): CancelablePromise<Array<AnsweredQuestionModel>> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/matches/{hash}/feedback',
			path: {
				hash: hash
			},
			query: {
				uid: uid
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Submit feedback for a match
	 * POST /matches/<hash>/feedback
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static submitFeedbackForMatchMatchesHashFeedbackPost({
		hash,
		requestBody
	}: {
		hash: string;
		requestBody: SubmitMatchFeedbackRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/matches/{hash}/feedback',
			path: {
				hash: hash
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}
}
