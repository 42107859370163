import {
	ICreateQuestionParams,
	SurveyQuestionTypeEnum
} from '../../../../types';
import {
	IMultiSelectSurveyQuestionModel,
	MultiSelectSurveyQuestionModel
} from '../multiselect-survey-question';

export interface IIntentionalSurveyQuestionModel
	extends IMultiSelectSurveyQuestionModel {
	/**
	 * Minimum number of required options for this question
	 */
	linkedQuestion: string | boolean;
}

export class IntentionalSurveyQuestionModel
	extends MultiSelectSurveyQuestionModel
	implements IIntentionalSurveyQuestionModel {
	public linkedQuestion: string | boolean;

	// type of this question is always multiselect
	public type: SurveyQuestionTypeEnum = SurveyQuestionTypeEnum.Intentional;

	constructor(params: ICreateQuestionParams) {
		super(params);
		if (params.questionBankEntry) {
			this.linkedQuestion = params.questionBankEntry.linkedQuestion as
				| string
				| boolean;
		} else {
			this.linkedQuestion = params.questionModel.linkedQuestion as
				| string
				| boolean;
		}
	}
}
