/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateMatchPreviewSingleRequest } from '../models/CreateMatchPreviewSingleRequest';
import type { CreateMatchPreviewsRequest } from '../models/CreateMatchPreviewsRequest';
import type { MatchPreviewIntroResponse } from '../models/MatchPreviewIntroResponse';
import type { MatchPreviewModel } from '../models/MatchPreviewModel';
import type { MatchPreviewsResponse } from '../models/MatchPreviewsResponse';
import type { PublishMatchPreviewsRequest } from '../models/PublishMatchPreviewsRequest';
import type { UpdateMatchPreviewRequest } from '../models/UpdateMatchPreviewRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MatchPreviewsService {
	/**
	 * Create previews for an entire club
	 * POST /matches/previews
	 * @returns MatchPreviewModel Successful Response
	 * @throws ApiError
	 */
	public static createSingleMatchPreviewMatchesPreviewsSinglePost({
		requestBody
	}: {
		requestBody: CreateMatchPreviewSingleRequest;
	}): CancelablePromise<MatchPreviewModel> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/matches/previews/single',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Create previews for an entire club
	 * POST /matches/previews
	 * @returns MatchPreviewsResponse Successful Response
	 * @throws ApiError
	 */
	public static createMatchPreviewsMatchesPreviewsPost({
		requestBody
	}: {
		requestBody: CreateMatchPreviewsRequest;
	}): CancelablePromise<MatchPreviewsResponse> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/matches/previews',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Get an intro for the match preview specified
	 * GET /matches/previews/{id}/intro
	 * @returns MatchPreviewIntroResponse Successful Response
	 * @throws ApiError
	 */
	public static getIntroForMatchPreviewMatchesPreviewsIdIntroGet({
		id
	}: {
		id: number;
	}): CancelablePromise<MatchPreviewIntroResponse> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/matches/previews/{id}/intro',
			path: {
				id: id
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Update a match preview
	 * PUT /matches/previews/{id}
	 * @returns MatchPreviewModel Successful Response
	 * @throws ApiError
	 */
	public static updateMatchPreviewMatchesPreviewsIdPut({
		id,
		requestBody
	}: {
		id: number;
		requestBody: UpdateMatchPreviewRequest;
	}): CancelablePromise<MatchPreviewModel> {
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/matches/previews/{id}',
			path: {
				id: id
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Publish a set of locked in match previews
	 * POST /matches/previews/publish
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static publishMatchPreviewsMatchesPreviewsPublishPost({
		requestBody
	}: {
		requestBody: PublishMatchPreviewsRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/matches/previews/publish',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Load a preview between any two users
	 * GET /matches/previews/legacy
	 * TODO: this endpoint should be refactored entirely
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static getMatchPreviewMatchesPreviewsLegacyGet({
		hash,
		mid,
		displayId,
		gid,
		minimize = false,
		matchedUserId
	}: {
		hash?: string;
		mid?: number;
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId?: string;
		gid?: number;
		minimize?: boolean;
		matchedUserId?: number;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/matches/previews/legacy',
			query: {
				hash: hash,
				mid: mid,
				displayId: displayId,
				gid: gid,
				minimize: minimize,
				matchedUserId: matchedUserId
			},
			errors: {
				422: `Validation Error`
			}
		});
	}
}
