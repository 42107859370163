/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MatchRequestDecision } from '../models/MatchRequestDecision';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MatchRequestsService {
	/**
	 * Get all match requests for a user
	 * GET /users/<uid>/match-requests
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static getMatchRequestsUsersMatchRequestsGet({
		uid
	}: {
		uid: number;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/users/match-requests',
			query: {
				uid: uid
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Get match preview for a single request
	 * GET /users/<uid>/match-requests/<cid>
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static getMatchRequestPreviewUsersMatchRequestsCidGet({
		cid,
		uid
	}: {
		cid: number;
		uid: number;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/users/match-requests/{cid}',
			path: {
				cid: cid
			},
			query: {
				uid: uid
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Approve or reject a match request
	 * PUT /users/<uid>/match-requests/<cid>
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static approveOrRejectMatchRequestUsersMatchRequestsCidPut({
		cid,
		uid,
		requestBody
	}: {
		cid: number;
		uid: number;
		requestBody: MatchRequestDecision;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/users/match-requests/{cid}',
			path: {
				cid: cid
			},
			query: {
				uid: uid
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}
}
