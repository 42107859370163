/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SlackKickoffPostRequest } from '../models/SlackKickoffPostRequest';
import type { SlackKickoffPostResponse } from '../models/SlackKickoffPostResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SlackService {
	/**
	 * Kickoff Slack Matching
	 * POST /internal/integrations/webhooks/slack/kickoff
	 * @returns SlackKickoffPostResponse Successful Response
	 * @throws ApiError
	 */
	public static kickoffInternalIntegrationsWebhooksSlackKickoffPost({
		requestBody
	}: {
		requestBody: SlackKickoffPostRequest;
	}): CancelablePromise<SlackKickoffPostResponse> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/internal/integrations/webhooks/slack/kickoff',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}
}
