import {
	AbstractQuestionModel,
	IAbstractQuestionModel
} from '../abstract-question';

/**
 * Enumizes the variants of static questions we provide
 */
export enum StaticQuestionEnum {
	EMAIL = 'email',
	BASICS = 'basics',
	TIMES = 'times'
}

/**
 * Interfaces parameters to create a static question model
 */
export interface IStaticQuestionModelParams {
	/**
	 * Type of static question we are representing with this instance
	 */
	type: StaticQuestionEnum;
	/**
	 * Index of this question in its survey
	 */
	index: number;
}

/**
 * Interfaces our static question model
 */
export interface IStaticQuestionModel extends IAbstractQuestionModel {
	/**
	 * Type of static question we are representing with this instance
	 */
	type: StaticQuestionEnum;
}

/**
 * Modularizes a static question that cannot be edited by the admin
 */
export class StaticQuestionModel
	extends AbstractQuestionModel
	implements IStaticQuestionModel {
	public type: StaticQuestionEnum;

	constructor(params: IStaticQuestionModelParams) {
		super();
		this.type = params.type;
		this.index = params.index;
	}

	public get validForSubmission(): boolean {
		return true;
	}
}
