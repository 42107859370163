/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enumizes all possible QuestionVisibility.visibility values
 */
export enum QuestionVisibilityEnum {
	VISIBLE = 'visible',
	PROFILE = 'profile',
	ONBOARDING = 'onboarding',
	HIDDEN = 'hidden'
}
