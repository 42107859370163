import { RestApiClient } from '../api/rest/restApiClientModel';
import { Observable, ReplaySubject, Subject, of } from 'rxjs';
import { GroupModel, IGroupModel } from '../group/group';
import {
	ObservableMap,
	action,
	computed,
	makeObservable,
	observable
} from 'mobx';
import { map } from 'rxjs/operators';
import { AppState } from '../../AppModel';
import { IDeveloperApiCredentialsDataModel } from '../../data-models/developer/credentials/DeveloperApiCredentials';
import {
	AuthService,
	Club,
	ClubLite,
	ClubsService,
	CreateUserModel,
	DefaultService,
	GetClubsResponse,
	GetUserMatchesResponse,
	MatchSummaryInfo,
	MatchesService,
	ReportSummary,
	ReportsService,
	ResetVerificationPostResponse,
	SendVerificationPostResponse,
	UpdateUserRequest,
	UserAvailabilityMap,
	UserLoginResponse,
	UserProfileModel,
	UsersService,
	VerifyEmailPostResponse,
	ClubMembership,
	routes__matches__types__MatchStatusEnum,
	GetClubAdminsResponse,
	GetClubMembershipsResponse,
	ClubAdmin
} from '../../api-client';
import { GetUserReports } from '../../api-client/models/GetUserReports';
import { IUpdateMemberProfileParams } from '../../components/member-profile/types';
import { IMatch, Match } from '../match/match';
import { RelationshipStatusEnum } from '../../components/member-profile/member-profile-view-model';

export type IUserProfile = Readonly<UserProfile>;

export interface IUserProfileParams {
	/**
	 * Unique id for this user
	 */
	// TODO: eventually makethis optional, we should use login cookies here
	uid?: number;
	/**
	 * Email for the logged in user
	 */
	email?: string;
	/**
	 * Cookie corresponding to this user's login
	 */
	cookie?: string;
	/**
	 * First name of the user
	 */
	firstName?: string;
	/**
	 * Last name of the user
	 */
	lastName?: string;
	/**
	 * Pronouns of the user
	 */
	pronouns?: string;
}

export interface ICreateUserParams {
	/**
	 * First name of the user
	 */
	firstName: string;
	/**
	 * Last name of the user
	 */
	lastName: string;
	/**
	 * Email of the user
	 */
	email: string;
	/**
	 * Password of the user
	 */
	password: string;
	/**
	 * True if this user is signing up on mobile
	 */
	mobile: boolean;
}

/**
 * Onboarding status for the user - track whether the user has ever viewed a tutorial
 */
export interface IUserOnboardingStatus {
	/**
	 * True if the user has been through the homepage tutorial before
	 */
	homepage: boolean;
	/**
	 * True if the user has been through the help center before
	 */
	help: boolean;
	/**
	 * True if the user has been through the flow page tutorial before
	 */
	flowPage: boolean;
	/**
	 * True if the user has been through the matching page tutorial before
	 */
	matching: boolean;
	/**
	 * True if the user has been through the welcome page tutorial before
	 */
	welcome: boolean;
	/**
	 * True if the user has been through the intro page tutorial before
	 */
	intro: boolean;
	/**
	 * True if the user has been through the review page tutorial before
	 */
	review: boolean;
	/**
	 * True if the user has been through the members page tutorial before
	 */
	members: boolean;
	/**
	 * True if the user has been through the insights page tutorial before
	 */
	insights: boolean;
	/**
	 * True if the user has been through the onboarding tutorial before
	 */
	onboarding: boolean;
}

/**
 * Enumizes the Tutorials available through Intros
 */
export enum TutorialEnum {
	Homepage = 'home',
	Help = 'help',
	Flow = 'flow-page',
	Matching = 'matching',
	Welcome = 'welcome',
	Intro = 'intro',
	Review = 'review',
	Members = 'members',
	Insights = 'insights',
	Onboarding = 'onboarding'
}

/**
 * Represents a user profile
 */
export class UserProfile implements IUserProfile {
	/**
	 * First name of this user
	 */
	@observable
	public firstName: string;
	/**
	 * Last name of this user
	 */
	@observable
	public lastName: string;
	/**
	 * Pronouns of this user
	 */
	@observable
	public pronouns: string;
	/**
	 * Discord tag for this user
	 */
	@observable
	public discord: string;
	/**
	 * Phone number for this user
	 */
	@observable
	public phone: string;

	/**
	 * Users company
	 */
	@observable
	public company: string;

	/**
	 * Users title
	 */
	@observable
	public title: string;

	/**
	 * Location of the member
	 */
	@observable
	public location: string;
	/**
	 * LinkedIn of the member
	 */
	@observable
	public linkedIn: string;
	/**
	 * Twitter of the member
	 */
	@observable
	public twitter: string;
	/**
	 * Education institution of the member
	 */
	@observable
	public education: string;
	/**
	 * Degrees of the member
	 */
	@observable
	public degrees: string;
	/**
	 * Personal site of the member
	 */
	@observable
	public personalSite: string;
	/**
	 * Timezone chosen by this user
	 */
	@observable
	public timezone: string;
	/**
	 * Profile photo for this user
	 */
	@observable
	public profilePhoto: string;
	/**
	 * User's daily availability
	 */
	@observable
	public availability: UserAvailabilityMap;
	/**
	 * True if this user has authenticated their google account with Intros
	 */
	@observable
	public googleAuthed: boolean = false;
	/**
	 * True if this user has authenticated their outlook account with Intros
	 */
	@observable
	public outlookAuthed: boolean = false;
	/**
	 * Identification data for the groups of which this user is a member
	 */
	public memberGroups: ClubMembership[];
	/**
	 * Identification data for groups of which this user is an admin
	 */
	@observable
	public adminGroups: ClubAdmin[];
	/**
	 * Client apps created by this user
	 */
	@observable
	public clientApps: IDeveloperApiCredentialsDataModel[];
	/**
	 * Track which tutorials the admin has run before
	 */
	@observable
	public tutorialExperience: IUserOnboardingStatus;
	/**
	 * Requested reports available to download
	 */
	@observable.ref
	public downloads: ReportSummary[] = [];
	/**
	 * Subject to notify subscribers when user admin groups have been initialized
	 */
	public adminGroupsInitialized: Subject<any>;
	/**
	 * Subject to notify subscribers when user members groups have been initialized
	 */
	public memberGroupsInitialized: Subject<any>;
	/**
	 * Subject to notify subscribers when reports for download have been loaded
	 */
	public reportsLoaded: Subject<any>;
	/**
	 * Subject to notify subscribers when matches have been loaded
	 */
	public matchesLoaded: ReplaySubject<any> = new ReplaySubject();
	/**
	 * True if matches have been loaded
	 */
	@observable
	public matchesLoadedBool: boolean = false;

	/**
	 * Subject to notify subscribers when developer params have been initialized
	 */
	public developerParamsInitialized: Subject<any>;
	/**
	 * Unique identifier given to this user
	 *
	 */
	@observable
	public uid: number;
	/**
	 * Email for this user
	 */
	@observable
	public email: string;

	/**
	 * Unverified email for this user
	 */
	@observable
	public unverifiedEmail: string;

	/**
	 * Stores login cookie
	 */
	@observable
	public cookie: string;

	/**
	 * Map of match id to match model
	 */
	@observable
	public matches: ObservableMap<number, IMatch> = new ObservableMap<
		number,
		IMatch
	>();

	/**
	 * Signals when the user's profile has been loaded
	 */
	public profileLoaded: Subject<void> = new Subject();

	/**
	 * Signals when the user's profile has failed to load
	 */
	public profileLoadFailed: Subject<void> = new Subject();

	/**
	 * Used to check when a report for export has finished
	 */
	private confirmExportsInterval: NodeJS.Timeout;

	constructor(params: IUserProfileParams) {
		makeObservable(this);
		if (params.uid) {
			this.uid = params.uid;
			this.cookie = params.cookie;
			this.email = params.email;
			this.firstName = params.firstName;
			this.lastName = params.lastName;
			this.pronouns = params.pronouns;
			this.adminGroupsInitialized = new Subject();
			this.memberGroupsInitialized = new Subject();
			this.reportsLoaded = new Subject();
			this.developerParamsInitialized = new Subject();
			this.loadAdminGroups().subscribe();
			// load profile if missing first name
			if (!this.firstName) {
				this.loadProfile();
			}
		} else if (params.cookie) {
			this.cookie = params.cookie;
			this.loadProfile();
		} else {
			console.error(
				'[UserProfile] no uid provided. Cannot create user profile...'
			);
			throw Error(
				'[UserProfile] no uid provided. Cannot create user profile...'
			);
		}
	}

	/**
	 * Display name of this user
	 */
	@computed
	public get name(): string {
		return `${this.firstName}${this.firstName && this.lastName && ' '}${
			this.lastName
		}`;
	}

	/**
	 * Approved Requests
	 */
	@computed
	public get approvedRequests(): IMatch[] {
		return Array.from(this.matches.values()).filter(
			(val: IMatch) =>
				val.status === routes__matches__types__MatchStatusEnum.SCHEDULED
		);
	}

	/**
	 * Pending Requests
	 */
	@computed
	public get pendingRequests(): IMatch[] {
		return Array.from(this.matches.values()).filter(
			(val: IMatch) =>
				val.status ===
				routes__matches__types__MatchStatusEnum.TIMES_PROPOSED_BY_MATCH
		);
	}

	/**
	 * Sent Requests
	 */
	@computed
	public get sentRequests(): IMatch[] {
		return Array.from(this.matches.values()).filter(
			(val: IMatch) =>
				val.status ===
				routes__matches__types__MatchStatusEnum.TIMES_PROPOSED_BY_USER
		);
	}

	/**
	 * Past matches for this user
	 */
	@computed
	public get pastMatches(): IMatch[] {
		return Array.from(this.matches.values()).reverse();
	}

	/**
	 * Update profile for this user
	 * @param params
	 */
	@action
	public updateProfile(params: IUpdateMemberProfileParams): Observable<void> {
		/**
		 * construct body for updating user
		 */
		const updateUserRequest: UpdateUserRequest = {};
		// construct request
		if (
			params.firstName !== undefined &&
			this.firstName !== params.firstName
		) {
			this.setFirstName(params.firstName);
			updateUserRequest.firstName = this.firstName;
		}
		if (
			params.lastName !== undefined &&
			this.lastName !== params.lastName
		) {
			this.setLastName(params.lastName);
			updateUserRequest.lastName = this.lastName;
		}
		if (
			params.pronouns !== undefined &&
			this.pronouns !== params.pronouns
		) {
			this.setPronouns(params.pronouns);
			updateUserRequest.pronouns = this.pronouns;
		}
		if (params.discord !== undefined && params.discord !== this.discord) {
			this.setDiscord(params.discord);
			updateUserRequest.discord = this.discord;
		}
		if (params.phone !== undefined && params.phone !== this.phone) {
			this.setPhone(params.phone);
			updateUserRequest.phone = this.phone;
		}
		if (params.company !== undefined && params.company !== this.company) {
			this.setCompany(params.company);
			updateUserRequest.company = this.company;
		}
		if (params.title !== undefined && params.title !== this.title) {
			this.setTitle(params.title);
			updateUserRequest.title = this.title;
		}
		if (
			params.location !== undefined &&
			params.location !== this.location
		) {
			this.setLocation(params.location);
			updateUserRequest.location = this.location;
		}
		if (
			params.education !== undefined &&
			params.education !== this.education
		) {
			this.setEducation(params.education);
			updateUserRequest.school = this.education;
		}
		if (params.degrees !== undefined && params.degrees !== this.degrees) {
			this.setDegrees(params.degrees);
			updateUserRequest.degrees = this.degrees;
		}
		if (
			params.linkedIn !== undefined &&
			params.linkedIn !== null &&
			updateUserRequest.linkedIn !== this.linkedIn
		) {
			this.setLinkedIn(params.linkedIn);
			updateUserRequest.linkedIn = this.linkedIn;
		}
		if (params.twitter !== undefined && params.twitter !== this.twitter) {
			this.setTwitter(params.twitter);
			updateUserRequest.twitter = this.twitter;
		}
		if (
			params.personalSite !== undefined &&
			params.personalSite !== null &&
			updateUserRequest.personalSite !== this.personalSite
		) {
			this.setPersonalSite(params.personalSite);
			updateUserRequest.personalSite = this.personalSite;
		}
		if (params.timezone) {
			this.setTimezone(params.timezone);
			updateUserRequest.timezone = this.timezone;
		}
		if (params.profilePhoto !== undefined) {
			this.setProfilePhoto(params.profilePhoto);
			updateUserRequest.profilePhoto = this.profilePhoto;
		}
		if (Object.keys(updateUserRequest).length > 0) {
			// send request
			return RestApiClient.serviceRequest({
				generator: () =>
					UsersService.updateUserInfoUsersUidPut({
						uid: this.uid,
						requestBody: updateUserRequest
					})
			});
		} else {
			return of(undefined);
		}
	}

	public updateAvailability(
		availability: UserAvailabilityMap
	): Observable<void> {
		return RestApiClient.serviceRequest({
			generator: () =>
				UsersService.updateUserInfoUsersUidPut({
					uid: this.uid,
					requestBody: {
						availability: Object.values(availability)
					}
				})
		}).pipe(
			map(() => {
				this.availability = availability;
			})
		);
	}

	public authenticateOutlook(authCode: string): Observable<void> {
		return RestApiClient.serviceRequest({
			generator: () =>
				AuthService.saveOutlookCredentialsUsersAuthOutlookPost({
					requestBody: {
						authCode: authCode
					}
				}),
			userToken: this.cookie
		}).pipe(
			map(() => {
				this.setOutlookAuthed(true);
			})
		);
	}

	public authenticateGoogle(authCode: string): Observable<void> {
		return RestApiClient.serviceRequest({
			generator: () =>
				AuthService.saveGoogleCredentialsUsersAuthGooglePost({
					requestBody: {
						authCode: authCode
					}
				}),
			userToken: this.cookie
		}).pipe(
			map(() => {
				this.setGoogleAuthed(true);
			})
		);
	}

	/**
	 * Load admin groups for this user
	 */
	@action
	public loadAdminGroups(): Observable<GetClubAdminsResponse> {
		return RestApiClient.serviceRequest({
			generator: () =>
				ClubsService.getClubAdminsUsersSelfClubAdminsGet({
					uid: this.uid
				}),
			userToken: this.cookie
		}).pipe(
			map(
				(response: unknown): GetClubAdminsResponse =>
					response as GetClubAdminsResponse
			),
			map((data: GetClubAdminsResponse) => {
				this.adminGroups = data.data;
				this.adminGroupsInitialized.next();
				return data;
			})
		);
	}

	/**
	 * Load member groups for this user
	 */
	@action
	public loadMemberGroups(): Observable<GetClubMembershipsResponse> {
		return RestApiClient.serviceRequest({
			generator: () =>
				ClubsService.getClubMembershipsUsersSelfClubMembershipsGet({
					uid: this.uid
				}),
			userToken: this.cookie
		}).pipe(
			map(
				(response: unknown): GetClubMembershipsResponse =>
					response as GetClubMembershipsResponse
			),
			map((data: GetClubMembershipsResponse) => {
				this.memberGroups = data.data;
				this.memberGroupsInitialized.next();
				return data;
			})
		);
	}

	/**
	 * Lock in the user's new email by verifying it with a emailVerificationHash
	 */
	@action
	public verifyNewEmail(hash: string) {
		// get preview for this userId
		return RestApiClient.serviceRequest<VerifyEmailPostResponse>({
			generator: () =>
				UsersService.verifyEmailUsersEmailVerificationVerifyPost({
					requestBody: {
						hash: hash
					}
				}),
			userToken: this.cookie
		}).pipe(
			map((data: VerifyEmailPostResponse) => {
				this.email = this.unverifiedEmail;
				this.unverifiedEmail = undefined;
				return data;
			})
		);
	}

	/**
	 * Sends an email verification email for a user to change their email. Providing an email will set the user's unverifiedEmail. If an email is not provided, then we will resend a verification email to the unverifiedEmail which should have been set previously.
	 */
	public sendEmailVerification(email?: string) {
		return RestApiClient.serviceRequest<SendVerificationPostResponse>({
			generator: () =>
				UsersService.sendVerificationEmailUsersEmailVerificationSendPost(
					{
						requestBody: {
							email: email
						}
					}
				),
			userToken: this.cookie
		}).pipe(
			map((data: SendVerificationPostResponse) => {
				if (email) {
					this.unverifiedEmail = email;
				}
				return data;
			})
		);
	}

	/**
	 * Sends a request to clear out the user's unverified email and unverifiedEmailHash
	 */
	public resetEmailVerification() {
		// get preview for this userId
		return RestApiClient.serviceRequest<ResetVerificationPostResponse>({
			generator: () =>
				UsersService.resetVerificationEmailUsersEmailVerificationResetPost(
					{
						requestBody: {}
					}
				),
			userToken: this.cookie
		}).pipe(
			map((data: ResetVerificationPostResponse) => {
				this.unverifiedEmail = undefined;
				return data;
			})
		);
	}

	/**
	 * Load reports for this user
	 */
	public loadReportsForDownload() {
		return RestApiClient.serviceRequest<GetUserReports>({
			generator: () =>
				DefaultService.getReportsCacheUsersUidReportsGet({
					uid: this.uid
				}),
			userToken: this.cookie
		}).pipe(
			map((data: GetUserReports) => {
				this.setDownloads(data.reports);
				this.reportsLoaded.next();
				// set interval if necessary
				if (
					this.downloads.length > 0 &&
					!this.downloads[this.downloads.length - 1].ready &&
					!this.confirmExportsInterval
				) {
					this.confirmExportsInterval = setInterval(() => {
						this.loadReportsForDownload().subscribe();
					}, 10000);
				} else if (this.confirmExportsInterval) {
					clearInterval(this.confirmExportsInterval);
				}
				return data;
			})
		);
	}

	/**
	 * Load data required for the review match tab
	 */
	public loadMatches(): Observable<void> {
		// get the user profile info
		return RestApiClient.serviceRequest<GetUserMatchesResponse>({
			generator: () => MatchesService.getMatchesForUserMatchesGet()
		}).pipe(
			map((data: GetUserMatchesResponse) => {
				this.setMatches(data.matches);
			})
		);
	}

	public archiveMatch(mid: number) {
		const match: IMatch = this.matches.get(mid);
		if (match) {
			match.archive().subscribe(() => {
				this.matches.delete(mid);
			});
		}
	}

	/**
	 * Used to report to the server that a user tutorial has been completed
	 * @param tutorial
	 */
	public completedTutorial(tutorial: TutorialEnum) {
		// DEPRECATED
	}

	/**
	 * Create this users account
	 */
	static createUser(params: CreateUserModel): Observable<UserLoginResponse> {
		return RestApiClient.serviceRequest<UserLoginResponse>({
			generator: () =>
				UsersService.createAdminUsersPost({
					requestBody: params
				})
		}).pipe(
			map((data: UserLoginResponse) => {
				AppState.setUser(
					data.uid,
					data.cookie,
					data.name?.split(' ')[0],
					data.name?.split(/[ ]+/)[1],
					params.email,
					true
				);
				return data;
			})
		);
	}

	@action
	public addClientApp(app: IDeveloperApiCredentialsDataModel) {
		this.clientApps.push(app);
	}

	@action
	public deleteClientApp(clientId: string) {
		var index = this.clientApps
			.map(function (e) {
				return e.id;
			})
			.indexOf(clientId);
		if (index > -1) {
			this.clientApps.splice(index, 1);
		}
	}

	/**
	 * Delete the report specified by the specified doc_pk
	 * @param doc_pk
	 */
	@action
	public deleteDownloadableReport(doc_pk: number) {
		return RestApiClient.serviceRequest({
			generator: () =>
				ReportsService.deleteReportClubsDisplayIdReportsReportIdDelete({
					displayId: encodeURIComponent(
						AppState.selectedGroup?.displayId
					),
					docPk: doc_pk
				})
		}).pipe(
			map(() => {
				// remove edited report
				this.setDownloads(
					this.downloads.filter(
						(report: ReportSummary) => report.doc_pk !== doc_pk
					)
				);
			})
		);
	}

	/**
	 * Set downloads for this user
	 * @param downloads
	 */
	@action
	private setDownloads(downloads: ReportSummary[]) {
		this.downloads = downloads;
	}

	/**
	 * Set the given tutorial has been completed
	 * @param tutorial
	 */
	@action
	private setTutorialCompleted(tutorial: TutorialEnum) {
		switch (tutorial) {
			case TutorialEnum.Homepage:
				this.tutorialExperience.homepage = true;
				break;
			case TutorialEnum.Help:
				this.tutorialExperience.help = true;
				break;
			case TutorialEnum.Flow:
				this.tutorialExperience.flowPage = true;
				break;
			case TutorialEnum.Matching:
				this.tutorialExperience.matching = true;
				break;
			case TutorialEnum.Welcome:
				this.tutorialExperience.welcome = true;
				break;
			case TutorialEnum.Intro:
				this.tutorialExperience.intro = true;
				break;
			case TutorialEnum.Review:
				this.tutorialExperience.review = true;
				break;
			case TutorialEnum.Members:
				this.tutorialExperience.members = true;
				break;
			case TutorialEnum.Insights:
				this.tutorialExperience.insights = true;
				break;
			case TutorialEnum.Onboarding:
				this.tutorialExperience.onboarding = true;
				break;
		}
	}

	@action
	private setFirstName(firstName: string) {
		this.firstName = firstName;
	}

	@action
	private setLastName(lastName: string) {
		this.lastName = lastName;
	}

	@action
	private setPronouns(pronouns: string) {
		this.pronouns = pronouns;
	}

	/**
	 * Set the company
	 * @param company
	 */
	@action
	private setCompany(company: string) {
		this.company = company;
	}

	/**
	 * Set the title
	 * @param title
	 */
	@action
	private setTitle(title: string) {
		this.title = title;
	}

	/**
	 * Set the location
	 * @param location
	 */
	@action
	private setLocation(location: string) {
		this.location = location;
	}

	/**
	 * Set the education
	 * @param education
	 */
	@action
	private setEducation(education: string) {
		this.education = education;
	}

	/**
	 * Set the degrees
	 * @param degrees
	 */
	@action
	private setDegrees(degrees: string) {
		this.degrees = degrees;
	}

	/**
	 * Set the user timezone
	 * @param timezone
	 */
	@action
	private setTimezone(timezone: string) {
		this.timezone = timezone;
	}

	@action
	private setProfilePhoto(photo: string) {
		this.profilePhoto = photo;
	}

	/**
	 * Set Discord fo the user
	 * @param discord
	 */
	@action
	private setDiscord(discord: string) {
		this.discord = discord;
	}

	@action
	private setPhone(phone: string) {
		this.phone = phone;
	}

	/**
	 * Set LinkedIn of the user
	 * @param linkedIn
	 */
	@action
	private setLinkedIn(linkedIn: string) {
		this.linkedIn = linkedIn;
	}

	/**
	 * Set the twitter
	 * @param twitter
	 */
	@action
	private setTwitter(twitter: string) {
		this.twitter = twitter;
	}

	/**
	 * Set personal sit
	 * @param personalSite
	 */
	@action
	private setPersonalSite(personalSite: string) {
		this.personalSite = personalSite;
	}

	/**
	 * Update whether we are authenticated to google
	 * @param authed
	 */
	@action
	private setGoogleAuthed(authed: boolean) {
		this.googleAuthed = authed;
	}

	/**
	 * Update whether we are authenticated to outlook
	 * @param authed
	 */
	@action
	private setOutlookAuthed(authed: boolean) {
		this.outlookAuthed = authed;
	}

	/**
	 * Set the past matches for this user
	 */
	@action
	private setMatches(matches: MatchSummaryInfo[]) {
		this.matches.clear();
		matches.forEach((match: MatchSummaryInfo) => {
			const model = new Match(match, match.displayId);
			this.matches.set(match.mid, model);
		});
		this.matchesLoadedBool = true;
		this.matchesLoaded.next();
	}

	@action
	private loadProfile() {
		RestApiClient.serviceRequest<UserProfileModel>({
			generator: () => UsersService.getUserInfoFromCookieUsersSelfGet()
		}).subscribe(
			(data: UserProfileModel) => {
				this.uid = data.uid;
				this.firstName = data.firstName;
				this.lastName = data.lastName;
				this.pronouns = data.pronouns;
				this.email = data.email;
				this.unverifiedEmail = data.unverifiedEmail;
				this.phone = data.phone;
				this.discord = data.discord;
				this.linkedIn = data.linkedIn;
				this.twitter = data.twitter;
				this.location = data.location;
				this.title = data.title;
				this.company = data.company;
				this.education = data.school;
				this.degrees = data.degrees;
				this.personalSite = data.personalSite;
				this.timezone = data.timezone;
				this.profilePhoto = data.image;
				this.availability = data.availability;
				this.googleAuthed = data.googleAuthed;
				this.outlookAuthed = data.outlookAuthed;
				this.profileLoaded.next();
			},
			() => {
				// notify subscribers that the profile failed to load
				this.profileLoadFailed.next();
			}
		);
	}

	/**
	 * Calculate the relationship status between 2 members
	 */
	public calculateRelationshipStatus(uid: number): RelationshipStatusEnum {
		// return have met if they have a past match
		if (
			this.pastMatches.filter((val: IMatch) => val.matchedUserId === uid)
				.length > 0
		) {
			return RelationshipStatusEnum.HaveMet;
		}
		// return request sent if this user has requested
		if (
			this.sentRequests.filter((val: IMatch) => val.matchedUserId === uid)
				.length > 0
		) {
			return RelationshipStatusEnum.RequestSent;
		}
		// return request received if this user is pending
		if (
			this.pendingRequests.filter(
				(val: IMatch) => val.matchedUserId === uid
			).length > 0
		) {
			return RelationshipStatusEnum.RequestReceived;
		}
		// return have not met otherwise
		return RelationshipStatusEnum.HaveNotMet;
	}
}
