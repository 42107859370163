/**
 * Enumizes the group setup data strings
 */
export enum GroupSetupEnum {
	Join = 'joinFormComplete',
	Welcome = 'welcomePageVisited',
	OptIn = 'optInPreviewViewed',
	Intro = 'introPageVisited',
	Feedback = 'feedbackFormPreviewed',
	Testing = 'testExperienceComplete',
	Rollout = 'rollout'
}
