/**
 * get the cookie by name
 * @param name
 */
export function getCookie(name: string) {
	const value = '; ' + document.cookie;
	const parts = value.split('; ' + name + '=');

	if (parts.length === 2) {
		// @ts-ignore
		return parts.pop().split(';').shift();
	}
}

/**
 * Sets a cookie for this app
 * @param name
 * @param val
 */
export function setCookie(name: string, val: string) {
	const date = new Date();
	const value = val;

	// Set it expire in 7 days
	date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);

	// Set it
	document.cookie =
		name + '=' + value + '; expires=' + date.toUTCString() + '; path=/';
}

/**
 * Remove a cookie with the given name
 * @param name
 */
export function deleteCookie(name: string) {
	const date = new Date();

	// Set it expire in -1 days
	date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);

	// Set it
	document.cookie = name + '=; expires=' + date.toUTCString() + '; path=/';
}

/**
 * Check for user cookie and return it if available
 */
export function getMemberAuthCookie(): string {
	// get regex match for cookie format
	const match: RegExpMatchArray = document.cookie.match(
		new RegExp('introsai.[-a-zA-Z0-9@:%_+.~#?&//=]{22,32}.is.authenticated')
	);
	// return cookie value
	if (match) {
		return match[0].split('.')[1];
	}
	// return undefined as default
	return undefined;
}

/**
 * Set the member auth cookie
 */
export function setMemberAuthCookie(cookie: string) {
	// delete any existing cookies
	deleteMemberAuthCookies();
	// create expiry date
	const date = new Date();
	// Set it expire in 30 days
	date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
	// Set it
	document.cookie =
		'introsai.' +
		cookie +
		'.is.authenticated=true; expires=' +
		date.toUTCString() +
		'; path=/';
}

/**
 * Delete member auth cookies
 */
export function deleteMemberAuthCookies() {
	// find all cookies to delete
	const cookiesToDelete: RegExpMatchArray[] = Array.from(
		document.cookie.matchAll(
			new RegExp(
				'introsai.[-a-zA-Z0-9@:%_+.~#?&//=]{1,32}.is.authenticated',
				'g'
			)
		)
	);
	// delete them
	cookiesToDelete.forEach((regex: RegExpMatchArray) =>
		deleteCookie(regex[0])
	);
}
