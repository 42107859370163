import { action, computed, makeObservable, observable } from 'mobx';
import { CustomDataUsageEnum } from '../../data-models/shared/customData/types';
import { MessagingData } from '../../api-client';

export interface ICustomDataModel {
	/**
	 * The question id associated with this peice of custom data
	 */
	qid: number;
	/**
	 * The question associated with this peice of custom data
	 */
	question: string;
	/**
	 * The linked question to this peice fo custom data
	 */
	linkedQuestion: string;
	/**
	 * The admin-defined usage of this CustomData
	 */
	usage?: CustomDataUsageEnum;
	/**
	 * True if we should include this custom data in the opt-in
	 */
	includeInOptIn?: boolean;
	/**
	 * True if we should include this custom data in the intro
	 */
	includeInIntro?: boolean;
	/**
	 * true if this piece of CustomData also has an associated AlgoParam, false othewise
	 */
	algoParam?: boolean;
	/**
	 * Updates the usage of the
	 */
	toggleUsage?: (usage: CustomDataUsageEnum) => void;
}

export class CustomData implements ICustomDataModel {
	public qid: number;
	public question: string;
	public algoParam: boolean;
	public linkedQuestion: string;

	@observable
	public usage: CustomDataUsageEnum;

	constructor(data: MessagingData) {
		makeObservable(this);
		this.qid = data.qid;
		this.question = data.question;
		this.algoParam = data.algoParam;
		this.usage = data.usage as CustomDataUsageEnum;
		this.linkedQuestion = data.linkedQuestion;
	}

	@computed
	public get includeInOptIn() {
		return (
			this.usage === CustomDataUsageEnum.Both ||
			this.usage === CustomDataUsageEnum.OptIn
		);
	}

	@computed
	public get includeInIntro() {
		return (
			this.usage === CustomDataUsageEnum.Both ||
			this.usage === CustomDataUsageEnum.Intro
		);
	}

	@action
	public toggleUsage(usage: CustomDataUsageEnum) {
		if (usage === CustomDataUsageEnum.OptIn) {
			if (this.usage === CustomDataUsageEnum.Both) {
				this.usage = CustomDataUsageEnum.Intro;
			} else if (this.usage === CustomDataUsageEnum.Intro) {
				this.usage = CustomDataUsageEnum.Both;
			} else if (this.usage === CustomDataUsageEnum.OptIn) {
				this.usage = CustomDataUsageEnum.None;
			} else if (this.usage === CustomDataUsageEnum.None) {
				this.usage = CustomDataUsageEnum.OptIn;
			}
		} else if (usage === CustomDataUsageEnum.Intro) {
			if (this.usage === CustomDataUsageEnum.Both) {
				this.usage = CustomDataUsageEnum.OptIn;
			} else if (this.usage === CustomDataUsageEnum.Intro) {
				this.usage = CustomDataUsageEnum.None;
			} else if (this.usage === CustomDataUsageEnum.OptIn) {
				this.usage = CustomDataUsageEnum.Both;
			} else if (this.usage === CustomDataUsageEnum.None) {
				this.usage = CustomDataUsageEnum.Intro;
			}
		}
	}
}
