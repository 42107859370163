/**
 * Enumizes statuses of group members
 */
export enum GroupMemberStatusEnum {
	Active = 'Active',
	Invited = 'Invited',
	Uploaded = 'Uploaded',
	Pending = 'Pending',
	Inactive = 'Inactive',
	Attention = 'Attention'
}
