import moment from 'moment';

/**
 * Convert the date provided to the target timezone provided
 * @param date
 * @param timezone
 */
export const convertToTargetTimezone = (date: Date, timezone: string) => {
	// get the local date without timezone
	const dateWithoutZone = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS');
	// get the target timezone
	const otherZone = moment.tz(date, timezone).format('Z');
	// join the two strings together
	const dateWithOtherZone = [dateWithoutZone, otherZone].join('');
	// create new date from string
	return new Date(dateWithOtherZone);
};
