/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DataExportRequest } from '../models/DataExportRequest';
import type { GetSingleReportResponse } from '../models/GetSingleReportResponse';
import type { ReportSummary } from '../models/ReportSummary';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReportsService {
	/**
	 * Request a report to be exported for this club
	 * POST /clubs/{displayId}/reports
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static exportRequestClubsDisplayIdReportsPost({
		displayId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: DataExportRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/clubs/{displayId}/reports',
			path: {
				displayId: displayId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Get a list of reports for this club
	 * GET /clubs/{displayId}/reports
	 * @returns ReportSummary Successful Response
	 * @throws ApiError
	 */
	public static getReportsCacheClubsDisplayIdReportsGet({
		displayId
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
	}): CancelablePromise<Array<ReportSummary>> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/clubs/{displayId}/reports',
			path: {
				displayId: displayId
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Get a Signed Url to download a report
	 * GET /clubs/{displayId}/reports/{reportId}
	 * @returns GetSingleReportResponse Successful Response
	 * @throws ApiError
	 */
	public static getReportClubsDisplayIdReportsReportIdGet({
		displayId,
		reportId
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		reportId: number;
	}): CancelablePromise<GetSingleReportResponse> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/clubs/{displayId}/reports/{reportId}',
			path: {
				displayId: displayId,
				reportId: reportId
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Delete a report
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static deleteReportClubsDisplayIdReportsReportIdDelete({
		displayId,
		docPk
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		docPk: number;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/clubs/{displayId}/reports/{reportId}',
			path: {
				displayId: displayId
			},
			query: {
				doc_pk: docPk
			},
			errors: {
				422: `Validation Error`
			}
		});
	}
}
