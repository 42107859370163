/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enumizes the status of a match
 */
export enum routes__matches__types__MatchStatusEnum {
	INTRO_MADE = 'intro_made',
	TIMES_PROPOSED_BY_USER = 'times_proposed_by_user',
	TIMES_PROPOSED_BY_MATCH = 'times_proposed_by_match',
	SCHEDULED = 'scheduled',
	AWAITING_USER_FEEDBACK = 'awaiting_user_feedback',
	COMPLETED_FEEDBACK = 'completed_feedback'
}
