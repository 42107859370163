import { MatchingRoundTypeEnum } from '../../../api-client';

/**
 * Enumizes the types of invites available to send
 */
export enum InviteTypeEnum {
	OptIn = 'Opt-In',
	OptOut = 'Opt-Out'
}

/**
 * Map string to enum value
 * @param value
 */
export const mapStringToInviteTypeEnum = (
	value: string
): MatchingRoundTypeEnum => {
	if (value === 'opt-in') {
		return MatchingRoundTypeEnum.OPT_IN;
	}
	return MatchingRoundTypeEnum.OPT_OUT;
};
