import { action, computed, makeObservable, observable } from 'mobx';
import { ISearchOption } from '../../../../../data-models/response/rest/users/search/UsersSearchGetResponse';
import {
	DynamicQuestionModel,
	IDynamicQuestionModel
} from '../dynamic-question';
import { ICreateQuestionParams, SurveyQuestionTypeEnum } from '../../types';
import { QuestionMatchingRuleTypeEnum } from '../../../../../api-client';
import { Observable } from 'rxjs';

export interface IFreetextSurveyQuestionModel extends IDynamicQuestionModel {
	/**
	 * Answer to the freetext question
	 */
	answer: string;
	/**
	 * Sets the answer to the freetext question
	 */
	setAnswer(val: string): void;
}

export class FreetextSurveyQuestionModel
	extends DynamicQuestionModel
	implements IFreetextSurveyQuestionModel {
	@observable
	public answer: string;

	@computed
	public get searchOption(): ISearchOption {
		return undefined;
	}

	// type of this question is always multiselect
	public type: SurveyQuestionTypeEnum = SurveyQuestionTypeEnum.Freetext;

	constructor(data: ICreateQuestionParams) {
		super({ ...data, suppressConditionalLogic: true });
		this.algoType = undefined;
		makeObservable(this);
	}

	public createMatchingRules(
		rule: QuestionMatchingRuleTypeEnum
	): Observable<void> {
		return;
	}

	public saveMatchingRules(): Observable<void> {
		return;
	}

	@computed
	public get validForSubmission(): boolean {
		return (!!this.answer && this.answer.length > 0) || this.optional;
	}

	@action
	public setAnswer(val: string) {
		this.answer = val;
	}
}
