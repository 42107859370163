/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { createMatchForMatchingRoundRequest } from '../models/createMatchForMatchingRoundRequest';
import type { createMatchForMatchingRoundResponse } from '../models/createMatchForMatchingRoundResponse';
import type { GenerateGPTIntroForMatchReqeust } from '../models/GenerateGPTIntroForMatchReqeust';
import type { GenerateGPtIntroRequest } from '../models/GenerateGPtIntroRequest';
import type { GenerateGPtIntroResponse } from '../models/GenerateGPtIntroResponse';
import type { GetMatchesResponse } from '../models/GetMatchesResponse';
import type { GetUserMatchesResponse } from '../models/GetUserMatchesResponse';
import type { LiteMatchInfo } from '../models/LiteMatchInfo';
import type { MatchesSearchParameters } from '../models/MatchesSearchParameters';
import type { MatchModel } from '../models/MatchModel';
import type { MatchProposalRequest } from '../models/MatchProposalRequest';
import type { PostMatchesSearchResponse } from '../models/PostMatchesSearchResponse';
import type { SwapUserIdForMatchReqeust } from '../models/SwapUserIdForMatchReqeust';
import type { UpdateMatchRequest } from '../models/UpdateMatchRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MatchesService {
	/**
	 * Get matches for a club's matching round
	 * GET /clubs/<display id>/matching-rounds/<matchingRoundId>/matches
	 * @returns GetMatchesResponse Successful Response
	 * @throws ApiError
	 */
	public static getMatchesClubsDisplayIdMatchingRoundsMatchingRoundIdMatchesGet({
		displayId,
		matchingRoundId
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		matchingRoundId: number;
	}): CancelablePromise<GetMatchesResponse> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/clubs/{displayId}/matching-rounds/{matchingRoundId}/matches',
			path: {
				displayId: displayId,
				matchingRoundId: matchingRoundId
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Create a match for a matching round
	 * Create a match for a matching round
	 * @returns createMatchForMatchingRoundResponse Successful Response
	 * @throws ApiError
	 */
	public static createMatcheForMatchingRoundClubsDisplayIdMatchingRoundsMatchingRoundIdMatchesPost({
		displayId,
		matchingRoundId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		matchingRoundId: number;
		requestBody: createMatchForMatchingRoundRequest;
	}): CancelablePromise<createMatchForMatchingRoundResponse> {
		return __request(OpenAPI, {
			method: 'POST',
			url:
				'/clubs/{displayId}/matching-rounds/{matchingRoundId}/matches/',
			path: {
				displayId: displayId,
				matchingRoundId: matchingRoundId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Update a match preview
	 * PUT /matches/previews/{id}
	 * @returns MatchModel Successful Response
	 * @throws ApiError
	 */
	public static updateMatchPreviewClubsDisplayIdMatchingRoundsMatchingRoundIdMatchesMatchIdPut({
		displayId,
		matchingRoundId,
		matchId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		matchingRoundId: number;
		matchId: number;
		requestBody: UpdateMatchRequest;
	}): CancelablePromise<MatchModel> {
		return __request(OpenAPI, {
			method: 'PUT',
			url:
				'/clubs/{displayId}/matching-rounds/{matchingRoundId}/matches/{matchId}',
			path: {
				displayId: displayId,
				matchingRoundId: matchingRoundId,
				matchId: matchId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Generate a GPT intro for a match
	 * POST /clubs/<display id>/matching-rounds
	 * @returns MatchModel Successful Response
	 * @throws ApiError
	 */
	public static generateGptIntroForMatchClubsDisplayIdMatchingRoundsMatchingRoundIdMatchesMatchIdGenerateGptIntroPost({
		displayId,
		matchingRoundId,
		matchId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		matchingRoundId: number;
		matchId: number;
		requestBody: GenerateGPTIntroForMatchReqeust;
	}): CancelablePromise<MatchModel> {
		return __request(OpenAPI, {
			method: 'POST',
			url:
				'/clubs/{displayId}/matching-rounds/{matchingRoundId}/matches/{matchId}/generate-gpt-intro',
			path: {
				displayId: displayId,
				matchingRoundId: matchingRoundId,
				matchId: matchId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Swap one of the users in the match
	 * POST /clubs/<display id>/matching-rounds
	 * @returns MatchModel Successful Response
	 * @throws ApiError
	 */
	public static swapUserIdInMatchClubsDisplayIdMatchingRoundsMatchingRoundIdMatchesMatchIdSwapUserPost({
		displayId,
		matchingRoundId,
		matchId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		matchingRoundId: number;
		matchId: number;
		requestBody: SwapUserIdForMatchReqeust;
	}): CancelablePromise<MatchModel> {
		return __request(OpenAPI, {
			method: 'POST',
			url:
				'/clubs/{displayId}/matching-rounds/{matchingRoundId}/matches/{matchId}/swap-user',
			path: {
				displayId: displayId,
				matchingRoundId: matchingRoundId,
				matchId: matchId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Get Matches for the Group
	 * Load member data from this Group
	 * @returns PostMatchesSearchResponse Successful Response
	 * @throws ApiError
	 */
	public static getMatchesClubsDisplayIdMatchesSearchPost({
		displayId,
		requestBody,
		page,
		size = 25
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: MatchesSearchParameters;
		page?: number;
		size?: number;
	}): CancelablePromise<PostMatchesSearchResponse> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/clubs/{displayId}/matches/search',
			path: {
				displayId: displayId
			},
			query: {
				page: page,
				size: size
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Generate a GPT intro for a match
	 * POST /internal/matches/{mid}/gnerateGptIntro
	 * @returns GenerateGPtIntroResponse Successful Response
	 * @throws ApiError
	 */
	public static generateGptIntroInternalMatchesMidGenerateGptIntroPost({
		mid,
		requestBody
	}: {
		mid: number;
		requestBody: GenerateGPtIntroRequest;
	}): CancelablePromise<GenerateGPtIntroResponse> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/internal/matches/{mid}/generateGptIntro',
			path: {
				mid: mid
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Get all matches for a user
	 * GET /matches
	 * # TODO: return match state with the matches and replace /users/requests with this endpoint
	 * @returns GetUserMatchesResponse Successful Response
	 * @throws ApiError
	 */
	public static getMatchesForUserMatchesGet(): CancelablePromise<GetUserMatchesResponse> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/matches',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Create a new match as a proposal
	 * POST /matches
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static proposeNewMatchMatchesPost({
		requestBody
	}: {
		requestBody: MatchProposalRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/matches',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Get match by it's unique hash
	 * Get a match id and group's display id given its unique hash
	 * @returns LiteMatchInfo Successful Response
	 * @throws ApiError
	 */
	public static getMatchByHashMatchesHashGet({
		hash
	}: {
		hash: string;
	}): CancelablePromise<LiteMatchInfo> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/matches/{hash}',
			path: {
				hash: hash
			},
			errors: {
				422: `Validation Error`
			}
		});
	}
}
