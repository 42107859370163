import Axios from 'axios-observable';
import { IGeneratePresignedUrlPostResponse } from '../../../data-models/response/rest/s3/GeneratePresignedUrlPostResponse';
import { RestApiClient } from '../rest/restApiClientModel';
import { S3Service } from '../../../api-client';

export interface IUploadFileParams {
	/**
	 * File we are uploading to AWS S3 bucket
	 */
	file: File;
	/**
	 * Cookie associated with the logged in admin OR associated with the group member
	 */
	cookie: string;
	/**
	 * Optional group id to provide for logo upload (should be provided when not during group creation)
	 */
	gid?: number;
	/**
	 * Optional boolean to specify if this is a profile photo we are uploading
	 */
	profilePhoto?: boolean;
}

/**
 * AWS S3 Client
 */
class AwsS3ClientModel {
	private S3instance: Axios = Axios.create({});

	public uploadFile(params: IUploadFileParams): Promise<unknown> {
		const { file, cookie, gid, profilePhoto } = params;

		return new Promise((res, rej) => {
			// Generate pre-signed URL for uploading an image to S3
			RestApiClient.serviceRequest<IGeneratePresignedUrlPostResponse>({
				generator: () =>
					S3Service.generatePresignedUrlInternalIntegrationsWebhooksS3GeneratePresignedUrlPost(
						{
							requestBody: {
								gid: gid,
								type: file.name.substring(
									file.name.lastIndexOf('.') + 1,
									file.name.length
								)
							}
						}
					)
			}).subscribe(
				(data: IGeneratePresignedUrlPostResponse) => {
					const presignedFields = data?.fields;

					// Create new formdata object
					const formData = new FormData();

					// Append all authentication related fields
					formData.append('key', presignedFields.key);
					formData.append(
						'AWSAccessKeyId',
						presignedFields.AWSAccessKeyId
					);
					formData.append('policy', presignedFields.policy);
					formData.append('signature', presignedFields.signature);

					// Append file
					formData.append('file', file);

					// Send upload request to signed S3 URL
					this.S3instance.post(data.url, formData, {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					}).subscribe(
						() => {
							res({
								success: true,
								path: `${data.url}${presignedFields.key}`
							});
						},
						() => {
							rej({
								success: false,
								reason: 'Failed to upload file to S3'
							});
						}
					);
				},
				() => {
					rej({
						success: false,
						reason: 'Failed to generate presigned S3 url'
					});
				}
			);
		});
	}
}

/**
 * Export a version of our class for other classes to share
 */
export const awsS3Client = new AwsS3ClientModel();
