import styled from '@emotion/styled';
import React from 'react';
import { Navigate } from 'react-router';

import { IntrosPathNamesEnum } from '../intros/types';
import { PrimaryButton } from '../shared/buttons/buttons';
import {
	FullHeightFlexWrapper,
	FlexColumnWrapper,
	CenteredFlexWrapper,
	FlexWrapper
} from '../shared/wrappers/styled-divs';

export const NoMobileAccess = () => {
	// get URL params
	const urlParams: URLSearchParams = new URLSearchParams(
		window.location.search
	);
	// true if we are from onboarding
	const onboarding: boolean = urlParams.get('onboarding') !== null;
	// track whether we should go home
	const [goHome, setGoHome] = React.useState<boolean>(false);
	// if goHome, then go to home page
	if (goHome) {
		return <Navigate to={IntrosPathNamesEnum.Dashboard.valueOf()} />;
	}

	return (
		<Container>
			<TopNavWrapper>
				<GroupLogo
					src="/assets/images/intros-logo.svg"
					alt="Intros Logo"
				/>
			</TopNavWrapper>
			<ContentWrapper>
				<Heading>Sign In from Desktop</Heading>
				<Description>
					Intros works best on a bigger screen
					{onboarding
						? ", so we've emailed you a link to sign in later from a desktop."
						: '.'}
				</Description>
				<NoMobileGraphic src="/assets/images/no-mobile-access.svg" />
				<Description>
					{onboarding ? (
						<>
							In the meantime,{' '}
							<CaseStudyLink
								href="https://intros.ai/case-studies"
								target="_blank"
								rel="noreferrer"
							>
								get inspiration
							</CaseStudyLink>{' '}
							from our other beta communities!
						</>
					) : (
						'If you’re already on a desktop, widen your screen and click the button below.'
					)}
				</Description>
				{!onboarding && (
					<ReturnToHompageGraphic
						label="Community Home"
						onClick={() => setGoHome(true)}
					/>
				)}
			</ContentWrapper>
		</Container>
	);
};

const Container = styled(FullHeightFlexWrapper)`
	flex-direction: column;
`;

const TopNavWrapper = styled(CenteredFlexWrapper)`
	align-items: center;
	height: 64px;
	background-color: var(--background);
	border-bottom: 1px solid var(--light-grey);
`;

const GroupLogo = styled.img`
	object-fit: contain;
	margin: auto;
`;

const ContentWrapper = styled(FlexColumnWrapper)`
	margin: auto;
	width: 320px;
	height: 440px;
	box-sizing: border-box;
	padding: 24px 16px;
	border-radius: 16px;

	box-shadow: 0px 2px 30px 0px var(--no-mobile-access-shadow);
	border: 1px solid var(--light-grey);
	background-color: var(--background);
	position: relative;
`;

const Description = styled.span`
	margin: auto;
	text-align: justify;
	font-size: 16px;
	line-height: 20px;
	color: var(--subtext-grey);
`;

const Heading = styled.div`
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	color: var(--airfoil-blue);
	margin: auto;
	margin-bottom: 32px;
`;

const NoMobileGraphic = styled.img`
	height: 200px;
	width: 200px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 16px;
	margin-bottom: 16px;
`;

const ReturnToHompageGraphic = styled(PrimaryButton)`
	height: 48px;
	width: 200px;
	box-sizing: border-box;

	margin: auto;
	margin-top: 16px;
`;

const CaseStudyLink = styled.a``;
