/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetMemberRecommendations } from '../models/GetMemberRecommendations';
import type { RecommendationResponseModel } from '../models/RecommendationResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RecommendationsService {
	/**
	 * Get Recommendations for a Member in a Group
	 * GET /clubs/{displayId}/members/<member_id>/recommendations
	 * @returns GetMemberRecommendations Successful Response
	 * @throws ApiError
	 */
	public static getMemberRecommendationsClubsDisplayIdMembersMemberIdRecommendationsGet({
		displayId,
		memberId,
		matchingRoundId,
		page,
		allowRematching = false
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		memberId: number;
		matchingRoundId?: number;
		page?: number;
		allowRematching?: boolean;
	}): CancelablePromise<GetMemberRecommendations> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/clubs/{displayId}/members/{member_id}/recommendations',
			path: {
				displayId: displayId,
				member_id: memberId
			},
			query: {
				matchingRoundId: matchingRoundId,
				page: page,
				allowRematching: allowRematching
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Respond to a recommendations for a Member in a Group
	 * PUT /clubs/{displayId}/members/<member_id>/recommendations
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static respondToRecommendationClubsDisplayIdMembersMemberIdRecommendationsPut({
		displayId,
		memberId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		memberId: number;
		requestBody: RecommendationResponseModel;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/clubs/{displayId}/members/{member_id}/recommendations',
			path: {
				displayId: displayId,
				member_id: memberId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}
}
