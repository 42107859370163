/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DetailedInsightsSearchRequest } from '../models/DetailedInsightsSearchRequest';
import type { DetailedInsightsSearchResponse } from '../models/DetailedInsightsSearchResponse';
import type { InsightsSearchRequest } from '../models/InsightsSearchRequest';
import type { InsightsSummary } from '../models/InsightsSummary';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InsightsService {
	/**
	 * Search for insights over a given timeframe
	 * POST /clubs/<display id>/engagement
	 * @returns InsightsSummary Successful Response
	 * @throws ApiError
	 */
	public static getInsightsDataClubsDisplayIdInsightsPost({
		displayId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: InsightsSearchRequest;
	}): CancelablePromise<InsightsSummary> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/clubs/{displayId}/insights',
			path: {
				displayId: displayId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Search for detailed insights over a given timeframe
	 * POST /clubs/<displayId>/insights/detailed
	 * @returns DetailedInsightsSearchResponse Successful Response
	 * @throws ApiError
	 */
	public static getDetailedInsightsDataClubsDisplayIdInsightsDetailedPost({
		displayId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: DetailedInsightsSearchRequest;
	}): CancelablePromise<DetailedInsightsSearchResponse> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/clubs/{displayId}/insights/detailed',
			path: {
				displayId: displayId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}
}
