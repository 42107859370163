import {
	action,
	computed,
	makeObservable,
	observable,
	ObservableMap,
	runInAction
} from 'mobx';
import posthog from 'posthog-js';
import { Observable } from 'rxjs';
import { AppState } from '../../../../../AppModel';
import { ISearchOption } from '../../../../../data-models/response/rest/users/search/UsersSearchGetResponse';
import { RestApiClient } from '../../../../api/rest/restApiClientModel';
import { SurveyAnswerOptionDataModel } from '../../../../api/rest/types';
import {
	ISurveyAnswerOptionModel,
	SurveyAnswerOptionModel
} from '../../../answer/survey-answer-option';
import {
	DynamicQuestionModel,
	IDynamicQuestionModel
} from '../dynamic-question';
import { ICreateQuestionParams, SurveyQuestionTypeEnum } from '../../types';
import {
	AddAnswerResponse,
	AnswersService,
	UpdateMatchingLogicResponse,
	CreateMatchingLogicTag,
	MatchingRuleEnum,
	MatchingService,
	MemberTagLiteModel,
	QuestionMatchingRuleTypeEnum,
	QuestionsService,
	SurveyTypes,
	TagMatchingRule,
	TagMatchingRuleLite
} from '../../../../../api-client';
import { map } from 'rxjs/operators';

export interface IRadioSurveyQuestionModel extends IDynamicQuestionModel {
	/**
	 * Options for this survey question
	 */
	answers: ISurveyAnswerOptionModel[];
	/**
	 * Options for this survey question as strings
	 */
	answerStrings: string[];
	/**
	 * Records the currently selected option id for this radio question
	 */
	selectedOptionId: number;
	/**
	 * Matching rules applied to the answers for this question
	 */
	activeMatchingRules: TagMatchingRule[];
	/**
	 * Add an option to this
	 */
	addOption(
		answer: string,
		questionBank?: boolean
	): Observable<unknown> | any;
	/**
	 * Remove an option from this question
	 */
	removeOption(answerId: number): void;
	/**
	 * Deactivate an option from this question
	 */
	deactivateOption(answerId: number): void;
	/**
	 * Selects an option for this question
	 */
	selectOption(answerId: number, value?: boolean): void;
}

export class RadioSurveyQuestionModel
	extends DynamicQuestionModel
	implements IRadioSurveyQuestionModel {
	/**
	 * Map of answer ids to their ISurveyAnswerOptionModel
	 */
	@observable
	public options: ObservableMap<number, ISurveyAnswerOptionModel>;
	/**
	 * Records the currently selected option id for this radio question
	 */
	@observable
	public selectedOptionId: number;

	/**
	 * Tags associated with this question
	 */
	private tags: MemberTagLiteModel[] = [];

	/**
	 * Tag matching rules associated with this question
	 */
	@observable
	private savedAnswerMatchingRules: TagMatchingRule[] = [];

	// type of this question is always radio
	public type: SurveyQuestionTypeEnum = SurveyQuestionTypeEnum.Radio;

	constructor(params: ICreateQuestionParams) {
		super(params);
		makeObservable(this);
		const options = new ObservableMap<number, ISurveyAnswerOptionModel>();
		if (params.questionBankEntry) {
			params.questionBankEntry.answers.forEach(
				(answer: SurveyAnswerOptionDataModel) => {
					options.set(answer.id, new SurveyAnswerOptionModel(answer));
				}
			);
			// set our observable equal to this constructed mpa
			this.options = options;
			// set rules
			this.savedAnswerMatchingRules = params.questionBankEntry.rules;
			// set tags
			this.tags = params.questionBankEntry.tags;
		} else {
			params.questionModel.answers.forEach(
				(answer: SurveyAnswerOptionDataModel) => {
					options.set(answer.id, new SurveyAnswerOptionModel(answer));
				}
			);
			// set our observable equal to this constructed mpa
			this.options = options;
			// set rules
			this.savedAnswerMatchingRules = params.questionModel.rules;
			// set tags
			this.tags = params.questionModel.tags;
		}
	}

	@computed
	public get searchOption(): ISearchOption {
		return {
			question: this.title,
			answers: Array.from(this.options.values()).map(
				(ans: ISurveyAnswerOptionModel) => ({
					uid: ans.id,
					val: ans.value
				})
			)
		};
	}

	@computed
	public get answers() {
		return Array.from(this.options.values()).sort(
			(a: ISurveyAnswerOptionModel, b: ISurveyAnswerOptionModel) => {
				/**
				 * Sort by idnex if both questions have an index, otherwise sort by value
				 */
				if (a.index !== undefined && b.index !== undefined) {
					return a.index < b.index ? -1 : 1;
				} else {
					return a.value < b.value ? -1 : 1;
				}
			}
		);
	}

	@computed
	public get answerStrings() {
		return this.answers
			.filter((ans) => ans.active)
			.map((ans: ISurveyAnswerOptionModel) => ans.value);
	}
	/**
	 * The rules that are currently active for this question
	 */
	@computed
	public get activeMatchingRules(): TagMatchingRule[] {
		return this.savedAnswerMatchingRules?.filter((rule) => rule.active);
	}

	@computed
	public get validForSubmission(): boolean {
		return !!this.selectedOptionId || this.optional;
	}

	public selectOption(answerId: number, value?: boolean) {
		// deselect the old answer id
		if (this.selectedOptionId) {
			(this.options.get(
				this.selectedOptionId
			) as ISurveyAnswerOptionModel).setSelected(false);
		}
		// set the new answer id as selected
		const answer = this.options.get(answerId) as ISurveyAnswerOptionModel;
		answer && answer.setSelected(value ?? true);
		// save the new answer id
		this.selectedOptionId = answerId;
	}

	public createMatchingRules(
		algoType: QuestionMatchingRuleTypeEnum,
		newMatchingRules: CreateMatchingLogicTag[],
		deleteExistingRules?: boolean
	) {
		// HttpMethodEnum.
		return RestApiClient.serviceRequest<UpdateMatchingLogicResponse>({
			generator: () =>
				deleteExistingRules
					? QuestionsService.updateMatchingLogicClubsDisplayIdSurveysSurveyTypeQuestionsQidMatchingPut(
							{
								displayId: encodeURIComponent(
									AppState.selectedGroup.displayId
								),
								qid: this.id,
								surveyType: SurveyTypes.PROFILE,
								requestBody: {
									newRules: newMatchingRules,
									algoWeight: this.weight || 3,
									allowOverride: this.allowOverride,
									algoType: algoType
								}
							}
					  )
					: QuestionsService.applyMatchingLogicClubsDisplayIdSurveysSurveyTypeQuestionsQidMatchingPost(
							{
								displayId: encodeURIComponent(
									AppState.selectedGroup.displayId
								),
								qid: this.id,
								surveyType: SurveyTypes.PROFILE,
								requestBody: {
									rules: newMatchingRules,
									algoWeight: this.weight || 3,
									allowOverride: this.allowOverride,
									algoType: algoType
								}
							}
					  )
		}).pipe(
			map((data: UpdateMatchingLogicResponse) => {
				this.tags = data.tags;
				this.savedAnswerMatchingRules = [...data.rules];
				this.algoType = algoType;
				this.weight = this.weight ? this.weight : 3;
				this.allowOverride = this.allowOverride
					? this.allowOverride
					: false;
				this.modifiedWeight = false;
				this.modifiedAllowOverride = false;
				// capture action
				posthog.capture(
					'Created Matching Rule for Member Profile (Join Survey) Question',
					{
						group: AppState.selectedGroup?.name,
						source: 'web',
						qid: this.id,
						rule: algoType.valueOf(),
						tag_based_rules: newMatchingRules
					}
				);
			})
		);
	}

	public saveMatchingRules(rules: TagMatchingRule[]) {
		// HttpMethodEnum.
		return RestApiClient.serviceRequest<UpdateMatchingLogicResponse>({
			generator: () =>
				MatchingService.updateMatchingLogicClubsDisplayIdSurveysSurveyTypeQuestionsQidMatchingPut(
					{
						displayId: encodeURIComponent(
							AppState.selectedGroup.displayId
						),
						qid: this.id,
						surveyType: SurveyTypes.PROFILE,
						requestBody: {
							updatingRules: rules,
							algoWeight: this.weight,
							allowOverride: this.allowOverride
						}
					}
				),
			userToken: AppState.user?.cookie
		}).pipe(
			map((data: UpdateMatchingLogicResponse) => {
				this.savedAnswerMatchingRules = data.rules;
				this.modifiedWeight = false;
				this.modifiedAllowOverride = false;
				/**
				 * Notify the user that the matching rules have been updated
				 * TODO: should probably be handled by UI
				 */
				AppState.setNotification({
					type: 'success',
					message: 'Successfully updated matching rule'
				});
				// capture action
				posthog.capture(
					'Saved Matching Rules for Member Profile (Join Survey) Question',
					{
						group: AppState.selectedGroup?.name,
						source: 'web',
						qid: this.id,
						rules
					}
				);
			})
		);
	}

	public addOption(answer: string, questionBank?: boolean) {
		return RestApiClient.serviceRequest<AddAnswerResponse>({
			generator: () =>
				AnswersService.addAnswerClubsDisplayIdSurveysSurveyTypeQuestionsQidAnswersPost(
					{
						displayId: encodeURIComponent(
							AppState.selectedGroup.displayId
						),
						surveyType: SurveyTypes.PROFILE,
						qid: this.id,
						requestBody: {
							answer: answer
						}
					}
				),
			userToken: AppState.user?.cookie
		}).subscribe((data: AddAnswerResponse) => {
			this.options.set(
				data.id,
				new SurveyAnswerOptionModel({
					id: data.id,
					value: answer,
					gid: questionBank ? undefined : AppState.selectedGroup.gid,
					active: true
				})
			);
			// capture action
			posthog.capture(
				'Added Answer Option to Member Profile (Join Survey) Question',
				{
					group: AppState.selectedGroup?.name,
					source: 'web',
					qid: this.id,
					answer
				}
			);
		});
	}

	public removeOption = (answerId: number) => {
		if (this.options.has(answerId)) {
			const answer = this.options.get(
				answerId
			) as ISurveyAnswerOptionModel;
			RestApiClient.serviceRequest<AddAnswerResponse>({
				generator: () =>
					AnswersService.deleteAnswerClubsDisplayIdSurveysSurveyTypeQuestionsQidAnswersAidDelete(
						{
							displayId: encodeURIComponent(
								AppState.selectedGroup.displayId
							),
							surveyType: SurveyTypes.PROFILE,
							qid: this.id,
							aid: answerId
						}
					),
				userToken: AppState.user?.cookie
			}).subscribe(() => {
				// delete the question from map
				runInAction(() => this.options.delete(answerId));
				// capture action
				posthog.capture(
					'Removed Answer Option from Member Profile (Join Survey) Question',
					{
						group: AppState.selectedGroup?.name,
						source: 'web',
						qid: this.id,
						aid: answerId
					}
				);
			});
		}
	};

	@action
	public deactivateOption(id: number) {
		const option: ISurveyAnswerOptionModel = this.options.get(id);
		option.setActive(false);
	}
}
