/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClearMatchAvailabilityDeleteRequest } from '../models/ClearMatchAvailabilityDeleteRequest';
import type { ConfirmMatchRequest } from '../models/ConfirmMatchRequest';
import type { ScheduleMatchModel } from '../models/ScheduleMatchModel';
import type { SubmitMatchRequest } from '../models/SubmitMatchRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SchedulingService {
	/**
	 * Get match schedule
	 * Get the schedule for a match given its unique hash
	 * @returns ScheduleMatchModel Successful Response
	 * @throws ApiError
	 */
	public static getMatchScheduleMatchesHashScheduleGet({
		hash
	}: {
		hash: string;
	}): CancelablePromise<ScheduleMatchModel> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/matches/{hash}/schedule',
			path: {
				hash: hash
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Submit availability for a match
	 * POST /matches/{hash}/schedule
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static submitMatchAvailabilityMatchesHashSchedulePost({
		hash,
		requestBody
	}: {
		hash: string;
		requestBody: SubmitMatchRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/matches/{hash}/schedule',
			path: {
				hash: hash
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Clear submitted availability for this match
	 * Use this prior to submitting new availability for a match
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static clearMatchAvailabilityMatchesHashScheduleDelete({
		hash,
		requestBody
	}: {
		hash: string;
		requestBody: ClearMatchAvailabilityDeleteRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/matches/{hash}/schedule',
			path: {
				hash: hash
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Submit availability for a match
	 * POST /matches/{hash}/schedule/confirm
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static confirmMatchAvailabilityMatchesHashScheduleConfirmPost({
		hash,
		requestBody
	}: {
		hash: string;
		requestBody: ConfirmMatchRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/matches/{hash}/schedule/confirm',
			path: {
				hash: hash
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}
}
