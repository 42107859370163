/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enumizes the actions we can receive via POST /groups/{display id}/insights/details
 */
export enum GroupInsightsDetailedActionEnum {
	NEW_MEMBERS = 'New Members',
	PARTICIPANTS = 'Participants',
	INTROS_MADE = 'Intros Made',
	INVITES_SENT = 'Invites Sent',
	INTROS_SCHEDULED = 'Intros Scheduled',
	AVG_MEETING_DURATION = 'Avg Meeting Duration',
	REVIEWS_SUBMITTED = 'Reviews Submitted',
	MATCH_FIT = 'Match Fit',
	NET_PROMOTOR_SCORE = 'Net Promotor Score'
}
