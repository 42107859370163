/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enumizes paths for our matches
 */
export enum MatchActionsEnum {
	MISSED = 'missed',
	CANCEL = 'cancel',
	ARCHIVE = 'archive'
}
